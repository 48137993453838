/* eslint-disable max-len */

import * as R from 'ramda';
import { v4 as uuid } from 'uuid';

export const addKeys = (arr = [], key = 'id') => arr.map((item) => ({
  ...item,
  key: key !== false ? item[key] : uuid()
}));

export const makeColumnFilters = (arr, key) => {
  const unique_values = [
    ...new Set(arr.map((i) => R.propOr(null, key, i)).filter((i) => i !== null))
  ].sort();
  return unique_values.map((value) => ({ text: value, value }));
};

export const groupRecordsBy = (arr, path) => {
  const unique_values = [
    ...new Set(arr?.map((i) => R.pathOr(null, path, i)).filter((i) => i !== null))
  ];
  return unique_values.reduce((acc, val) => {
    acc[val] = arr.filter((obj) => R.pathOr(null, path, obj) === val).length;
    return acc;
  }, {});
};

export const createOptionFromTypeSet = (typeSet) => Object.entries(typeSet).map(([, optionType]) => ({
  value: optionType,
  label: optionType.label,
  optionType
}));

const generateUniqueIdsArray = (length) => {
  const result = [];
  const usedIds = new Set();

  while (result.length < length) {
    const newId = Math.floor(Math.random() * 1000);
    if (!usedIds.has(newId)) {
      result.push({ key: newId });
      usedIds.add(newId);
    }
  }

  return result;
};

export const mappingTableRowFixture = generateUniqueIdsArray(8);
export const tableRowFixture = generateUniqueIdsArray(30);

export const convertOptionsStructure = (data) => data.map((item) => ({
  value: item.id,
  label: item.serialNumber ? `${item.name} - ${item.serialNumber}` : item.name,
  item
}));

export const convertEnumerationStructure = (data) => data.map((item, index) => ({
  value: index + 1,
  label: item
}));

export const concatenateCredentials = (data) => data?.map((item) => ({
  ...item,
  concatenatedCredentials: item.credentials
    .map((cred) => `Credentials: ${cred.credentials} • License Number: ${cred.licenseNumber} `)
    .join('\n')
}));

export const handleDataLoading = (data, isLoading, error, setOptions, dataKey) => {
  if (!isLoading) {
    if (error) {
      console.error(`Error loading ${dataKey}:`, error);
    }
    if (data) {
      setOptions(convertEnumerationStructure(data));
    }
  }
};

export const handleOptionsLoading = (data, isLoading, error, setOptions, dataKey) => {
  if (!isLoading) {
    if (error) {
      console.error(`Error loading ${dataKey}:`, error);
    }
    if (data) {
      setOptions(convertOptionsStructure(data));
    }
  }
};

export const sendMessageToSW = (message) => {
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(message);
  } else {
    console.error('No active service worker found to send a message.');
  }
};
