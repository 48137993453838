import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import { Input } from 'antd';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { global } from '../../../../shared/colors';
import ToggleSwitch from '../../components/ToggleSwitch';
import FileDropzone from '../../components/FileDropzone';
import priorityOptions from '../../components/options/priorityOptions';
import customStyles from '../../components/customStyles/customStyles';
import DropdownIndicator from '../../../../components/Select/DropdownIndicator';
import useGetData from '../../../../hooks/useGetData';
import { GlobalContext } from '../../../../context/GlobalContext';
import { ModifyInspectionsContext } from '../../../../context/ModifyInspectionsContext';
import { getComponentTypes } from '../../../../services/airmethaneApi';
import { handleDataLoading } from '../../../../shared/utils';
import ClearIndicator from '../../../../components/Select/ClearIndicator';

const flexCenter = `
    display: flex;
    justify-content: center;
    align-items: center;
`;

const clickable = `
    cursor: pointer;
    &:hover {
        opacity: 65%;
    }
`;

const noBackgroundNoBorder = `
    background: none;
    border: none;
`;

const Form = styled.form`
    ${flexCenter}
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    background: ${global.White};
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 20px 20px 0 20px;
    margin-bottom: 20px;
    height: ${(props) => (props.isExpanded ? 'auto' : 'fit-content')};
`;

const LeaksHeader = styled.h2`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
    color: ${global.PrimaryBlue};
    font-size: 16px;
    font-weight: 600;
    align-items: center;
`;

const ExpandableButton = styled.button`
    ${noBackgroundNoBorder}
    ${clickable}
    font-size: 24px;
`;

const FieldsDiv = styled.div`
    width: 100%;
`;

const Label = styled.label`
    color: ${global.Gray2};
    font-size: 12px;
    font-weight: 600;
`;

const SubLabel = styled.p`
    color: ${global.Gray4};
    font-size: 12px;
    font-weight: 600;
    margin: 4px 0 0 0;
`;

const StyledInput = styled(Input)`
    height: 51px;
    width: 100%;
    padding: 16px 20px;
    min-width: 100px;
    margin-top: 8px;
    margin-bottom: 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;

    ${(props) => props.id === 'right'
        && `
        padding-left: 34px;
    `}

    ${(props) => props.id === 'left'
        && `
        padding-left: 20px;
    `}

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield;
    }
`;

const TimeInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const RightPlaceholderInput = styled.div`
    position: relative;
    width: 100%;

    ${(props) => props.content === 'Parts per million'
        && `
        margin-right: -18px;
        z-index: 1;
    `}

    &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: grey;
        content: ${(props) => `'${props?.content}'`};
        right: 20px;
        bottom: 5px;
        color: ${global.Gray5};
        font-size: 16px;
    }
`;

const InputWrapper = styled.div`
    position: relative;
    width: 100%;

    ${(props) => props.id === 'leftWrapper'
        && `
        margin-right: -18px;
        z-index: 1;
    `}

    &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: grey;
    }

    &:nth-child(1)::after {
        content: 'minutes';
        right: 12px;
        bottom: 7px;
        color: ${global.Gray5};
    }

    &:nth-child(2)::after {
        content: 'seconds';
        right: 12px;
        bottom: 7px;
        color: ${global.Gray5};
    }
`;

const LeakSummary = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    margin: 8px 0 20px 0;
    border-radius: 10px;
    border: 1px solid ${global.Gray5};
`;

const Spacer = styled.div`
    width: 20px;
`;

const SummaryHeader = styled.div`
    display: flex;
    justify-content: space-between;

    div:last-child {
        color: ${global.Gray4};
        font-weight: 600;
        font-size: 14px;
    }
`;

const ComponentTitle = styled.div`
    font-weight: 600;
    width: 40%;
`;

const DropZones = styled.div`
    display: flex;
    margin: 8px 0 20px 0;
`;

const SummaryContent = styled.div`
    color: ${global.Gray3};
    word-break: break-word;

    div {
        margin-top: 5px;
    }

    .overflow-content {
        height: 100px;
        overflow: auto;
    }
`;

const LeaksForm = ({ formIndex, leaks, setLeaks }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [componentTypeOptions, setComponentTypeOptions] = useState([]);
  const { token } = useContext(GlobalContext);
  const { updateLeakIDOptions } = useContext(ModifyInspectionsContext);
  const {
    data: componentTypes,
    error: componentTypesError,
    isLoading: isComponentTypeLoading
  } = useGetData(token, 'componentTypes', getComponentTypes);

  const handleInputChange = (eventOrValue, fieldName) => {
    const updatedFormValues = [...leaks];

    if (typeof eventOrValue === 'object' && eventOrValue?.target) {
      updatedFormValues[formIndex][eventOrValue?.target.name] = eventOrValue.target.value;
    } else if (
      typeof eventOrValue === 'string'
            || typeof eventOrValue === 'number'
            || eventOrValue === null
    ) {
      updatedFormValues[formIndex][fieldName] = eventOrValue;
    }

    setLeaks(updatedFormValues);
  };

  const handleLeakInputChange = (e, fieldName, type) => {
    let value = type === 'number' ? parseFloat(e.target.value) : e.target.value;

    if (type === 'number' && value < 0) {
      value = 0;
    }

    if (fieldName === 'seconds' && value >= 60) {
      value = 59;
    }

    if (fieldName === 'tagNumber') {
      updateLeakIDOptions();
    }

    setLeaks((prevLeaks) => {
      const updatedLeaks = [...prevLeaks];

      if (updatedLeaks[formIndex]) {
        updatedLeaks[formIndex][fieldName] = value;

        const hours = '00';
        let minutes = updatedLeaks[formIndex].minutes || 0;
        let seconds = updatedLeaks[formIndex].seconds || 0;

        if (fieldName === 'minutes') {
          minutes = value;
        } else if (fieldName === 'seconds') {
          seconds = value;
        }

        const durationTimestamp = `${hours}:${String(minutes).padStart(
          2,
          '0'
        )}:${String(seconds).padStart(2, '0')}`;
        updatedLeaks[formIndex].timestamp = durationTimestamp;
      }

      return updatedLeaks;
    });
  };

  const handleLeak = (value, file) => {
    setLeaks((prevLeaks) => {
      const updatedLeaks = [...prevLeaks];

      if (formIndex >= 0 && formIndex < updatedLeaks.length) {
        updatedLeaks[formIndex][file] = value;
      } else {
        console.error(
          `Error updating leak: Invalid index ${formIndex}`
        );
      }

      return updatedLeaks;
    });
  };

  const getByLeak = (file) => {
    if (formIndex >= 0 && formIndex < leaks.length) {
      return leaks[formIndex]?.[file];
    }
    console.error(`Error fetching leak: Invalid index ${formIndex}`);
    return null;
  };

  const removeLeak = (i, file) => {
    setLeaks((prevLeaks) => {
      const updatedLeaks = [...prevLeaks];

      if (i >= 0 && i < updatedLeaks.length) {
        updatedLeaks[i] = { ...updatedLeaks[i] };
        delete updatedLeaks[i][file];
      } else {
        console.error(`No form found at index ${i}`);
      }

      return updatedLeaks;
    });
  };

  const capitalizeFirstLetter = (string) => {
    if (!string || typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const parseTimestamp = (timestamp) => {
    const parts = timestamp.split(':');
    return {
      minutes: parseInt(parts[1], 10),
      seconds: parseInt(parts[2], 10)
    };
  };

  useEffect(() => {
    if (leaks[formIndex]?.timestamp) {
      const { minutes, seconds } = parseTimestamp(
        leaks[formIndex].timestamp
      );

      setLeaks((prevLeaks) => {
        const newLeaks = [...prevLeaks];
        newLeaks[formIndex] = {
          ...newLeaks[formIndex],
          minutes,
          seconds
        };
        return newLeaks;
      });
    }
  }, [leaks[formIndex]?.timestamp, formIndex]);

  useEffect(() => {
    handleDataLoading(
      componentTypes?.enumerations?.componentType,
      isComponentTypeLoading,
      componentTypesError,
      setComponentTypeOptions,
      'componentTypes'
    );
  }, [componentTypes, isComponentTypeLoading, componentTypesError]);

  return (
    <Form isExpanded={isExpanded}>
      <LeaksHeader>
        {`Leak ${formIndex + 1}`}
        <ExpandableButton
          type="button"
          className="survey-toggle-button"
          onClick={() => setIsExpanded(!isExpanded)}
          tabIndex={0}
        >
          {isExpanded ? (
            <IoChevronUpOutline style={{ color: global.Gray4 }} />
          ) : (
            <IoChevronDownOutline style={{ color: global.Gray4 }} />
          )}
        </ExpandableButton>
      </LeaksHeader>

      {isExpanded && (
        <FieldsDiv>
          <Label>Component type</Label>
          <Select
            isClearable
            autosize
            styles={customStyles}
            className="detail-select componentType"
            classNamePrefix="detail-select"
            value={
              componentTypeOptions.find(
                (option) => option.label
                                    === leaks[formIndex]?.componentType
              ) || null
            }
            options={componentTypeOptions || []}
            placeholder="Select component type"
            onChange={(value) => handleInputChange(value?.label ?? null, 'componentType')}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator,
              DropdownIndicator
            }}
          />

          <Label>Sub-component</Label>
          <StyledInput
            placeholder="Type sub-component"
            type="text"
            value={leaks[formIndex]?.componentSubtype}
            onChange={(value) => handleLeakInputChange(
              value,
              'componentSubtype',
              'text'
            )}
          />
          <Label>Timestamp</Label>
          <SubLabel>
            The timestamp in the video this leak occurs
          </SubLabel>
          <TimeInputContainer>
            <InputWrapper id="leftWrapper">
              <StyledInput
                id="left"
                type="number"
                min="0"
                value={
                  leaks[formIndex]?.minutes > 0
                    ? leaks[formIndex]?.minutes
                    : ''
                }
                placeholder="0"
                onChange={(value) => handleLeakInputChange(
                  value,
                  'minutes',
                  'number'
                )}
              />
            </InputWrapper>

            <InputWrapper>
              <StyledInput
                id="right"
                type="number"
                min="0"
                value={
                  leaks[formIndex]?.seconds > 0
                    ? leaks[formIndex]?.seconds
                    : ''
                }
                placeholder="0"
                onChange={(value) => handleLeakInputChange(
                  value,
                  'seconds',
                  'number'
                )}
              />
            </InputWrapper>
          </TimeInputContainer>

          <Label>Location</Label>
          <StyledInput
            placeholder="Type location"
            type="text"
            value={leaks[formIndex]?.location}
            onChange={(value) => handleLeakInputChange(value, 'location', 'text')}
          />
          <Label>Leak measurement</Label>
          <ToggleSwitch
            type={leaks[formIndex]?.rateUom}
            setType={(newValue) => handleInputChange(newValue, 'rateUom', formIndex)}
            text1="MCF"
            text2="PPM.M"
          />
          <Label>
            {leaks[formIndex]?.rateUom ?? 'PPM.M'}
            {' '}
            (optional)
          </Label>
          <RightPlaceholderInput
            content={leaks[formIndex]?.rateUom === 'PPM.M' ? 'Parts per million' : 'MCF'}
          >
            <StyledInput
              type="number"
              placeholder="0"
              id="parts-per-million"
              value={leaks[formIndex]?.rate}
              onChange={(value) => handleLeakInputChange(value, 'rate', 'number')}
            />
          </RightPlaceholderInput>
          <Label>Leak ID / Tag</Label>
          <StyledInput
            placeholder="12345678"
            type="number"
            value={leaks[formIndex]?.tagNumber}
            onChange={(value) => handleLeakInputChange(value, 'tagNumber', 'number')}
          />
          <Label>Priority (optional)</Label>
          <Select
            isClearable
            autosize
            styles={customStyles}
            className="detail-select priorityType"
            classNamePrefix="detail-select-priority"
            value={
              priorityOptions(leaks[formIndex]?.priority).find(
                (option) => option.value === leaks[formIndex]?.priority
              ) || null
            }
            placeholder="Select leak priority"
            options={priorityOptions(leaks[formIndex]?.priority)}
            menuPlacement="top"
            onChange={(value) => handleInputChange(value?.value ?? value, 'priority')}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator,
              DropdownIndicator
            }}
          />
          <Label>Leak Summary</Label>
          <LeakSummary>
            <div>
              <SummaryHeader>
                <ComponentTitle>
                  {capitalizeFirstLetter(
                    leaks[formIndex]?.componentType
                  ) || 'Component type'}
                </ComponentTitle>
                <SummaryContent>
                  {leaks[formIndex]?.minutes
                                    || leaks[formIndex]?.seconds
                    ? `${leaks[formIndex]?.minutes} minutes ${leaks[formIndex]?.seconds} seconds`
                    : 'Timestamp'}
                </SummaryContent>
              </SummaryHeader>
              <SummaryContent>
                <div className="overflow-content">
                  {capitalizeFirstLetter(
                    leaks[formIndex]?.componentSubtype
                  ) || 'Sub-component type'}
                </div>
                <div className="rate-content">{leaks[formIndex]?.rateUom || 'ppm.m'}</div>
              </SummaryContent>
            </div>

            <div>{leaks[formIndex]?.location || 'Location'}</div>
          </LeakSummary>
          <Label>Leak images</Label>
          <SubLabel>Files should be .jpg, .png, or .heic</SubLabel>
          <DropZones>
            <FileDropzone
              id={`leak-1-${formIndex}`}
              height="200px"
              onFileAdded={(value) => handleLeak(value, 'leak1')}
              onFileRemoved={() => removeLeak(formIndex, 'leak1')}
              value={getByLeak('leak1')}
              editableName="Leak1"
            />
            <Spacer />
            <FileDropzone
              id={`leak-2-${formIndex}`}
              height="200px"
              onFileAdded={(value) => handleLeak(value, 'leak2')}
              onFileRemoved={() => removeLeak(formIndex, 'leak2')}
              value={getByLeak('leak2')}
              editableName="Leak2"
            />
          </DropZones>
          <Label>Additional notes (optional)</Label>
          <StyledInput
            placeholder="Anything else you would like to add?"
            type="text"
            value={leaks[formIndex]?.notes}
            onChange={(value) => handleLeakInputChange(value, 'notes', 'text')}
          />
        </FieldsDiv>
      )}
    </Form>
  );
};

LeaksForm.propTypes = {
  formIndex: PropTypes.number.isRequired,
  leaks: PropTypes.arrayOf(
    PropTypes.shape({
      componentType: PropTypes.string,
      componentSubtype: PropTypes.string,
      minutes: PropTypes.number,
      seconds: PropTypes.number,
      location: PropTypes.string,
      rateUom: PropTypes.string,
      rate: PropTypes.number,
      leakId: PropTypes.number,
      priority: PropTypes.string,
      leak1: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      leak2: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    })
  ).isRequired,
  setLeaks: PropTypes.func.isRequired
};

export default LeaksForm;
