import React from 'react';
import { GlobalProvider } from './context/GlobalContext';
import { ModifyInspectionsProvider } from './context/ModifyInspectionsContext';

const AppProviders = ({ children }) => (
  <GlobalProvider>
    <ModifyInspectionsProvider>{children}</ModifyInspectionsProvider>
  </GlobalProvider>
);

export default AppProviders;
