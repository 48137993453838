import React from 'react';
import styled from 'styled-components';

import { global } from '../../../shared/colors';

const StyledButton = styled.button`
    width: 191px;
    height: 23px;
    top: 743px;
    left: 762px;
    border-radius: 30px;
    border: 1px solid ${global.Gray2};
    background: ${global.White};
    color: ${global.Gray2};
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    margin-top: 80px;
    cursor: pointer;

    &:hover {
        opacity: 65%;
    }
`;

const StyledDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ClearButton = ({ onClear }) => (
  <StyledDiv>
    <StyledButton onClick={onClear}>
      Clear all fields in this section
    </StyledButton>
  </StyledDiv>
);
export default ClearButton;
