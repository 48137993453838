import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { global } from '../../../../shared/colors';
import placeholderImage from '../../image-outline.svg';

const flexCenter = `
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    width: 88px;
    height: auto;
    margin: auto;
`;

const UploadLabel = styled.p`
    color: ${global.PrimaryBlue};
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
`;

const Placeholder = styled.div`
    ${flexCenter}
    flex-direction: column;
`;

const FileContainer = styled.div`
    ${flexCenter}
    flex: 1;
    flex-direction: column;
    border-width: 1px;
    border-radius: 10px;
    border-color: ${global.Gray5};
    border-style: dashed;
    background-color: ${global.White};
    color: ${global.Gray5};
    outline: none;
    transition: border 0.24s ease-in-out;
    width: 100%;
    cursor: pointer;
    height: 329px;
`;

const ImageDropzone = ({ onFileChange, filePreview }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/heic',
    onDrop: (acceptedFiles) => {
      onFileChange(acceptedFiles[0]);
    }
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {!filePreview && (
        <FileContainer>
          <Placeholder>
            <Image src={placeholderImage} alt="placeholder" />
            <UploadLabel>Upload media</UploadLabel>
          </Placeholder>
        </FileContainer>
      )}
    </div>
  );
};

ImageDropzone.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  filePreview: PropTypes.string
};

ImageDropzone.defaultProps = {
  filePreview: null
};

export default ImageDropzone;
