import styled, { css } from 'styled-components';

import { global } from '../../../shared/colors';

const buttonStyles = css`
    border-radius: 10px;
    width: 120px;
    height: 43px;
    cursor: pointer;
    border: none;
`;

const normalStyles = css`
    background: ${global.Black};
    color: ${global.White};

    &:hover {
        opacity: 0.65;
    }
`;

const disabledStyles = css`
    background: #bcbcbc;
    color: #dbdbdb;
    cursor: not-allowed;
    opacity: 0.5;
`;

const Next = styled.button`
    ${buttonStyles};
    ${normalStyles};

    &:disabled {
        ${disabledStyles};
    }
`;

export default Next;
