const writeUploadDataToDB = async ({
  uploadData,
  file,
  getRequestEndpointFromFile,
  token
}) => {
  const url = getRequestEndpointFromFile(file);
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(uploadData)
    });

    if (response.ok) {
      return await response.json();
    }

    throw response;
  } catch (error) {
    return error;
  }
};

export default writeUploadDataToDB;
