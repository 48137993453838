/* eslint @typescript-eslint/no-unused-vars: off */
/* eslint-disable-next-line */
/* eslint max-len: off */
import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useMemo
} from 'react';
import { formatISO } from 'date-fns';

import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import {
  emptyBaseMonitoredComponent,
  inspectionSections
} from '../shared/constants';
import { GlobalContext } from './GlobalContext';
import {
  getInspection,
  getMethods,
  getInspectors,
  getSurveyInstruments
} from '../services/airmethaneApi';
import useGetData from '../hooks/useGetData';
import { handleOptionsLoading } from '../shared/utils';
import { determineSectionCompletion } from '../pages/ModifyInspection/utils';

const DEFAULTS = {
  part: 1,
  leakSubNav: 1,
  leakSummarySubNav: 1,
  surveys: [],
  leaks: [],
  verifications: [],
  monitoredComponents: [],
  activeTab: 'site-details',
  activeSubTab: 'info',
  fields: {
    facilitySign: []
  },
  complianceType: 'OOOO',
  operationalStatus: 'Running',
  isLeaks: 'No',
  isOnSite: 'No',
  isDeviations: 'No',
  completedSections: [],
  editInspectionData: {
    isPending: false,
    inspectionData: null,
    inspectionId: null
  }
};

export const ModifyInspectionsContext = createContext(DEFAULTS);

export const ModifyInspectionsProvider = ({ children }) => {
  const { triggerGlobalNotification, token } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [part, setPart] = useLocalStorage('part', DEFAULTS.part);
  const [leakOGI, setLeakOGI] = useState([]);
  const [completedSections, setCompletedSections] = useState(
    DEFAULTS.completedSections
  );
  const [inspectorOptions, setInspectionOptions] = useState([]);
  const [methodOptions, setMethodOptions] = useState([]);
  const [instrumentsOptions, setInstrumentsOptions] = useState([]);
  const [facilitySign, setFacilitySign] = useState([]);
  const [sites, setSites] = useLocalStorage('sites', []);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [inspectionDate, setInspectionDate] = useState('');
  const [leakDate, setLeakDate] = useLocalStorage('part', null);
  const [leakIDOptions, setLeakIDOptions] = useState([]);
  const [inspectionType, setInspectionType] = useLocalStorage(
    'inspectionType',
    null
  );
  const [editInspectionData, setEditInspectionData] = useState(
    DEFAULTS.editInspectionData
  );
  const [fields, setFields] = useLocalStorage('fields', DEFAULTS.fields);
  const [isLeaks, setIsLeaks] = useLocalStorage('isLeaks', DEFAULTS.isLeaks);
  const [formData, setFormData] = useState(DEFAULTS);
  const [leakSubNav, setLeakSubNav] = useLocalStorage(
    'leakSubNav',
    DEFAULTS.leakSubNav
  );
  const [leakSummarySubNav, setLeakSummarySubNav] = useLocalStorage(
    'leakSummarySubNav',
    DEFAULTS.leakSummarySubNav
  );
  const [surveys, setSurveys] = useLocalStorage('surveys', DEFAULTS.surveys);
  const [monitoredComponents, setMonitoredComponents] = useLocalStorage(
    'monitoredComponents',
    DEFAULTS.monitoredComponents
  );
  const [leaks, setLeaks] = useLocalStorage('leaks', DEFAULTS.leaks);
  const [leakRepair, setLeakRepair] = useLocalStorage('leakRepairs', []);
  const [verifications, setVerifications] = useLocalStorage(
    'verifications',
    DEFAULTS.verifications
  );
  const [activeTab, setActiveTab] = useLocalStorage(
    'activeTab',
    DEFAULTS.activeTab
  );
  const [activeSubTab, setActiveSubTab] = useLocalStorage(
    'activeSubTab',
    DEFAULTS.activeSubTab
  );
  const [isDeviations, setIsDeviations] = useLocalStorage(
    'isDeviations',
    DEFAULTS.isDeviations
  );
  const [isOnSite, setOnSite] = useLocalStorage('isOnSite', DEFAULTS.isOnSite);
  const [complianceType, setComplianceType] = useLocalStorage(
    'complianceType',
    DEFAULTS.complianceType
  );
  const [operationalStatus, setOperationalStatus] = useLocalStorage(
    'operationalStatus',
    DEFAULTS.operationalStatus
  );
  const {
    data: methods,
    error: methodsError,
    isLoading: isMethodsLoading
  } = useGetData(token, 'methods', getMethods);
  const {
    data: inspectors,
    error: inspectorsError,
    isLoading: isInspectorsLoading
  } = useGetData(token, 'inspectors', getInspectors);
  const {
    data: instruments,
    error: instrumentsError,
    isLoading: isInstrumentsLoading
  } = useGetData(token, 'instruments', getSurveyInstruments);

  const updateFiles = (index, updater) => {
    const updatedForms = [...surveys];
    updatedForms[index].files = updater(updatedForms[index].files);
    setSurveys(updatedForms);
  };

  const updateLeakIDOptions = () => {
    const newLeakIDOptions = leaks.map((leak) => ({
      id: leak.id,
      value: leak.tagNumber?.toString(),
      label: leak.tagNumber?.toString()
    }));
    setLeakIDOptions(newLeakIDOptions);
  };

  const addRepairsToLeaks = () => {
    const updatedLeaks = leaks.map((leak) => {
      const repairsForThisLeak = leakRepair.filter(
        (repair) => repair.leakId === leak.tagNumber
          || repair.leakId === leak.id
          || repair.leakId === leak.localId
      );
      return { ...leak, leakRepairs: repairsForThisLeak };
    });
    setLeaks(updatedLeaks);
  };

  const updateLeakWithRepair = (leakID, updatedRepair) => {
    setLeaks((currentLeaks) => currentLeaks.map((leak) => {
      const identifier = leak.id || leak.localId || leak.tagNumber;

      if (identifier === leakID || identifier === leak.tagNumber) {
        if (updatedRepair === null) {
          return {
            ...leak,
            leakRepairs: leak.leakRepairs.filter(
              (repair) => (repair.id || repair.tagNumber) !== leakID
            )
          };
        }

        const updatedLeakRepairs = leak.leakRepairs.filter(
          (repair) => (repair.id || repair.tagNumber) !== updatedRepair.id
        );
        updatedLeakRepairs.push(updatedRepair);
        return {
          ...leak,
          leakRepairs: updatedLeakRepairs
        };
      }
      return leak;
    }));
  };

  const formatPayloadForServer = (leaksData) => leaksData?.map(({ localId: outerLeakId, ...leak }) => ({
    ...leak,
    leakRepairs: leak?.leakRepairs?.map(({ localId, leakId, ...rest }) => (outerLeakId ? { ...rest } : { ...rest, leakId }))
  }));

  const clearFields = () => {
    setFields({});
    setSurveys([]);
    setLeaks(leaks.map((leak) => ({ ...leak, leakRepairs: [] })));
    setLeaks([]);
    setLeakRepair([]);
    setVerifications([]);
    setInspectionDate('');
    setIsLeaks('No');
    setActiveTab('site-details');
    setOnSite('No');
    setStartTime(null);
    setEndTime(null);
    setPart(1);
    setLeakSubNav(1);
    setLeakSummarySubNav(1);
    setMonitoredComponents(DEFAULTS.monitoredComponents);
    setCompletedSections(DEFAULTS.completedSections);
    setFacilitySign([]);
    setInspectionType(null);
    localStorage.setItem('leaks', JSON.stringify([]));
    localStorage.setItem('leakRepairs', JSON.stringify([]));
  };

  const removeLocalIdFromArrayOfItems = (items) => items?.map(({ localId, ...rest }) => rest);

  const handleFormData = () => {
    const fieldsCopy = { ...fields };
    const {
      monitoredComponents: monitoredComponentsCopy,
      surveys: surveysCopy,
      verifications: verificationsCopy
    } = fieldsCopy;
    const monitoredComponentsWithoutLocalId = removeLocalIdFromArrayOfItems(
      monitoredComponentsCopy
    );
    const surveysWithoutLocalId = removeLocalIdFromArrayOfItems(surveysCopy);
    const verificationsWithoutLocalId = removeLocalIdFromArrayOfItems(verificationsCopy);

    const modifiedSurveys = surveysWithoutLocalId
      ?.map((survey) => {
        const surveyCompletionPercent = determineSectionCompletion(
          inspectionSections.SURVEY_DETAILS,
          survey
        );
        if (surveyCompletionPercent === 0) return null;
        if (survey.leakDetections && survey.leakDetections.length === 0) {
          const { ...surveyWithoutLeakDetections } = survey;
          return surveyWithoutLeakDetections;
        }
        return survey;
      })
      .filter((survey) => survey !== null);

    const modifiedMonitoredComponents = monitoredComponentsWithoutLocalId
      ?.map((component) => {
        const { ...restOfComponent } = component;
        const defaultEntries = Object.entries(emptyBaseMonitoredComponent);
        let hasNonDefaultValues = false;
        for (let i = 0; i < defaultEntries.length; i += 1) {
          if (restOfComponent[defaultEntries[i][0]] !== defaultEntries[i][1]) {
            hasNonDefaultValues = true;
            break;
          }
        }
        return hasNonDefaultValues ? component : null;
      })
      .filter((component) => component !== null);

    const formattedFields = {
      ...fieldsCopy,
      leaks: formatPayloadForServer(fields.leaks),
      surveys: modifiedSurveys,
      verifications: verificationsWithoutLocalId,
      monitoredComponents: modifiedMonitoredComponents
    };

    setFormData({
      ...formattedFields,
      statusId: 1
    });
  };

  const resetFormLocation = () => {
    setPart(DEFAULTS.part);
    setActiveTab(DEFAULTS.activeTab);
    setActiveSubTab(DEFAULTS.activeSubTab);
  };

  const resetEditInspectionData = () => setEditInspectionData(DEFAULTS.editInspectionData);

  const getInspectionById = async (id) => {
    setEditInspectionData({
      ...editInspectionData,
      inspectionId: id,
      isPending: true
    });
    try {
      const data = await getInspection(token, id);
      const inspectionData = data?.inspections?.at(0);
      const allLeakRepairs = inspectionData.leaks
        .map((leak) => leak.leakRepairs)
        .reduce((acc, current) => acc.concat(current), []);
      setFields(inspectionData);
      setStartTime(inspectionData.startTime);
      setEndTime(inspectionData.endTime);
      setVerifications(inspectionData.verifications);
      setInspectionDate(inspectionData.inspectionDate);
      setSurveys(inspectionData.surveys);
      setLeaks(inspectionData.leaks);
      setLeakRepair(allLeakRepairs);
      setMonitoredComponents(inspectionData.monitoredComponents);
      setEditInspectionData({
        ...editInspectionData,
        inspectionData,
        isPending: false
      });
    } catch (error) {
      triggerGlobalNotification('error', 'Failed to fetch inspection');
      resetEditInspectionData();
      navigate('/');
    }
  };

  useEffect(() => {
    handleOptionsLoading(
      methods?.methods,
      isMethodsLoading,
      methodsError,
      setMethodOptions,
      'methods'
    );
    handleOptionsLoading(
      inspectors?.inspectors,
      isInspectorsLoading,
      inspectorsError,
      setInspectionOptions,
      'inspectors'
    );
    handleOptionsLoading(
      instruments?.surveyInstruments,
      isInstrumentsLoading,
      instrumentsError,
      setInstrumentsOptions,
      'instruments'
    );
  }, [
    methods,
    isMethodsLoading,
    methodsError,
    inspectors,
    isInspectorsLoading,
    inspectorsError,
    instruments,
    isInstrumentsLoading,
    instrumentsError
  ]);

  useEffect(() => {
    handleFormData();
  }, [
    fields,
    facilitySign,
    leakOGI,
    leakRepair,
    complianceType,
    inspectionType,
    isLeaks,
    isDeviations,
    isOnSite,
    operationalStatus,
    monitoredComponents
  ]);

  useEffect(() => {
    setLeakIDOptions(
      leaks.map((leak) => ({
        id: leak?.id,
        localId: leak?.localId,
        value: leak?.tagNumber?.toString(),
        label: leak?.tagNumber?.toString()
      }))
    );
  }, [leaks]);

  const formattedDate = inspectionDate && formatISO(new Date(inspectionDate));

  useEffect(() => {
    addRepairsToLeaks();
  }, [leakRepair]);

  useEffect(() => {
    setFields((prevFields) => ({
      ...prevFields,
      surveys,
      monitoredComponents,
      leaks,
      verifications,
      facilitySign,
      leakOGI,
      complianceType,
      inspectionType,
      operationalStatus,
      isLeaks,
      isDeviations,
      isOnSite,
      startTime,
      endTime,
      inspectionDate: formattedDate
    }));
  }, [
    surveys,
    monitoredComponents,
    leaks,
    verifications,
    facilitySign,
    leakOGI,
    complianceType,
    inspectionType,
    isLeaks,
    isDeviations,
    isOnSite,
    operationalStatus,
    startTime,
    endTime,
    inspectionDate,
    leakRepair
  ]);

  const providerValue = useMemo(
    () => ({
      activeTab,
      setActiveTab,
      activeSubTab,
      setActiveSubTab,
      surveys,
      setSurveys,
      monitoredComponents,
      setMonitoredComponents,
      leaks,
      setLeaks,
      leakRepair,
      setLeakRepair,
      verifications,
      setVerifications,
      updateFiles,
      updateLeakIDOptions,
      fields,
      setFields,
      leakOGI,
      setLeakOGI,
      facilitySign,
      setFacilitySign,
      complianceType,
      setComplianceType,
      inspectionType,
      setInspectionType,
      isLeaks,
      isDeviations,
      setIsLeaks,
      setIsDeviations,
      operationalStatus,
      setOperationalStatus,
      startTime,
      setStartTime,
      endTime,
      setEndTime,
      sites,
      setSites,
      part,
      setPart,
      inspectionDate,
      setInspectionDate,
      leakDate,
      setLeakDate,
      leakSubNav,
      setLeakSubNav,
      leakSummarySubNav,
      setLeakSummarySubNav,
      isOnSite,
      setOnSite,
      leakIDOptions,
      formData,
      clearFields,
      updateLeakWithRepair,
      completedSections,
      setCompletedSections,
      editInspectionData,
      resetEditInspectionData,
      getInspectionById,
      resetFormLocation,
      methodOptions,
      inspectorOptions,
      instrumentsOptions,
      inspectors
    }),
    [
      activeTab,
      activeSubTab,
      surveys,
      monitoredComponents,
      leaks,
      leakRepair,
      verifications,
      fields,
      leakOGI,
      facilitySign,
      complianceType,
      inspectionType,
      isLeaks,
      isDeviations,
      operationalStatus,
      startTime,
      endTime,
      sites,
      part,
      inspectionDate,
      leakDate,
      leakSubNav,
      leakSummarySubNav,
      isOnSite,
      leakIDOptions,
      formData,
      completedSections,
      editInspectionData,
      methodOptions,
      inspectorOptions,
      instrumentsOptions,
      inspectors
    ]
  );

  return (
    <ModifyInspectionsContext.Provider value={providerValue}>
      {children}
    </ModifyInspectionsContext.Provider>
  );
};
