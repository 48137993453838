import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import Back from '../../components/Back';
import Next from '../../components/Next';
import FooterNav from '../../components/FooterNav';
import Spacer from '../../components/Spacer';
import SubmitButton from '../../components/SubmitButton';
import { GlobalContext } from '../../../../context/GlobalContext';
import { ModifyInspectionsContext } from '../../../../context/ModifyInspectionsContext';
import { global } from '../../../../shared/colors';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal.tsx';

const Container = styled.section`
    width: 60%;
`;

const Header = styled.h1`
    color: ${global.Black};
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 45px;
`;

const SubLabel = styled.p`
    color: ${global.Gray2};
    font-size: 20px;
    font-weight: 600;
    margin-top: 60px;
    margin-bottom: 8px;
`;

const StyledParagraph = styled.p`
    max-width: 535px;
    font-size: 16px;
    font-weight: 400;
    color: ${global.Gray3};
    margin: 0;
`;

const Label = styled.p`
    color: ${global.Gray4};
    font-size: 20px;
    margin-top: 8px;
    margin-bottom: 40px;
`;

const FinalSubmission = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const { toggleSideNav, sideNavVisible, setSideNavVisibility } = useContext(GlobalContext);
  const { setActiveTab } = useContext(ModifyInspectionsContext);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    toggleSideNav();
    setSideNavVisibility(!sideNavVisible);
    if (location.pathname === '/inspections') {
      navigate('/');
    }
  };

  const closeSubmitModal = () => {
    setIsSubmitModalOpen(false);
  };

  const confirmSubmit = () => {
    handleClick(setIsSubmitting);
  };

  const handleBack = () => setActiveTab('inspection-summary');

  return (
    <>
      <Container>
        <Header>Before you submit</Header>
        <Label>Make sure you have everything ready</Label>
        <SubLabel>I understand...</SubLabel>
        <StyledParagraph>
          I understand that once I submit this form, I will not be
          able to make any changes or adjustments to the content or
          inspection created. I also confirm that all the documents
          and images included in this form meet the criteria for
          inspection established by the relevant state and federal
          regulations. I am aware that I will be held accountable if
          the documents and images fail to meet these standards.
          Therefore, I take full responsibility for the accuracy and
          completeness of the submission I am providing.
        </StyledParagraph>
        <SubmitButton onClick={setIsSubmitModalOpen} disabled>
          Submit inspection
        </SubmitButton>
        <Spacer />
      </Container>
      <FooterNav>
        <Back type="submit" onClick={handleBack}>
          Back
        </Back>
        <Next type="submit" onClick={(e) => console.log(e)} disabled>
          Submit
        </Next>
      </FooterNav>
      <ConfirmationModal
        isOpen={isSubmitModalOpen}
        onClose={closeSubmitModal}
        onConfirm={confirmSubmit}
        isProcessing={isSubmitting}
        modalHeader="Are you sure?"
        confirmButtonText="Yes, submit"
        confirmButtonColor={global.PrimaryBlue}
        cancelButtonText="No, don't submit"
        cancelButtonColor={global.White}
        cancelTextColor={global.Black}
        modalText={(
          <>
            Submitting this inspection will finalize all information
            you have entered. You will not be able to edit after
            submission.
            <strong>This action cannot be undone.</strong>
          </>
        )}
      />
    </>
  );
};

export default FinalSubmission;
