import { useState, useEffect } from 'react';
import useLocalStorage from './useLocalStorage';

const useGetData = (token, key, fetchData) => {
  const [data, setData] = useLocalStorage(key, null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDataWrapper = async () => {
      if (!token) {
        setError('Authentication token is invalid or not provided.');
        return;
      }

      try {
        if (!data) {
          setIsLoading(true);
          const fetchedData = await fetchData(token);
          setData(fetchedData);
          setIsLoading(false);
        }
      } catch (fetchError) {
        setError(fetchError);
        setIsLoading(false);
      }
    };

    fetchDataWrapper();
  }, [token, data, setData]);

  return { data, error, isLoading };
};

export default useGetData;
