import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import Select from 'react-select';
import { Input } from 'antd';
import { IoChevronDownOutline, IoChevronUpOutline, IoRemoveCircle } from 'react-icons/io5';

import ClearIndicator from '../../../../components/Select/ClearIndicator';
import DropdownIndicator from '../../../../components/Select/DropdownIndicator';
import customStyles from '../../components/customStyles/customStyles';
import { ModifyInspectionsContext } from '../../../../context/ModifyInspectionsContext';
import './MonitoredComponentCard.scss';

const localCustomStyles = {
  ...customStyles,
  placeholder: (base) => ({
    ...base,
    fontSize: '16px',
    color: global.Gray4,
    paddingLeft: '20px'
  })
};

const { TextArea } = Input;

const MonitoredComponentCard = ({ monitoredComponent }) => {
  const {
    monitoredComponents,
    setMonitoredComponents,
    instrumentsOptions
  } = useContext(ModifyInspectionsContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const confirmRef = useRef();

  const componentIndex = monitoredComponents.findIndex((component) => (monitoredComponent?.id
    ? component?.id === monitoredComponent?.id
    : component?.localId === monitoredComponent?.localId));

  const updateComponentValues = (fieldName, value) => {
    const newComponentList = [...monitoredComponents];

    newComponentList[componentIndex][fieldName] = value;
    setMonitoredComponents(newComponentList);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (confirmRef.current && !confirmRef.current.contains(event.target)) {
        setConfirmDelete(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="component-card-container">
      <button
        ref={confirmRef}
        type="button"
        className={`delete-button ${confirmDelete ? 'confirm' : ''}`}
        onClick={() => {
          if (confirmDelete) {
            const newComponentList = [...monitoredComponents];
            newComponentList.splice(componentIndex, 1);
            setMonitoredComponents(newComponentList);
            setConfirmDelete(false);
          } else {
            setConfirmDelete(true);
          }
        }}
      >
        {confirmDelete ? (
          <div className="confirm-delete">Delete</div>
        ) : (
          <IoRemoveCircle className="delete-icon" size={24} />
        )}
      </button>
      <div className="component-card">
        <button
          type="button"
          className={`expandable-header ${!isExpanded ? 'closed' : ''}`}
          onClick={() => setIsExpanded(!isExpanded)}
          tabIndex={0}
        >
          <p>
            Component
            {componentIndex + 1}
          </p>
          <div>
            {isExpanded ? <IoChevronUpOutline size={24} /> : <IoChevronDownOutline size={24} />}
          </div>
        </button>
        {isExpanded && (
          <>
            <div className="monitored-component-content-row-item">
              <label htmlFor="monitored-component-type" className="field-label">
                Component type
              </label>
              <Select
                id="monitored-component-type"
                isClearable
                styles={localCustomStyles}
                value={
                  instrumentsOptions.find(
                    (option) => option?.value === monitoredComponent?.componentType
                  ) || null
                }
                options={instrumentsOptions || []}
                placeholder="Select component"
                className="detail-select"
                classNamePrefix="detail-select"
                onChange={(value) => updateComponentValues('componentType', value?.value ?? value)}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator,
                  DropdownIndicator
                }}
              />
            </div>
            <div className="component-checkbox">
              <label className="checkbox-group">
                <input
                  type="checkbox"
                  checked={monitoredComponent?.unsafeToMonitor || false}
                  onChange={() => {
                    updateComponentValues('unsafeToMonitor', !monitoredComponent?.unsafeToMonitor);
                  }}
                />
                <span className="custom-checkbox" />
              </label>
              <span className="field-label">Unsafe to monitor</span>
            </div>
            {monitoredComponent?.unsafeToMonitor && (
              <>
                <label htmlFor="unsafe-description" className="field-label">
                  Description
                </label>
                <TextArea
                  className="description-input"
                  placeholder="Why was this unsafe to monitor?"
                  name="unsafe-description"
                  type="textfield"
                  value={monitoredComponent?.unsafeToMonitorDescription || ''}
                  onChange={(e) => updateComponentValues('unsafeToMonitorDescription', e.target.value)}
                />
              </>
            )}
            <div className="component-checkbox">
              <label className="checkbox-group">
                <input
                  type="checkbox"
                  checked={monitoredComponent?.difficultToMonitor || false}
                  onChange={() => {
                    updateComponentValues(
                      'difficultToMonitor',
                      !monitoredComponent?.difficultToMonitor
                    );
                  }}
                />
                <span className="custom-checkbox" />
              </label>
              <span className="field-label">Difficult to monitor</span>
            </div>
            {monitoredComponent?.difficultToMonitor && (
              <>
                <label htmlFor="unsafe-description" className="field-label">
                  Description
                </label>
                <TextArea
                  className="description-input"
                  placeholder="Why was this difficult to monitor?"
                  name="difficult-description"
                  type="textfield"
                  value={monitoredComponent?.difficultToMonitorDescription || ''}
                  onChange={(e) => updateComponentValues('difficultToMonitorDescription', e.target.value)}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MonitoredComponentCard;
