import React, { useContext, useEffect } from 'react';

import Upload from './Upload';
import { GlobalContext } from '../../context/GlobalContext';
import useUploadStatusMap from '../../hooks/useUploadStatusMap';

const InspectionUpload = ({ selectedInspection }) => {
  const { user_organizations, user_sites } = useContext(GlobalContext);

  const { resetUpload } = useUploadStatusMap();

  const selectedOrg = user_organizations.find(
    (org) => org.netsuiteId === selectedInspection.customerId
  );
  const selectedSite = user_sites.find(
    (site) => site.netsuiteId === selectedInspection.customerLocationId
  );

  useEffect(() => resetUpload, [selectedInspection]);

  return (
    <Upload
      resetUpload={resetUpload}
      selectedInspection={selectedInspection}
      selectedOrg={selectedOrg}
      selectedSite={selectedSite}
      inModal
    />
  );
};

export default InspectionUpload;
