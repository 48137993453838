import React, {
  useContext, useState, useEffect, useRef
} from 'react';
import styled from 'styled-components';
import { IoAddOutline, IoRemoveCircle } from 'react-icons/io5';
import { v4 as uuidv4 } from 'uuid';

import '../../components/customStyles/datePicker.css';
import { ModifyInspectionsContext } from '../../../../context/ModifyInspectionsContext';
import FooterNav from '../../components/FooterNav';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import Next from '../../components/Next';
import Back from '../../components/Back';
import Spacer from '../../components/Spacer';
import ToggleSwitch from '../../components/ToggleSwitch';
import LeakManagement from './LeakManagment';
import RepairForm from './RepairForm';
import { global } from '../../../../shared/colors';

const flexDisplay = `
    display: flex;
    align-items: center;
`;

const baseButtonStyles = `
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 600;

    &:hover {
        opacity: 0.65;
    }
`;

const LeakWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const AddLeak = styled.button`
  ${baseButtonStyles}
  font-size: 16px;
  color: ${global.PrimaryBlue};
  text-align: left;

  svg {
    margin-bottom: 4px;
    vertical-align: middle;
  }
`;

const AddRepair = styled.button`
  ${baseButtonStyles}
  font-size: 16px;
  color: ${global.PrimaryBlue};
  text-align: left;

  svg {
    margin-bottom: 4px;
    vertical-align: middle;
  }
`;

const StyledSection = styled.section`
  ${flexDisplay};
  flex-direction: column;
  align-items: flex-start;
`;

const ToggleLabel = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${global.Gray3};
  margin: 0;
`;

const SubHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

const RepairRow = styled.div`
  ${flexDisplay}
  width: 100%;
  justify-content: flex-start;
  margin-left: -10px;
`;

const ConfirmDiv = styled.div`
  ${flexDisplay};
  justify-content: center;
  height: 100%;
`;

const ConfirmDeleteButton = styled.button`
  ${baseButtonStyles}
  width: 75px;
  height: 43px;
  margin: -20px 10px 0 -73px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 19.36px;
  border: 1px solid ${global.ErrorRed};
  color: ${global.ErrorRed};

  &:hover {
    background-color: ${global.ErrorRed};
    opacity: 1;
    color: ${global.White};
  }
`;

const RemoveButton = styled.button`
  ${baseButtonStyles}
  height: 40px;
`;

const StyledRemoveIcon = styled(IoRemoveCircle)`
  color: ${global.ErrorRed};
  transition: opacity 0.3s ease;
  font-size: 24px;
  margin: -3px 0 16px -66px;

  &:hover {
    opacity: 0.65;
  }
`;

const Leaks = () => {
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const confirmRef = useRef(null);
  const [inputsHaveValue, setInputsHaveValue] = useState(false);
  const {
    setActiveTab,
    setActiveSubTab,
    leakSubNav,
    setLeakSubNav,
    leaks,
    setLeaks,
    isLeaks,
    isOnSite,
    setOnSite,
    setIsLeaks,
    fields,
    leakOGI,
    leakDate,
    leakRepair,
    setLeakRepair
  } = useContext(ModifyInspectionsContext);

  const handleBack = () => setActiveTab('inspection-details');
  const handleLeakSubNavNext = () => setLeakSubNav(2);
  const handleLeakSubNavBack = () => setLeakSubNav(1);
  const handleNext = () => setActiveTab('inspection-summary');

  const addLeak = () => {
    const newLeak = { localId: uuidv4(), leakRepairs: [], rateUom: 'PPM.M' };
    setLeaks([...leaks, newLeak]);
  };

  const addRepair = () => {
    const newRepair = { localId: uuidv4() };
    const updatedRepairs = [...leakRepair, newRepair];
    setLeakRepair(updatedRepairs);
  };

  const removeRepair = (idToRemove) => {
    // eslint-disable-next-line max-len
    setLeakRepair((prevLeakRepair) => prevLeakRepair.filter((repair) => (repair.id || repair.localId) !== idToRemove));

    const updatedLeaks = leaks.map((leak) => ({
      ...leak,
      leakRepairs:
        leak.leakRepairs?.filter((repair) => (repair.id || repair.localId) !== idToRemove) || []
    }));
    setLeaks(updatedLeaks);
  };

  const handleRemoveConfirm = (repairId) => {
    setDeleteConfirm(repairId);
  };

  const handleCancelRemove = () => {
    setDeleteConfirm(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && deleteConfirm !== null) {
        setDeleteConfirm(null);
        handleCancelRemove();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [deleteConfirm]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (confirmRef.current && !confirmRef.current.contains(event.target)) {
        setDeleteConfirm(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [confirmRef]);

  useEffect(() => {
    const hasValue = leakRepair.length > 0;
    setInputsHaveValue(hasValue);
  }, [leakDate, leakOGI, fields]);

  useEffect(() => {
    setActiveSubTab('surveys');
  }, []);

  useEffect(() => {
    if (isOnSite === 'Yes' && leakRepair.length === 0) {
      addRepair();
    }
  }, [isOnSite]);

  useEffect(() => {
    if (isLeaks === 'Yes' && leaks.length === 0) {
      addLeak();
    }
    if (isLeaks === 'No' && leaks.length > 0) {
      setIsLeaks('Yes');
    }
  }, [isLeaks, leaks]);

  return leakSubNav === 1 ? (
    <>
      <LeakWrapper>
        <Header>Leak details</Header>
        <StyledSection>
          <SubHeaderRow>
            <SubHeader>Part 1 of 2</SubHeader>
            <AddLeak type="button" onClick={addLeak}>
              <IoAddOutline size={16} />
              {' '}
              Add new leak
            </AddLeak>
          </SubHeaderRow>
          <ToggleLabel>Were any leaks identified by the inspection?</ToggleLabel>
          <ToggleSwitch
            type={isLeaks}
            setType={setIsLeaks}
            text1="No"
            text2="Yes"
            disabled={leaks.length > 0}
          />
        </StyledSection>
        <LeakManagement leaks={leaks} setLeaks={setLeaks} setIsLeaks={setIsLeaks} />
        <AddLeak type="button" onClick={addLeak}>
          <IoAddOutline size={16} />
          {' '}
          Add new leak
        </AddLeak>
        <Spacer />
      </LeakWrapper>
      <FooterNav>
        <Back type="submit" onClick={handleBack}>
          Back
        </Back>
        <Next type="submit" onClick={handleLeakSubNavNext}>
          Next
        </Next>
      </FooterNav>
    </>
  ) : (
    <>
      <LeakWrapper>
        <Header>Leak details</Header>
        <StyledSection>
          <SubHeaderRow>
            <SubHeader>Part 2 of 2</SubHeader>
            {isOnSite === 'Yes' && (
              <AddRepair type="button" onClick={addRepair}>
                <IoAddOutline size={16} />
                {' '}
                Add new repair
              </AddRepair>
            )}
          </SubHeaderRow>
        </StyledSection>
        <ToggleLabel>Were any leaks repaired on site?</ToggleLabel>
        <ToggleSwitch
          type={isOnSite}
          setType={setOnSite}
          text1="No"
          text2="Yes"
          disabled={inputsHaveValue}
        />
        {isOnSite && (
          <section>
            {leakRepair.map((form, index) => (
              <RepairRow key={form.id || form.localId}>
                {deleteConfirm === (form.id || form.localId) ? (
                  <ConfirmDiv>
                    <ConfirmDeleteButton
                      ref={confirmRef}
                      onClick={() => removeRepair(form.id ?? form.localId)}
                    >
                      Delete
                    </ConfirmDeleteButton>
                  </ConfirmDiv>
                ) : (
                  <RemoveButton onClick={() => handleRemoveConfirm(form.id ?? form.localId)}>
                    <StyledRemoveIcon />
                  </RemoveButton>
                )}
                <RepairForm
                  key={form.id || form.localId}
                  files={form.files || []}
                  formIndex={index}
                />
              </RepairRow>
            ))}
            <AddRepair type="button" onClick={addRepair}>
              <IoAddOutline size={16} />
              {' '}
              Add new repair
            </AddRepair>
            <Spacer />
          </section>
        )}
      </LeakWrapper>
      <FooterNav>
        <Back type="submit" onClick={handleLeakSubNavBack}>
          Back
        </Back>
        <Next type="submit" onClick={handleNext}>
          Next
        </Next>
      </FooterNav>
    </>
  );
};

export default Leaks;
