import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { IoAddOutline, IoRemoveCircle } from 'react-icons/io5';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';

import FooterNav from '../../components/FooterNav';
import Header from '../../components/Header';
import Next from '../../components/Next';
import Back from '../../components/Back';
import SubHeader from '../../components/SubHeader';
import Spacer from '../../components/Spacer';
import FileDropzone from '../../components/FileDropzone';
import useModifyInspectionsContext from '../../../../hooks/useModifyInspectionContext';
import { global } from '../../../../shared/colors';
import customStyles from '../../components/customStyles/customStyles';
import DropdownIndicator from '../../../../components/Select/DropdownIndicator';
import ClearIndicator from '../../../../components/Select/ClearIndicator';

const credentialsStyles = {
  ...customStyles,
  container: (provided) => ({
    ...customStyles.container(provided),
    margin: '8px 0'
  })
};

const flexCenter = `
    display: flex;
    justify-content: center;
    align-items: center;
`;

const clickable = `
    cursor: pointer;
    &:hover {
        opacity: 65%;
    }
`;

const noBackgroundNoBorder = `
    background: none;
    border: none;
`;

const baseButtonStyles = `
    ${noBackgroundNoBorder}
    ${clickable}
    font-weight: 600;
    margin-left: -12px;
`;

const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  background: ${global.White};
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
`;

const LabelBase = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${global.Gray3};
  margin-bottom: 8px;
`;

const ConfirmDeleteButton = styled.button`
  ${baseButtonStyles}
  width: 75px;
  height: 43px;
  margin: -20px 10px 0 -85px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 19.36px;
  border: 1px solid ${global.ErrorRed};
  color: ${global.ErrorRed};

  &:hover {
    background-color: ${global.ErrorRed};
    opacity: 1;
    color: ${global.White};
  }
`;

const ConfirmDiv = styled.div`
  ${flexCenter};
  justify-content: center;
  height: 100%;
`;

const RemoveButton = styled.button`
  ${baseButtonStyles}
  height: 40px;
`;

const StyledRemoveIcon = styled(IoRemoveCircle)`
  color: ${global.ErrorRed};
  transition: opacity 0.3s ease;
  font-size: 24px;
  margin: -3px 0 16px -66px;

  &:hover {
    opacity: 0.65;
  }
`;

const ComponentRow = styled.div`
  ${flexCenter}
  width: 100%;
  justify-content: flex-start;
`;

const AddDailyVerification = styled.button`
  ${baseButtonStyles}
  font-size: 16px;
  color: ${global.PrimaryBlue};
  text-align: left;
  margin-bottom: 32px;

  svg {
    margin-bottom: 4px;
    vertical-align: middle;
  }

  ${(props) => props.disabled
    && ` 
            color: ${global.Gray5};
            cursor: not-allowed;
            &:hover {
                opacity: 1;
            }
        `}
`;

const Container = styled.div`
  width: 60%;
  height: 90%;
  margin-bottom: 60px;
`;

const VerificationDiv = styled(Box)`
  margin-bottom: 16px;

  section {
    margin: 20px 26px 32px 26px;
  }
`;

const FacilityDiv = styled(Box)`
  section {
    margin: 20px 26px 32px 26px;
  }
`;

const Label = styled(LabelBase)`
  margin: 20px 0 0 0;
`;

const TextLabel = styled(LabelBase)`
  margin-top: 20px;
`;

const StyledInput = styled(Input)`
  height: 51px;
  width: 100%;
  min-width: 200px;
  font-size: 16px;
  padding: 16px;
  cursor: pointer;
  border-radius: 10px;

  /* Hide number input spinners */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & {
    -moz-appearance: textfield;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  ${(props) => props.id === 'Meters'
    && `
        margin-right: -18px;
        z-index: 1;
    `}

  ${(props) => props.id === 'Grams per hour'
    && `
        margin-right: -18px;
        z-index: 1;
    `}

    &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: grey;
    content: ${(props) => `'${props?.id}'`};
    right: 16px;
    bottom: 10px;
    color: ${global.Gray5};
  }
`;

const ConfirmDelete = ({ confirm, confirmRef }) => (
  <ConfirmDiv>
    <ConfirmDeleteButton id="confirmVerificationDelete" ref={confirmRef} onClick={confirm}>
      Delete
    </ConfirmDeleteButton>
  </ConfirmDiv>
);

const Verification = () => {
  const {
    setActiveSubTab,
    facilitySign,
    setFacilitySign,
    verifications,
    setVerifications,
    instrumentsOptions,
    fields
  } = useModifyInspectionsContext();
  const [inputAlign, setInputAlign] = useState('right');
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const confirmRef = useRef(null);

  const handleInputFocus = () => {
    setInputAlign('left');
  };

  const handleInputBlur = (e) => {
    if (e.target.value === '') setInputAlign('right');
  };

  const handleVerificationInputChange = (e, fieldName, type, formIndex) => {
    const value = type === 'number' ? parseFloat(e.target.value) : e.target.value;

    setVerifications((prevVerifications) => {
      const updatedVerifications = [...prevVerifications];

      if (updatedVerifications[formIndex]) {
        updatedVerifications[formIndex] = {
          ...updatedVerifications[formIndex],
          [fieldName]: value
        };
      }

      return updatedVerifications;
    });
  };

  const getSignForDropzone = (id) => facilitySign?.find((file) => file.id === id);

  const handleBack = () => setActiveSubTab('info');
  const handleNext = () => setActiveSubTab('weather-conditions');

  const addFacilitySign = (file) => {
    setFacilitySign((prev) => {
      const filtered = prev.filter((f) => f.id !== file.id);
      return [...filtered, file];
    });
  };

  const removeFacilitySign = () => {
    setFacilitySign([]);
  };

  const addRow = () => {
    const newVerification = {
      localId: uuidv4(),
      inspectionId: fields?.id,
      surveyInstrument: fields?.verifications?.surveyInstrument || null,
      surveyInstrumentId: fields?.verifications?.surveyInstrument?.id || null
    };
    setVerifications([...verifications, { ...newVerification }]);
  };

  const removeRow = (idToRemove) => {
    setVerifications(
      verifications.filter(
        (verification) => (verification.id ?? verification.localId) !== idToRemove
      )
    );
  };

  const handleRemoveConfirm = (verificationId) => {
    setDeleteConfirm(verificationId);
  };

  const handleVerification = (value, file, formIndex) => {
    if (formIndex < 0 || formIndex >= verifications.length) {
      console.warn(`Invalid index ${formIndex}`);
      return;
    }
    setVerifications((prevVerifications) => {
      const updatedVerifications = [...prevVerifications];
      updatedVerifications[formIndex][file] = value;
      return updatedVerifications;
    });
  };

  const getByVerification = (file, formIndex) => {
    if (formIndex < 0 || formIndex >= verifications.length) {
      console.warn(`Invalid index ${formIndex}`);
      return null;
    }
    return verifications[formIndex]?.[file] || null;
  };

  const removeVerification = (i, file) => {
    setVerifications((prevVerifications) => {
      const updatedVerifications = [...prevVerifications];

      if (i >= 0 && i < updatedVerifications.length) {
        updatedVerifications[i] = { ...updatedVerifications[i] };
        delete updatedVerifications[i][file];
      } else {
        console.error(`No form found at index ${i}`);
      }

      return updatedVerifications;
    });
  };

  const handleSurveyInstrumentChange = (selectedOption, formIndex) => {
    setVerifications((prevVerifications) => {
      const updatedVerifications = [...prevVerifications];
      updatedVerifications[formIndex] = {
        ...updatedVerifications[formIndex],
        surveyInstrument: selectedOption?.item,
        surveyInstrumentId: selectedOption?.item?.id
      };

      return updatedVerifications;
    });
  };

  useEffect(() => {
    if (verifications.length === 0) {
      const initialVerifications = {
        localId: uuidv4()
      };
      setVerifications([initialVerifications]);
    }
  }, [verifications]);

  const handleCancelRemove = () => {
    setDeleteConfirm(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && deleteConfirm !== null) {
        setDeleteConfirm(null);
        handleCancelRemove();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (confirmRef.current && !confirmRef.current.contains(event.target)) {
        setDeleteConfirm(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Container>
        <Header>Inspection details</Header>
        <SubHeader>Part 2 of 4: Verification</SubHeader>

        {verifications.map((verification, index) => (
          <ComponentRow key={verification.id ?? verification.localId}>
            {deleteConfirm === (verification.id ?? verification.localId) ? (
              <ConfirmDelete
                confirm={() => removeRow(verification.id ?? verification.localId)}
                confirmRef={confirmRef}
              />
            ) : (
              index > 0 && (
                <RemoveButton
                  id="removeVerification"
                  onClick={() => handleRemoveConfirm(verification.id ?? verification.localId)}
                >
                  <StyledRemoveIcon />
                </RemoveButton>
              )
            )}
            <VerificationDiv>
              <section>
                <Label>Daily verification</Label>
                <FileDropzone
                  id={`verificationImage-${index}`}
                  label="Daily verification"
                  height="329px"
                  subLabel="Files should be .jpg, .png, or .heic"
                  onFileAdded={(value) => handleVerification(value, 'verificationImage', index)}
                  onFileRemoved={() => removeVerification(index, 'verificationImage')}
                  value={getByVerification('verificationImage', index)}
                />
                <Label>Instrument</Label>
                <Select
                  id="surveyInstrument"
                  autosize
                  styles={credentialsStyles}
                  className="detail-select"
                  classNamePrefix="detail-select"
                  options={instrumentsOptions || []}
                  placeholder="Select instrument"
                  isClearable
                  value={
                    instrumentsOptions?.find(
                      (option) => option?.item?.name === verification?.surveyInstrument?.name
                    ) || null
                  }
                  onChange={(selectedOption) => handleSurveyInstrumentChange(selectedOption, index)}
                  clearValue={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator,
                    DropdownIndicator
                  }}
                />
                <TextLabel>Altitude</TextLabel>
                <InputWrapper id="Meters">
                  <StyledInput
                    id="meters"
                    placeholder="0"
                    type="number"
                    inputalign={inputAlign}
                    value={verifications[index]?.altitude || ''}
                    onChange={(e) => handleVerificationInputChange(e, 'altitude', 'number', index)}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                  />
                </InputWrapper>
                <TextLabel>Flow rate</TextLabel>
                <InputWrapper id="Grams per hour">
                  <StyledInput
                    id="pressure"
                    placeholder="0"
                    type="number"
                    inputalign={inputAlign}
                    value={verifications[index]?.flowRate || ''}
                    onChange={(e) => handleVerificationInputChange(e, 'flowRate', 'number', index)}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                  />
                </InputWrapper>
              </section>
            </VerificationDiv>
          </ComponentRow>
        ))}

        <AddDailyVerification type="button" onClick={addRow} disabled={verifications.length >= 2}>
          <IoAddOutline size={16} />
          {' '}
          Add new verification
        </AddDailyVerification>
        <ComponentRow>
          <FacilityDiv>
            <section>
              <Label>Facility sign</Label>
              <FileDropzone
                id="facility-sign"
                height="329px"
                subLabel="Files should be .jpg, .png, or .heic"
                onFileAdded={addFacilitySign}
                onFileRemoved={removeFacilitySign}
                value={getSignForDropzone('facility-sign')}
              />
            </section>
          </FacilityDiv>
        </ComponentRow>
        <Spacer />
      </Container>
      <FooterNav>
        <Back type="submit" onClick={handleBack}>
          Back
        </Back>
        <Next type="submit" onClick={handleNext}>
          Next
        </Next>
      </FooterNav>
    </>
  );
};

export default Verification;
