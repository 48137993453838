import { useContext } from 'react';
import { ModifyInspectionsContext } from '../context/ModifyInspectionsContext';

const useModifyInspectionsContext = () => {
  const context = useContext(ModifyInspectionsContext);
  if (!context) {
    throw new Error(
      'useModifyInspectionsContext must be used within a ModifyInspectionsProvider'
    );
  }
  return context;
};

export default useModifyInspectionsContext;
