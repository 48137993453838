import {
  DOCUMENT_FILE_TYPE,
  VIDEO_FILE_TYPE,
  IMAGE_FILE_TYPE
} from '../shared/constants';
import { API_URL } from '../shared/url';
import getS3UploadData from './getS3UploadData';
import writeUploadDataToDB from './writeUploadDataToDB';

const getRequestEndpointFromFile = (
  { orgId, siteId, inspectionId },
  uploadUrl = ''
) => `${API_URL}/files/orgs/${orgId}/sites/${siteId}?inspectionId=${inspectionId}${uploadUrl}`;

// eslint-disable-next-line max-len
const getFormDataFromFields = (formFields) => Object.entries(formFields).reduce((formData, [key, value]) => {
  formData.append(key, value);
  return formData;
}, new FormData());

const uploadFileToS3 = async ({ fields, url, uploadedFile }) => new Promise((resolve, reject) => {
  const formData = getFormDataFromFields(fields);
  formData.append('file', uploadedFile.file);
  const xhr = new XMLHttpRequest();
  xhr.open('POST', url, true);
  xhr.send(formData);

  const type = (uploadedFile.file.type.match('video.*') && VIDEO_FILE_TYPE)
            || (uploadedFile.file.type.match('image.*') && IMAGE_FILE_TYPE)
            || DOCUMENT_FILE_TYPE
            || null;

  xhr.onload = () => {
    if (xhr.status === 204) {
      const xhrResult = {
        fileTypeId: type,
        fileUrl: `${xhr.responseURL}${fields.key}`,
        s3Key: fields.key,
        name: uploadedFile.fileName,
        displayName: uploadedFile.displayName,
        sensorTypeId: uploadedFile.sensorType
      };

      return resolve({ ok: true, xhrResult });
    }

    const error = new Error(`Request failed with status ${xhr.status}`);
    error.status = xhr.status;
    error.statusText = xhr.statusText;
    error.url = xhr.responseURL;
    return reject(error);
  };
});

const uploadFileListToS3 = async ({
  uploadedFiles,
  onFilesPending,
  onFileUploadFailed,
  onFileUploadSuccess,
  token
}) => {
  onFilesPending(uploadedFiles);

  const fileResults = await Promise.all(
    uploadedFiles.map(async (file) => {
      try {
        const s3UploadData = await getS3UploadData(
          file,
          getRequestEndpointFromFile,
          token
        );

        if (!s3UploadData.ok) {
          throw s3UploadData;
        }

        const { fields, url } = s3UploadData.result;

        const uploadResponse = await uploadFileToS3({
          url,
          fields,
          uploadedFile: file
        });

        if (!uploadResponse.ok) {
          throw uploadResponse;
        }

        const writeResponse = await writeUploadDataToDB({
          uploadData: uploadResponse.xhrResult,
          file,
          getRequestEndpointFromFile,
          token
        });

        if (writeResponse.ok === false) {
          throw writeResponse;
        }

        onFileUploadSuccess({ pendingFile: file });

        return writeResponse;
      } catch (error) {
        const { url, status, statusText } = error;

        const failedUploadResult = {
          ok: false,
          error: (url
                        && status
                        && statusText && {
            statusText: `${status}: ${statusText} ${url}`
          }) || { statusText: error.toString() }
        };

        onFileUploadFailed({ pendingFile: file, failedUploadResult });

        return failedUploadResult;
      }
    })
  );

  return fileResults;
};

export default uploadFileListToS3;
