import styled from 'styled-components';
import { Input } from 'antd';
import { global } from '../../../../shared/colors';

export const flexCenter = `
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const clickable = `
    cursor: pointer;
    &:hover {
        opacity: 65%;
    }
`;

export const noBackgroundNoBorder = `
    background: none;
    border: none;
`;

export const Container = styled.section`
    width: 60%;
`;

export const BaseLabel = styled.p`
    align-items: center;
    justify-content: left;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 8px 0;
`;

export const Label = styled(BaseLabel)`
    color: ${global.Gray3};
`;

export const SectionLabel = styled(BaseLabel)`
    color: ${global.Gray3};
    margin-top: 32px;
`;

export const StatusText = styled.p`
    color: ${global.Gray5};
    padding-left: 12px;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 9px;
`;

export const StyledInput = styled(Input)`
    height: 51px;
    width: 100%;
    min-width: 200px;
    margin-bottom: 20px;
    font-size: 16px;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 10px;

    /* Hide number input spinners */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield;
    }
`;
