const getS3UploadData = async (
  uploadedFile,
  getRequestEndpointFromFile,
  token
) => {
  const { sensorType: sensorTypeId, fileName: name } = uploadedFile;
  const url = getRequestEndpointFromFile(uploadedFile, '&uploadUrl=true');

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        sensorTypeId,
        name
      })
    });

    if (response.ok) {
      const result = await response.json();
      return {
        ok: true,
        result
      };
    }

    throw response;
  } catch (error) {
    return error;
  }
};

export default getS3UploadData;
