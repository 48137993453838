import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { global } from '../../../../shared/colors';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isProcessing: boolean;
  modalText: JSX.Element | string;
  modalHeader: JSX.Element | string;
  confirmButtonColor?: string;
  cancelButtonColor?: string;
  cancelTextColor?: string;
  confirmButtonText: string;
  cancelButtonText?: string;
  textWidth?: string;
  textColor?: string;
}

interface ModalTextProps {
  textWidth?: string;
  textColor?: string;
}

interface CancelProps {
  backgroundColor?: string;
  cancelColor?: string;
}

interface ConfirmProps {
  backgroundColor?: string;
}

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContainer = styled.div.attrs({
  tabIndex: -1,
  role: 'dialog',
  'aria-modal': true,
  'aria-labelledby': 'modalHeader'
})`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    outline: none;
    width: 518px;
    height: 271px;
`;

const ModalHeader = styled.h2.attrs({
  id: 'modalHeader'
})`
    margin: 0;
    font-size: 32px;
    width: 372px;
    text-align: center;
`;

const ModalText = styled.p<ModalTextProps>`
    font-size: 16px;
    font-weight: 400;
    width: ${(props) => props.textWidth || '332px'};
    text-align: center;
    color: ${(props) => props.textColor || global.Black};

    span {
        font-weight: 600;
    }
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
`;

const buttonStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    font-weight: 600;
    height: 43px;
    width: 173px;
    &:hover {
        opacity: 60%;
    }
    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px -webkit-focus-ring-color;
    }
`;

const Cancel = styled.button<CancelProps>`
    ${buttonStyles}
    background-color: ${(props) => props.backgroundColor || global.White};
    color: ${(props) => props.cancelColor || global.Gray2};
    border: 1px solid ${(props) => props.cancelColor || global.Gray3}};
    margin: 0 12px 0 0;
`;

const Confirm = styled.button<ConfirmProps>`
    ${buttonStyles}
    background-color: ${(props) => props.backgroundColor || global.ErrorRed};
    color: white;
`;

const Spinner = styled.div`
    border: 4px solid ${global.Gray5};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isProcessing,
  modalHeader,
  modalText,
  confirmButtonText,
  confirmButtonColor,
  cancelButtonColor,
  cancelTextColor,
  cancelButtonText,
  textWidth,
  textColor
}) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isOpen && closeButtonRef.current !== null) {
      closeButtonRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalText textWidth={textWidth} textColor={textColor}>
          {modalText}
        </ModalText>
        <ModalFooter>
          <Cancel
            ref={closeButtonRef}
            onClick={onClose}
            backgroundColor={cancelButtonColor}
            cancelColor={cancelTextColor}
          >
            {cancelButtonText || 'No, don\'t delete'}
          </Cancel>
          <Confirm
            onClick={onConfirm}
            backgroundColor={confirmButtonColor}
          >
            {isProcessing ? (
              <Spinner />
            ) : (
              confirmButtonText || 'Yes, delete'
            )}
          </Confirm>
        </ModalFooter>
      </ModalContainer>
    </ModalBackground>
  );
};

ConfirmationModal.defaultProps = {
  confirmButtonColor: global.ErrorRed,
  cancelButtonColor: global.White,
  cancelTextColor: global.Gray2,
  cancelButtonText: 'No, don\'t delete',
  textWidth: '332px',
  textColor: global.Black
};

export default ConfirmationModal;
