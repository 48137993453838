const sliceTruncation = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  const underscoreIndex = fileName.lastIndexOf('_');
  let truncatedName = fileName;

  if (underscoreIndex > lastDotIndex && underscoreIndex !== -1) {
    truncatedName = fileName.slice(0, underscoreIndex);
  } else if (lastDotIndex !== -1) {
    truncatedName = fileName.slice(0, lastDotIndex);
  }

  if (truncatedName.length > 9) {
    truncatedName = `${truncatedName.substring(
      0,
      4
    )}...${truncatedName.substring(truncatedName.length - 4)}`;
  }

  return truncatedName;
};

export default sliceTruncation;
