import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Spin } from 'antd';

import { global } from '../../shared/colors';
import { StyledUploadSpin } from '../../shared/styled-components';
import useAsyncClick from '../../hooks/useAsyncClick';

const antIcon = <StyledUploadSpin spin />;

const StyledSpin = styled(Spin)`
    margin-right: 5px;
    color: ${global.White};
`;

const StyledButton = styled(Button)`
    ${(props) => props.size === 'small'
        && `
      height: 32px;
    `}

    ${(props) => props.size === 'med'
        && `
      height: 38px;
    `}

    ${(props) => props.size === 'large'
        && `
      height: 40px;
    `}

    ${(props) => props.variant === 'primary'
        && `
      background-color: ${global.PrimaryBlue};
      color: ${global.White};
      &:hover {
        opacity: 0.8;
        color: ${global.White};
      }
      &:focus {
        background-color: ${global.PrimaryBlue}};
        color: ${global.White};
      }
      &:active {
        background-color: ${global.PrimaryBlue} !important;
      }
    `}

    ${(props) => props.variant === 'outline'
        && `
      background-color: ${global.White};
      color: ${global.Gray3} !important;
      &:hover {
        background-color: ${global.White} ;
        color: ${global.PrimaryBlue};
      }
      &:focus {
        background-color: ${global.White};
        color: ${global.Gray2};
      }
      &:active {
        color: ${global.PrimaryBlue} !important;
      }
    `}

    min-width: 98px;
    font-size: 14px !important;
    border-width: 1px;
    border-radius: 5px !important;
    border-color: ${global.PrimaryBlue} !important;
    font-family: Inter, sans-serif, Arial, Helvetica !important;

    &:hover {
        opacity: 0.8;
    }

    &:focus {
        opacity: 0.8;
    }

    &:disabled {
        background-color: ${global.Gray5} !important;
        border-color: ${global.Gray5} !important;
        color: ${global.Gray3};
    }
`;

const CustomAntdButton = ({
  size = 'med',
  variant = 'primary',
  isDisabled = false,
  handleClick,
  asyncClick,
  nospin = false,
  spinnerProps,
  children
}) => {
  const { waiting, onClickHandler } = useAsyncClick(asyncClick, handleClick);

  const spin = waiting && !nospin && (
    <StyledSpin {...spinnerProps} indicator={antIcon} />
  );

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClickHandler();
    }
  };

  return (
    <StyledButton
      type="button"
      role="button"
      aria-label={children}
      icon={spin}
      size={size}
      variant={variant}
      disabled={isDisabled}
      waiting={waiting.toString()}
      onClick={onClickHandler}
      onKeyPress={handleKeyPress}
      block
    >
      {nospin || !waiting ? children : null}
    </StyledButton>
  );
};

CustomAntdButton.propTypes = {
  size: PropTypes.oneOf(['small', 'med', 'large']),
  variant: PropTypes.oneOf(['primary', 'outline']),
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func,
  asyncClick: PropTypes.func,
  nospin: PropTypes.bool,
  spinnerProps: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

CustomAntdButton.defaultProps = {
  size: 'med',
  variant: 'primary',
  isDisabled: false,
  handleClick: () => null,
  asyncClick: () => null,
  nospin: false,
  spinnerProps: {}
};

export default CustomAntdButton;
