import styled from 'styled-components';

import { global } from '../../../shared/colors';

const Border = styled.section`
    margin: 20px;
    height: 88vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    background: ${global.White};
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
    max-width: 1000px;

    .progress-bar-container {
        width: 100%;
        margin: -8px 0 0 0;
        z-index: 10;

        p {
            margin: 0 0 0 17px;
            padding: 0;
            color: ${global.Gray4};
            font-weight: 600;
            font-size: 12px;
            width: fit-content;
        }
        
        .completion-percentage {
            margin: 0;
            padding: 0 17px;
            height: 2px !important;

            .ant-progress-outer {
                height: 0 !important;
            }
    
            .ant-progress-inner {
                height: 2px;
                background-color: transparent;

                .ant-progress-bg {
                    height: 2px !important;
                    background-color: ${global.Black};
                }
            }
        }
    }
`;

export default Border;
