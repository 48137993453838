import styled from 'styled-components';

import { global } from '../../../shared/colors';

const SubmitButton = styled.button`
    border-radius: 10px;
    border: none;
    height: 43px;
    background: ${global.PrimaryBlue};
    color: ${global.White};
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 0 40px;
    margin-top: 40px;

    &:hover {
        opacity: 65%;
    }

    &:disabled {
        background: ${global.Gray5};
        color: ${global.Gray4};
        cursor: not-allowed;
    }
`;

export default SubmitButton;
