import React, {
  useContext, useState, useEffect, useRef
} from 'react';
import styled from 'styled-components';
import { IoAddOutline, IoRemoveCircle } from 'react-icons/io5';

import { ModifyInspectionsContext } from '../../../../../context/ModifyInspectionsContext';
import FooterNav from '../../../components/FooterNav';
import Header from '../../../components/Header';
import Next from '../../../components/Next';
import Back from '../../../components/Back';
import Spacer from '../../../components/Spacer';
import SurveyForm from './SurveyForm';
import { global } from '../../../../../shared/colors';

const flexDisplay = `
    display: flex;
    align-items: center;
`;

const baseButtonStyles = `
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 600;

    &:hover {
        opacity: 0.65;
    }
`;

const SurveyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
`;

const SurveyRow = styled.div`
    ${flexDisplay}
    width: 100%;
    justify-content: flex-start;
    margin-left: -10px;
`;

const ConfirmDeleteButton = styled.button`
    ${baseButtonStyles}
    width: 75px;
    height: 43px;
    margin: -20px 10px 0 -73px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 19.36px;
    border: 1px solid ${global.ErrorRed};
    color: ${global.ErrorRed};

    &:hover {
        background-color: ${global.ErrorRed};
        opacity: 1;
        color: ${global.White};
    }
`;

const ConfirmDiv = styled.div`
    ${flexDisplay};
    justify-content: center;
    height: 100%;
`;

const RemoveButton = styled.button`
    ${baseButtonStyles}
    height: 40px;
`;

const StyledRemoveIcon = styled(IoRemoveCircle)`
    color: ${global.ErrorRed};
    transition: opacity 0.3s ease;
    font-size: 24px;
    margin: -3px 0 16px -66px;

    &:hover {
        opacity: 0.65;
    }
`;

const AddSurvey = styled.button`
    ${baseButtonStyles}
    font-size: 16px;
    color: ${global.PrimaryBlue};
    text-align: left;

    svg {
        margin-bottom: 4px;
        vertical-align: middle;
    }
`;

const StyledSection = styled.section`
    ${flexDisplay};
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
`;

const SubHeader = styled.p`
    color: #bcbcbc;
    font-size: 20px;
    margin-top: 8px;
    margin-bottom: 62px;
`;

const Surveys = () => {
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const confirmRef = useRef(null);
  const {
    setActiveTab, setActiveSubTab, surveys, setSurveys, fields
  } = useContext(ModifyInspectionsContext);

  const handleBack = () => setActiveSubTab('weather-conditions');
  const handleNext = () => setActiveTab('leak-details');

  const addSurvey = () => {
    const newSurvey = {
      localId: new Date().getTime(),
      files: [],
      inspectionId: fields?.id
    };
    setSurveys([...surveys, { ...newSurvey }]);
  };

  const removeSurvey = (idToRemove) => {
    setSurveys(surveys.filter((survey) => (survey.id ?? survey.localId) !== idToRemove));
  };

  const handleRemoveConfirm = (surveyId) => {
    setDeleteConfirm(surveyId);
  };

  const handleCancelRemove = () => {
    setDeleteConfirm(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && deleteConfirm !== null) {
        setDeleteConfirm(null);
        handleCancelRemove();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [deleteConfirm]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        confirmRef.current
                && !confirmRef.current.contains(event.target)
      ) {
        setDeleteConfirm(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [confirmRef]);

  useEffect(() => {
    if (surveys.length === 0) addSurvey();
  }, []);

  return (
    <>
      <SurveyWrapper>
        <Header>Inspection details</Header>
        <StyledSection>
          <SubHeader>Part 4 of 4: Surveys</SubHeader>
          <AddSurvey type="button" onClick={addSurvey}>
            <IoAddOutline size={16} />
            {' '}
            Add new survey
          </AddSurvey>
        </StyledSection>
        {surveys.map((survey, index) => (
          <SurveyRow key={survey.id ?? survey.localId}>
            {deleteConfirm === (survey.id ?? survey.localId) ? (
              <ConfirmDiv>
                <ConfirmDeleteButton
                  ref={confirmRef}
                  onClick={() => removeSurvey(survey.id ?? survey.localId)}
                >
                  Delete
                </ConfirmDeleteButton>
              </ConfirmDiv>
            ) : (
              <RemoveButton
                onClick={() => handleRemoveConfirm(survey.id ?? survey.localId)}
              >
                <StyledRemoveIcon />
              </RemoveButton>
            )}
            <SurveyForm
              key={survey.id ?? survey.localId}
              files={survey.files || []}
              formIndex={index}
              surveys={surveys}
              setSurveys={setSurveys}
            />
          </SurveyRow>
        ))}
        {surveys.length > 0 && (
          <>
            <AddSurvey type="button" onClick={addSurvey}>
              <IoAddOutline size={16} />
              {' '}
              Add new survey
            </AddSurvey>
            <Spacer />
          </>
        )}
      </SurveyWrapper>
      <FooterNav>
        <Back type="submit" onClick={handleBack}>
          Back
        </Back>
        <Next type="submit" onClick={handleNext}>
          Next
        </Next>
      </FooterNav>
    </>
  );
};

export default Surveys;
