import React, { useContext } from 'react';
import { TimePicker } from 'antd';
import { FiClock } from 'react-icons/fi';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { IoCloseOutline } from 'react-icons/io5';
import styled from 'styled-components';
import moment from 'moment';

import './rangePicker.css';
import { global } from '../../../../shared/colors';
import { ModifyInspectionsContext } from '../../../../context/ModifyInspectionsContext';

const { RangePicker } = TimePicker;

const StyledRangePicker = styled(RangePicker)`
    padding: 16px;

    svg {
        color: ${global.Gray5};
    }

    .ant-picker-range-separator {
        margin-right: 20px;
    }
`;

const RangerPicker = () => {
  const {
    startTime, endTime, setStartTime, setEndTime
  } = useContext(
    ModifyInspectionsContext
  );

  const formattedStartTimeValue = startTime
    ? moment(startTime, 'HH:mm:ss')
    : null;
  const formattedEndTimeValue = endTime ? moment(endTime, 'HH:mm:ss') : null;

  return (
    <StyledRangePicker
      use12Hours
      format="h:mm a"
      value={[formattedStartTimeValue, formattedEndTimeValue].filter(
        Boolean
      )}
      onChange={(timeRange) => {
        if (timeRange) {
          const formattedStartTime = timeRange[0].format('HH:mm:ss');
          const formattedEndTime = timeRange[1].format('HH:mm:ss');
          setStartTime(formattedStartTime);
          setEndTime(formattedEndTime);
        } else {
          setStartTime(null);
          setEndTime(null);
        }
      }}
      allowClear={{
        clearIcon: <IoCloseOutline size={24} />
      }}
      suffixIcon={<FiClock size={24} />}
      separator={<HiArrowNarrowRight size={24} />}
    />
  );
};

export default RangerPicker;
