import React from 'react';

import './ClearIndicator.css';

const ClearIndicator = (props) => {
  const {
    innerProps: { ref, ...restInnerProps }
  } = props;

  return (
    <button
      className="clear-select-btn"
      type="button"
      {...restInnerProps}
      ref={ref}
    >
      RESET
    </button>
  );
};

export default ClearIndicator;
