import React from 'react';
import styled from 'styled-components';
import { RiAlertLine } from 'react-icons/ri';
import { Modal } from 'antd';

import Button from '../Button';
import { global } from '../../shared/colors';
import { ButtonContainer } from '../../shared/styled-components';

const StyledDeleteFileModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-content {
        box-shadow: none;
        background-color: ${global.White};
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        display: flex;
        justify-content: center;
    }
`;

const StyledDeleteDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 272px;
    width: 432px;
    background: ${global.White};
    border-radius: 2px;
`;

const AlertMessage = styled.div`
    text-align: center;
`;

const StyledRiAlertLine = styled(RiAlertLine)`
    color: ${global.ErrorRed};
    font-size: 24px;
`;

const AlertText = styled.p`
    color: ${global.textGrey};
    margin-bottom: 40px;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
`;

const FileDeleteModal = ({
  isVisible, file, onCancel, onOk
}) => (
  <StyledDeleteFileModal
    visible={isVisible}
    footer={null}
    closable={false}
    centered
    aria-labelledby="delete-file-modal-title"
  >
    <StyledDeleteDiv>
      <AlertMessage>
        <StyledRiAlertLine />
        <AlertText id="delete-file-modal-title">
          Are you sure you would like to delete selected files?
        </AlertText>
      </AlertMessage>
      <ButtonRow>
        <ButtonContainer>
          <Button
            variant="outline"
            size="large"
            nospin="true"
            handleClick={onCancel}
          >
            Cancel
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button
            size="large"
            asyncClick
            handleClick={(e, callback) => {
              onOk(file.id, callback);
            }}
          >
            Delete
          </Button>
        </ButtonContainer>
      </ButtonRow>
    </StyledDeleteDiv>
  </StyledDeleteFileModal>
);

export default FileDeleteModal;
