import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IoRemoveCircle } from 'react-icons/io5';

import LeaksForm from './LeaksForm';
import { global } from '../../../../shared/colors';

const flexDisplay = `
    display: flex;
    align-items: center;
`;

const baseButtonStyles = `
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 600;

    &:hover {
        opacity: 0.65;
    }
`;

const LeakRow = styled.div`
    ${flexDisplay}
    width: 100%;
    justify-content: flex-start;
    margin-left: -10px;
`;

const ConfirmDeleteButton = styled.button`
    ${baseButtonStyles}
    width: 75px;
    height: 43px;
    margin: -20px 10px 0 -73px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 19.36px;
    border: 1px solid ${global.ErrorRed};
    color: ${global.ErrorRed};

    &:hover {
        background-color: ${global.ErrorRed};
        opacity: 1;
        color: ${global.White};
    }
`;

const ConfirmDiv = styled.div`
    ${flexDisplay};
    justify-content: center;
    height: 100%;
`;

const RemoveButton = styled.button`
    ${baseButtonStyles}
    height: 40px;
`;

const StyledRemoveIcon = styled(IoRemoveCircle)`
    color: ${global.ErrorRed};
    transition: opacity 0.3s ease;
    margin: -8px 0 16px -66px;
    font-size: 24px;
    flex-shrink: 0;

    &:hover {
        opacity: 0.65;
    }
`;

const LeakManagement = ({ leaks, setLeaks, setIsLeaks }) => {
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const confirmRef = useRef(null);

  const removeLeak = (idToRemove) => {
    setLeaks((prevLeaks) => {
      const remainingLeaks = prevLeaks.filter(
        (form) => (form.id || form.localId) !== idToRemove
      );
      if (remainingLeaks.length === 0) {
        setIsLeaks('No');
      }
      return remainingLeaks;
    });
  };

  const handleRemoveConfirm = (leakToDelete) => {
    setDeleteConfirm(leakToDelete);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && deleteConfirm !== null) {
        setDeleteConfirm(null);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        confirmRef.current
                && !confirmRef.current.contains(event.target)
      ) {
        setDeleteConfirm(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {leaks.map((leak, index) => (
        <LeakRow key={leak.id}>
          {deleteConfirm === (leak.id ?? leak.localId) ? (
            <ConfirmDiv>
              <ConfirmDeleteButton
                ref={confirmRef}
                onClick={() => removeLeak(leak.id ?? leak.localId)}
              >
                Delete
              </ConfirmDeleteButton>
            </ConfirmDiv>
          ) : (
            <RemoveButton
              onClick={() => handleRemoveConfirm(leak.id ?? leak.localId)}
            >
              <StyledRemoveIcon />
            </RemoveButton>
          )}
          <LeaksForm
            key={leak.id}
            files={leak.files || []}
            formIndex={index}
            leaks={leaks}
            setLeaks={setLeaks}
          />
        </LeakRow>
      ))}
    </>
  );
};

export default LeakManagement;
