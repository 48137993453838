import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import {
  ButtonContainer,
  TextWithIcon,
  StyledParagraph,
  StyledInspection,
  StyledDiv,
  StyledDataBooksModal
} from './styled-components';
import { ButtonContainer as ClickableContainer } from '../../shared/styled-components';

const DatabooksModal = ({
  onOk, isVisible, inspection, onCancel
}) => (
  <StyledDataBooksModal
    open={isVisible}
    footer={null}
    closable={false}
    centered
  >
    <StyledDiv>
      <TextWithIcon>
        <StyledParagraph>
          Would you like to receive an email with a download link for
          the files in the following inspection?
        </StyledParagraph>
        <StyledInspection>{inspection?.flogistixId}</StyledInspection>
      </TextWithIcon>
      <ButtonContainer>
        <ClickableContainer>
          <Button
            variant="outline"
            size="large"
            nospin="true"
            handleClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onCancel();
            }}
          >
            Cancel
          </Button>
        </ClickableContainer>
        <ClickableContainer>
          <Button
            size="large"
            handleClick={(e, callback) => {
              e.stopPropagation();
              e.preventDefault();
              onOk(inspection?.id, callback);
            }}
          >
            Send
          </Button>
        </ClickableContainer>
      </ButtonContainer>
    </StyledDiv>
  </StyledDataBooksModal>
);

DatabooksModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  inspection: PropTypes.shape({
    id: PropTypes.string,
    flogistixId: PropTypes.string
  }),
  onCancel: PropTypes.func.isRequired
};

DatabooksModal.defaultProps = {
  inspection: {}
};

export default DatabooksModal;
