const localSuffix = process.env.REACT_APP_IS_LOCAL === 'true' ? '/local' : '';

export const Auth_0 = 'http://axil.com/user';

export const IAM_URL = `${process.env.REACT_APP_IAM_URL}${localSuffix}`;

export const API_URL = process.env.REACT_APP_API_URL
    && `${process.env.REACT_APP_API_URL}${localSuffix}/airmethane`;

export const DATABOOKS_URL = process.env.REACT_APP_API_URL
    && `${process.env.REACT_APP_API_URL}${localSuffix}/databook/air-methane`;

export const audience = 'https://api.axil.ai';

export const API_INSPECTIONS_ENDPOINT = `${API_URL}/inspections`;
