import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled((props) => {
  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <div
      {...props}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    />
  );
})`
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export default IconWrapper;
