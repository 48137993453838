import React, { useContext, useEffect, useState } from 'react';
import { notification } from 'antd';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IoChevronForward } from 'react-icons/io5';

import { RiAddFill } from 'react-icons/ri';
import { addKeys, groupRecordsBy } from '../../shared/utils';
import { global } from '../../shared/colors';
import { GlobalContext } from '../../context/GlobalContext';
import { ModifyInspectionsContext } from '../../context/ModifyInspectionsContext';
import Table from '../../components/Table/Table';
import Status from '../../components/Status/Status';
import SkeletonTable from '../../components/Table/SkeletonTable';
import { COMPLETE_STATUS_ID, inspectionStatusMap } from '../../shared/constants';
import {
  Breadcrumbs,
  Breadcrumb,
  Header
} from '../../shared/styled-components';
import { InspectionBreadcrumb } from '../Inspections/styled-components';

const timeZone = 'UTC';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  background: ${global.PrimaryBlue};
  width: 205px;
  height: 41px;
  color: ${global.White};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    opacity: 60%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin: 0 0 5px 0;
`;

const StyledSection = styled.section`
  display: flex;
  margin-right: 41px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 52px;
  margin-left: 61px;
  margin-right: 29px;
  margin-bottom: 99px;
  height: 80vh;
`;

const StatusPill = styled.span`
  padding: 2px 8px;
  border-radius: 30px;
  background: ${(props) => props.backgroundColor || 'transparent'};
  color: ${(props) => props.color || global.Transparency};
`;

const NoCrumb = styled.div`
  height: 28px;
`;

const getStatusColors = (status) => {
  switch (status?.code) {
    case 'draft':
      return [global.DraftText, global.PrimaryBlue];
    case 'media_processing':
      return [global.ProcessingText, global.ProcessingBackground];
    case 'leak_processing':
      return [global.ProcessingText, global.ProcessingBackground];
    case 'ready_to_submit':
      return [global.ReadyText, global.ReadyBackground];
    default:
      return ['transparent', global.Transparency];
  }
};

const renderInspectionDate = (inspectionDate) => {
  const dateAsUTC = utcToZonedTime(new Date(inspectionDate), timeZone);
  return format(dateAsUTC, 'MMM dd, yyyy', { timeZone });
};

const customerViewTableColumns = [
  {
    title: 'Site',
    dataIndex: 'customerLocationName',
    key: 'customerLocationName',
    width: '30%',
    sortable: true
  },
  {
    title: 'Total Inspections',
    dataIndex: 'inspections',
    key: 'inspections',
    width: '20%',
    sortable: true
  },
  {
    title: 'Recent Inspection',
    dataIndex: 'inspectionDate',
    key: 'inspectionDate',
    width: '25%',
    sortable: true,
    render: renderInspectionDate
  }
];

const inspectionTableColumns = [
  {
    title: 'Site',
    dataIndex: 'customerLocationName',
    key: 'customerLocationName',
    width: '30%',
    sortable: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '20%',
    sortable: true,
    render: (status) => {
      const [backgroundColor, fontColor] = getStatusColors(status);
      return (
        <StatusPill backgroundColor={backgroundColor} color={fontColor}>
          {inspectionStatusMap[status?.code] ?? status?.code}
        </StatusPill>
      );
    }
  },
  {
    title: 'Inspection Date',
    dataIndex: 'inspectionDate',
    key: 'inspectionDate',
    width: '25%',
    sortable: true,
    render: (inspectionDate) => {
      const dateAsUTC = utcToZonedTime(new Date(inspectionDate), timeZone);
      return format(dateAsUTC, 'MMM dd, yyyy', { timeZone });
    }
  }
];

const inspectionTableWithCustomer = [
  {
    title: 'Customer Name',
    dataIndex: 'customerName',
    key: 'customerName',
    width: '25%',
    sortable: true
  },
  ...inspectionTableColumns
];

const completedInspectionColumns = [
  {
    title: 'Customer Name',
    dataIndex: 'customerName',
    key: 'customerName',
    width: '25%',
    sortable: true
  },
  {
    title: 'Site',
    dataIndex: 'customerLocationName',
    key: 'customerLocationName',
    width: '30%',
    sortable: true
  },
  {
    title: 'Total inspections',
    dataIndex: 'inspections',
    key: 'inspections',
    width: '20%',
    sortable: true
  },
  {
    title: 'Inspection Date',
    dataIndex: 'inspectionDate',
    key: 'inspectionDate',
    width: '25%',
    sortable: true,
    render: renderInspectionDate
  }
];

const Home = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    inspections,
    user_organizations,
    loading,
    getAndSetInspections,
    is_admin,
    triggerGlobalNotification,
    userIsInternal,
    token
  } = useContext(GlobalContext);
  const { clearFields, resetFormLocation } = useContext(
    ModifyInspectionsContext
  );
  const [netsuiteOrganization, setNetsuiteOrganization] = useState(null);
  const [locationData, setLocationData] = useState();

  const goToStart = () => {
    navigate('/');
  };

  const setLocationToMatchOrgParam = (locations_with_inspections) => {
    const customerId = parseInt(params.customerId, 10);
    const matchingLocations = locations_with_inspections.filter(
      (element) => element.customerId === customerId
    );

    setLocationData(matchingLocations);
  };

  const handleRows = (record) => {
    const location = locationData?.filter((l) => l.id === record.id)[0];
    if (location && location?.inspections > 0) {
      notification.destroy();
      navigate(
        `/orgs/${record.customerId}/inspections/${record.customerLocationId}`
      );
    } else {
      triggerGlobalNotification(
        'error',
        'This site does not have any inspections to view'
      );
    }
  };

  const tableProps = is_admin ? { enableTabs: true } : {};

  useEffect(() => {
    if (!loading) {
      const customerId = parseInt(params.customerId, 10);

      setNetsuiteOrganization(
        user_organizations.find((org) => org.netsuiteId === customerId)
      );
    }
  }, [params, inspections, loading, user_organizations]);

  useEffect(() => {
    if (!loading) {
      const location_counts = groupRecordsBy(inspections, [
        'customerLocationId'
      ]);

      const inspectionsWithValidDate = inspections?.map((location) => ({
        ...location,
        sortableDate: new Date(location.inspectionDate).getTime()
      }));

      const inspectionsFromUniqueSites = [];
      inspectionsWithValidDate?.forEach((insp) => {
        const index = inspectionsFromUniqueSites.findIndex(
          (uniqueLocation) => uniqueLocation.customerLocationId === insp.customerLocationId
        );
        if (index > -1) {
          if (inspectionsFromUniqueSites[index].sortableDate < insp.sortableDate) {
            inspectionsFromUniqueSites[index] = insp;
          }
        } else if (insp?.status?.id === COMPLETE_STATUS_ID || insp?.status?.id === null) {
          inspectionsFromUniqueSites.push(insp);
        }
      });

      inspectionsFromUniqueSites.sort((a, b) => b.sortableDate - a.sortableDate);

      const locations_with_inspections = inspectionsFromUniqueSites.map((inspection) => ({
        ...inspection,
        inspections: location_counts[inspection.customerLocationId] || 0,
        recent: format(new Date(inspection.sortableDate), 'MMMM dd, yyyy')
      }));

      const hasCustomer = Object.keys(params).includes('customerId');

      if (hasCustomer) {
        setLocationToMatchOrgParam(locations_with_inspections);
      } else {
        setLocationData(locations_with_inspections);
      }
    }
  }, [loading, inspections, user_organizations, params]);

  useEffect(() => {
    if (token) {
      getAndSetInspections();
    }
  }, []);

  if (loading) {
    return (
      <StyledDiv>
        <StyledSection>
          <Header>Inspections</Header>
        </StyledSection>
        <NoCrumb />
        <SkeletonTable isCustomer={!userIsInternal} />
      </StyledDiv>
    );
  }

  return (
    <StyledDiv>
      <StyledSection>
        <div>
          <Header>Inspections</Header>
          <Status />
        </div>
        {is_admin && (
          <ButtonWrapper>
            <StyledButton
              type="text"
              data-cy="CreateInspection"
              onClick={() => {
                clearFields();
                resetFormLocation();
                navigate('/inspections');
              }}
            >
              <RiAddFill size={16} style={{ marginRight: '4px' }} />
              Create new inspection
            </StyledButton>
          </ButtonWrapper>
        )}
      </StyledSection>
      {netsuiteOrganization ? (
        <Breadcrumbs>
          <InspectionBreadcrumb
            id="InspectionsBreadcrumbHome"
            onClick={goToStart}
            aria-label="Inspections"
          >
            Inspections
          </InspectionBreadcrumb>
          <IoChevronForward className="breadcrumb-chevron" />
          <Breadcrumb
            id="OrgBreadcrumbHomeInspections"
            aria-label="Inspection"
          >
            {netsuiteOrganization.orgName}
          </Breadcrumb>
        </Breadcrumbs>
      ) : (
        <NoCrumb />
      )}
      <Table
        data={addKeys(locationData) || ''}
        columns={
          !userIsInternal
            ? customerViewTableColumns
            : inspectionTableWithCustomer
        }
        completedColumns={completedInspectionColumns}
        onRow={handleRows}
        {...tableProps}
      />
    </StyledDiv>
  );
};

export default Home;
