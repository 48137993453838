import styled from 'styled-components';

const SubHeader = styled.p`
    color: #bcbcbc;
    font-size: 20px;
    margin-top: 8px;
    margin-bottom: 40px;
`;

export default SubHeader;
