import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import DropdownIndicator from '../../../../components/Select/DropdownIndicator';
import customStyles from '../../components/customStyles/customStyles';
import FooterNav from '../../components/FooterNav';
import Header from '../../components/Header';
import Next from '../../components/Next';
import Back from '../../components/Back';
import SubHeader from '../../components/SubHeader';
import ClearButton from '../../components/ClearButton';
import Spacer from '../../components/Spacer';
import ToggleSwitch from '../../components/ToggleSwitch';
import RangerPicker from '../../components/RangePicker/RangePicker';
import { global } from '../../../../shared/colors';
import useGetData from '../../../../hooks/useGetData';
import { GlobalContext } from '../../../../context/GlobalContext';
import { getInspectionFrequency } from '../../../../services/airmethaneApi';
import { handleDataLoading } from '../../../../shared/utils';
import { ModifyInspectionsContext } from '../../../../context/ModifyInspectionsContext';
import ClearIndicator from '../../../../components/Select/ClearIndicator';

const centeredFlex = `
    display: flex;
    align-items: center;
    justify-content: center;
`;

const sharedFontSettings = `
    font-size: 16px;
    font-weight: 600;
`;

const sharedMarginSettings = `
    margin-top: 1em;
    margin-bottom: 1em;
`;

const BaseLabel = styled.p`
    ${sharedFontSettings}
    color: ${global.Gray3};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 8px 0;
`;

const Container = styled.section`
    width: 60%;
    max-width: 563px;
    display: flex;
    flex-direction: column;
    align-items: start;
`;

const Label = styled(BaseLabel)`
    margin: 32px 0 8px 0;
`;

const Toggle = styled.div`
    ${centeredFlex}
    ${sharedMarginSettings}
    cursor: pointer;
    width: 100%;
    height: 3em;
    border-radius: 10px;
    position: relative;
    background: ${global.Gray5};
    transition: background 0.2s;
`;

const ToggleCircle = styled.div`
    ${centeredFlex}
    position: absolute;
    left: ${(props) => (props.toggled ? '50%' : '0')};
    width: 50%;
    height: 100%;
    background: white;
    border-radius: 10px;
    transition: left 0.2s;
    ${sharedFontSettings}
    box-shadow: 0.2em 0.2em 1em 0px #0000001a;
`;

const PlaceholderMargin = styled(BaseLabel)`
    margin: ${(props) => props.margin || '0'};
`;

const PlaceholderLeft = styled(PlaceholderMargin).attrs({
  margin: '0 0 0 10%'
})``;

const PlaceholderRight = styled(PlaceholderMargin).attrs({
  margin: '0 15% 0 0'
})``;

const PlaceholdersThree = styled.section`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const ToggleThree = styled(Toggle)`
    height: 51px;
    margin: 8px 0 0 0;
`;

const ToggleCircleThree = styled(ToggleCircle)`
    left: ${(props) => {
    if (props.state === 'Running') return '-2';
    if (props.state === 'Disabled') return '33.3%';
    return '66.8%';
  }};
    width: 33.3%;
`;

const Placeholder = styled(BaseLabel)`
    border-left: ${(props) => (props.leftBorder ? `1px solid ${global.White}` : 'none')};
    border-right: ${(props) => (props.rightBorder ? `1px solid ${global.White}` : 'none')};
    border-radius: 10px;
    width: 31%;
    height: 53px;
    text-align: center;
    margin: auto;
    ${centeredFlex}
`;

const TimeInputContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const StyledSelectDiv = styled.div`
    width: 100%;

    #frequency {
        margin: 0;
    }
`;

const Info = () => {
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const { token } = useContext(GlobalContext);
  const {
    data: inspectionFrequency,
    error: inspectionFrequencyError,
    isLoading: isInspectionFrequencyLoading
  } = useGetData(token, 'inspectionFrequency', getInspectionFrequency);
  const {
    setActiveTab,
    setActiveSubTab,
    complianceType,
    setComplianceType,
    operationalStatus,
    setOperationalStatus,
    setEndTime,
    setStartTime,
    setPart,
    setFields,
    fields
  } = useContext(ModifyInspectionsContext);

  const handleBack = () => setActiveTab('site-details');

  const handleNext = () => {
    setActiveSubTab('verification');
    setPart(2);
  };

  const handleToggleThree = (e) => {
    const { clientX } = e;
    const { width, left } = e.currentTarget.getBoundingClientRect();

    const clickX = clientX - left;

    if (clickX < width * 0.33) {
      setOperationalStatus('Running');
    } else if (clickX >= width * 0.33 && clickX < width * 0.66) {
      setOperationalStatus('Disabled');
    } else {
      setOperationalStatus('Other');
    }
  };

  const toTitleCase = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const clearAll = () => {
    setComplianceType('OOOO');
    setOperationalStatus('Running');
    setFields((prev) => ({ ...prev, inspectionFrequency: null }));
    setStartTime(null);
    setEndTime(null);
    localStorage.setItem('inspectionFrequency', null);
    setFields((prev) => ({ ...prev, inspectionFrequency: null }));
  };

  useEffect(() => {
    handleDataLoading(
      inspectionFrequency?.enumerations?.inspectionFrequency,
      isInspectionFrequencyLoading,
      inspectionFrequencyError,
      setFrequencyOptions,
      'inspectionFrequency'
    );
  }, [
    inspectionFrequency,
    isInspectionFrequencyLoading,
    inspectionFrequencyError
  ]);

  useEffect(() => {
    const handleClick = (event) => {
      const element = event.target;
      if (
        element
                && element.classList.contains('ant-picker-time-panel-cell-inner')
      ) {
        element.classList.add('user-selected');
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <>
      <Container>
        <Header>Inspection details</Header>
        <SubHeader>Part 1 of 4: Info</SubHeader>
        <BaseLabel>Compliance type</BaseLabel>
        <ToggleSwitch
          type={complianceType}
          setType={setComplianceType}
          text1="Voluntary"
          text2="OOOO"
          noBottomMargin
        />
        <Label>Operational status</Label>
        <ToggleThree
          onClick={handleToggleThree}
          state={operationalStatus}
        >
          <ToggleCircleThree state={operationalStatus}>
            {toTitleCase(operationalStatus)}
          </ToggleCircleThree>
          <PlaceholdersThree>
            <PlaceholderLeft>Running</PlaceholderLeft>
            <Placeholder
              leftBorder={
                operationalStatus === 'Disabled'
                                || operationalStatus === 'Other'
              }
              rightBorder={
                operationalStatus === 'Disabled'
                                || operationalStatus === 'Running'
              }
            >
              Disabled
            </Placeholder>
            <PlaceholderRight>Other</PlaceholderRight>
          </PlaceholdersThree>
        </ToggleThree>
        <Label>Inspection frequency</Label>
        <StyledSelectDiv>
          <Select
            id="frequency"
            styles={customStyles}
            className="detail-select"
            classNamePrefix="detail-select"
            isClearable
            value={
              frequencyOptions?.find(
                (option) => option.label
                                    === fields?.inspectionFrequency
              ) || null
            }
            onChange={(selectedOption) => {
              setFields((prev) => ({
                ...prev,
                inspectionFrequency: selectedOption?.label
              }));
            }}
            options={frequencyOptions || []}
            placeholder="Select a frequency type"
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator,
              DropdownIndicator
            }}
          />
        </StyledSelectDiv>
        <Label>Inspection time</Label>
        <TimeInputContainer>
          <RangerPicker />
        </TimeInputContainer>
        <ClearButton onClear={clearAll} />
        <Spacer />
      </Container>
      <FooterNav>
        <Back type="submit" onClick={handleBack}>
          Back
        </Back>
        <Next type="submit" onClick={handleNext}>
          Next
        </Next>
      </FooterNav>
    </>
  );
};

export default Info;
