import React, {
  useCallback, useContext, useState, useEffect, useRef
} from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import {
  IoChevronDownOutline, IoClose, IoCheckmark, IoChevronUpOutline
} from 'react-icons/io5';
import { FiEdit2 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { Input } from 'antd';

import { ModifyInspectionsContext } from '../../../../../context/ModifyInspectionsContext';
import placeholderImage from '../../../image-outline.svg';
import { global } from '../../../../../shared/colors';
import sliceName from '../../../components/utils/sliceName';
import sliceTruncation from '../../../components/utils/sliceTruncation';
import customStyles from '../../../components/customStyles/customStyles';
import DropdownIndicator from '../../../../../components/Select/DropdownIndicator';
import { concatenateCredentials } from '../../../../../shared/utils';
import ClearIndicator from '../../../../../components/Select/ClearIndicator';

const credentialsStyles = {
  ...customStyles,
  container: (provided) => ({
    ...customStyles.container(provided),
    margin: '8px 0'
  })
};

const flexCenter = `
    display: flex;
    justify-content: center;
    align-items: center;
`;

const clickable = `
    cursor: pointer;
    &:hover {
        opacity: 65%;
    }
`;

const borderMixin = `
    border-radius: 10px;
    border: 1px dashed ${global.Gray5};
`;

const noBackgroundNoBorder = `
    background: none;
    border: none;
`;

const hideInputSpinners = `
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield;
    }
`;

const Form = styled.form`
  ${flexCenter}
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  background: ${global.White};
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  height: ${(props) => (props.isExpanded ? 'auto' : 'fit-content')};
`;

const FileWrapper = styled.div`
  ${flexCenter}
  flex: 1 0 33%;
  max-width: 33%;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Placeholder = styled.div`
  ${flexCenter}
  flex-direction: column;
  width: 100%;
`;

const Image = styled.img`
  width: 88px;
  height: auto;
  margin: auto;
`;

const UploadLabel = styled.p`
  color: ${global.PrimaryBlue};
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
`;

const PreviewView = styled.div`
  ${flexCenter}
  width: 100%;
  min-height: 167px;
  border-radius: 10px;
  border: 1px dashed ${global.Gray5};
  cursor: pointer;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
`;

const TableView = styled.div`
  ${borderMixin}
  display: flex;
  width: 100%;
  min-height: 167px;
  cursor: pointer;
  background: ${global.Gray6};
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  margin: 20px 0 20px 20px;
  color: ${global.Gray2};

  th,
  td {
    padding: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tr:nth-child(odd) {
    background-color: ${global.Gray6};
  }

  tr:nth-child(even) {
    background-color: ${global.White};
    border-radius: 10px;
  }

  tr {
    margin: 12px 0;
  }

  th:first-child {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    text-align: left;
    background-color: ${global.Gray5};
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td {
    padding-left: 16px;
  }

  th:last-child {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: ${global.Gray5};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  th:not(:first-child):not(:last-child) {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: ${global.Gray5};
  }

  tbody tr,
  thead tr {
    display: table;
    table-layout: fixed;
    width: 90%;
  }

  tbody {
    display: block;
    width: 98%;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: ${global.Gray5};
      border: 2px solid transparent;
      background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${global.Gray5};
    }
  }
`;

const SurveyHeader = styled.p`
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  color: ${global.PrimaryBlue};
`;

const ExpandableButton = styled.button`
  ${noBackgroundNoBorder}
  ${clickable}
    font-size: 24px;
`;

const UploadMore = styled.p`
  ${noBackgroundNoBorder}
  ${flexCenter}
    ${clickable}
    color: var(--blue, ${global.PrimaryBlue});
  font-size: 12px;
  font-weight: 600;
  text-decoration-line: underline;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Remove = styled.button`
  ${noBackgroundNoBorder}
  color:${global.ErrorRed};
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: ${global.ErrorRed};
  text-decoration-thickness: 1px;
  font-weight: 600;
  font-size: 12px;

  &:hover {
    text-decoration-thickness: 2px;
  }
`;

const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: ${global.Gray2};
  font-size: 12px;
  font-weight: 600;
`;

const SubLabel = styled(Label)`
  color: ${global.Gray4};
  margin-top: 4px;
  margin-bottom: 8px;
`;

const StyledInputAnt = styled(Input)`
  width: 100%;
`;

const InputLeft = styled(StyledInputAnt)`
  margin-right: -8px;
  height: 51px;
  border-radius: 10px;
  padding: 8px 16px;

  ${hideInputSpinners}
`;

const InputRight = styled(StyledInputAnt)`
  height: 51px;
  border-radius: 0 10px 10px 0;
  padding-left: 38px;

  ${hideInputSpinners}
`;

const TimeInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  position: relative;
  top: 28px;
  right: 33px;
  height: 50px;
`;

const Button = styled.button`
  width: 224px;
  height: 31px;
  border-radius: 10px;
  background: none;
  border: none;
  box-shadow: 2px 2px 10px 0px #0000000d;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 12px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 2px 2px 20px 0px #0000001a;
    transform: scale(1.02);
  }

  &:active {
    box-shadow: 1px 1px 5px 0px #0000001a;
    transform: scale(0.98);
  }
`;

const VideoTH = styled.th`
  width: 45%;
`;

const TableCell = styled.td`
  width: 40%;
`;

const StyledInput = styled.input`
  width: 50%;
  margin: 0 8px;
  border: none;
  background: none;
`;

const FileNameLink = styled.a`
  text-decoration: underline;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CloseIcon = styled(IoClose)`
  color: ${global.ErrorRed};
  cursor: pointer;

  &:hover {
    opacity: 65%;
  }
`;

const CheckmarkIcon = styled(IoCheckmark)`
  color: ${global.Green};
  cursor: pointer;

  &:hover {
    opacity: 65%;
  }
`;

const EditButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 65%;
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 65%;
  }
`;

const EditDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  margin-bottom: 8px;
`;

const VideoTitle = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  margin: 0 8px;
  color: #424242;
  font-weight: 600;
`;

const ThumbImage = styled.img`
  width: 100%;
  max-width: 170px;
  height: auto;
  border-radius: 10px;
`;

const ThumbVideo = styled.video`
  width: 100%;
  max-width: 170px;
  height: auto;
  border-radius: 10px;
`;

const FieldsDiv = styled.div`
  width: 100%;
  margin-top: 12px;
`;

const LinkIconsDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputWrapperRight = styled.div`
  position: relative;
  width: 100%;
  margin: 8px 0 20px 0;

  ${(props) => props.id === 'hours'
    && `
        margin-right: -18px;
    `}

  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: ${(props) => `'${props?.id}'`};
    right: 16px;
    color: ${global.Gray5};
    font-size: 16px;
  }
`;

const InputWrapperLeft = styled.div`
  position: relative;
  width: 100%;
  margin: 8px 0 20px 0;

  ${(props) => props.id === 'hours'
    && `
        margin-right: -18px;
        z-index: 3;
    `}

  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: ${(props) => `'${props?.id}'`};
    right: 16px;
    color: ${global.Gray5};
    z-index: 2;
    font-size: 16px;
  }
`;

const StyledConcatenation = styled.div`
  color: ${global.Gray4};
  margin-top: 8px;
  margin-bottom: 8px;
  white-space: pre-line;
  font-size: 12px;
  font-weight: 600;
`;

const SurveyType = styled.p`
  margin: 0 0 20px 0;
  color: ${global.Gray4};
  font-size: 12px;
  font-weight: 600;
`;

const SurveyForm = ({
  files, formIndex, surveys, setSurveys
}) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [editMode, setEditMode] = useState({});
  const [isExpanded, setIsExpanded] = useState(true);
  const [isTableView, setTableView] = useState(false);
  const [selectedInspector, setSelectedInspector] = useState(null);
  const counterRef = useRef(0);
  const [inputValues, setInputValues] = useState(
    Array.isArray(files) ? files.map((file) => file.name) : []
  );
  const [currentInputValue, setCurrentInputValue] = useState('');
  const {
    updateFiles, methodOptions, inspectorOptions, instrumentsOptions, inspectors
  } = useContext(ModifyInspectionsContext);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => {
        counterRef.current += 1;
        const uniqueVideoName = `Video${counterRef.current}_${uuidv4()}`;
        const uniqueFileName = `${file.name}_${uuidv4()}`;
        return {
          ...file,
          preview: URL.createObjectURL(file),
          videoName: uniqueVideoName,
          name: uniqueFileName,
          size: file.size,
          type: file.type
        };
      });
      updateFiles(formIndex, (oldFiles) => [...oldFiles, ...newFiles]);
      setInputValues((oldNames) => [...oldNames, ...newFiles.map((file) => file.name)]);
    },
    [formIndex, updateFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    noClick: editingIndex !== null
  });

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const updatedFiles = [...files];
      updatedFiles[editingIndex].videoName = currentInputValue;
      updateFiles(formIndex, () => updatedFiles);
      setEditingIndex(null);
    }
  };

  const handleVideoNameUpdate = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    const updatedFiles = [...files];
    updatedFiles[index].videoName = currentInputValue;
    updateFiles(formIndex, () => updatedFiles);
    setEditingIndex(null);
  };

  const handleCloseClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setEditingIndex(null);
  };

  const createEditClickHandler = (index, file) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    setEditingIndex(index);
    setCurrentInputValue(file.videoName);
  };

  const createPreviewClickHandler = (previewUrl) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    window.open(previewUrl, '_blank');
  };

  const handleRemoveClick = (index) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    const updatedNames = [...inputValues];
    updatedNames.splice(index, 1);
    updateFiles(formIndex, (oldFiles) => {
      const updatedFiles = [...oldFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
    setInputValues(updatedNames);
  };

  const thumbs = files.map((file, index) => (
    <FileWrapper key={file.name}>
      <>
        {editingIndex === index ? (
          <EditDiv>
            <IoClose
              style={{ color: 'red', cursor: 'pointer' }}
              size={20}
              onClick={handleCloseClick}
            />
            <StyledInput
              type="text"
              value={sliceName(currentInputValue)}
              onChange={(event) => setCurrentInputValue(event.target.value)}
              onKeyDown={handleKeyPress}
            />
            <IoCheckmark
              style={{ color: 'green', cursor: 'pointer' }}
              size={20}
              onClick={(event) => handleVideoNameUpdate(event, index)}
            />
          </EditDiv>
        ) : (
          <EditDiv>
            <VideoTitle>{sliceTruncation(file.videoName)}</VideoTitle>
            <FiEdit2
              size={12}
              color={global.Gray4}
              onClick={createEditClickHandler(index, file)}
              style={{ cursor: 'pointer' }}
            />
          </EditDiv>
        )}

        {file.type && file.type.startsWith('image') && (
          <a
            href={file.preview}
            target="_blank"
            rel="noopener noreferrer"
            onClick={createPreviewClickHandler(file.preview)}
          >
            <ThumbImage src={file.preview} alt={file.videoName} />
          </a>
        )}
        {file.type && file.type.startsWith('video') && (
          <a
            href={file.preview}
            target="_blank"
            rel="noopener noreferrer"
            onClick={createPreviewClickHandler(file.preview)}
          >
            <ThumbVideo src={file.preview} controls />
          </a>
        )}
      </>

      {editingIndex !== index && <Remove onClick={handleRemoveClick(index)}>remove video</Remove>}
    </FileWrapper>
  ));

  const handleInputChange = (eventOrValue, fieldName) => {
    const updatedFormValues = [...surveys];

    if (typeof eventOrValue === 'object' && eventOrValue.target) {
      updatedFormValues[formIndex][eventOrValue.target.name] = eventOrValue.target.value;
    } else if (typeof eventOrValue === 'string' || typeof eventOrValue === 'number') {
      updatedFormValues[formIndex][fieldName] = eventOrValue;
    }

    setSurveys(updatedFormValues);
  };

  const handleMethodChange = (selectedOption) => {
    const methodValue = {
      method: {
        id: selectedOption?.value,
        name: selectedOption?.label
      }
    };

    // eslint-disable-next-line max-len
    const updatedSurveys = surveys.map((survey, index) => (index === formIndex ? { ...survey, ...methodValue } : survey));

    setSurveys(updatedSurveys);
  };

  const handleCloseIconClick = (videoName) => (event) => {
    event.stopPropagation();
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [videoName]: false
    }));
    setCurrentInputValue(videoName);
  };

  const handleInput = (e) => {
    setCurrentInputValue(e.target.value);
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const handleInputKeyDown = (videoName, index) => (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      const updatedFiles = [...files];
      updatedFiles[index].videoName = currentInputValue;

      updateFiles(formIndex, () => updatedFiles);

      setEditMode((prevEditMode) => ({
        ...prevEditMode,
        [videoName]: false
      }));
    }
  };

  const handleCheckmarkClick = (videoName, index) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    const updatedFiles = [...files];
    updatedFiles[index].videoName = currentInputValue;

    updateFiles(formIndex, () => updatedFiles);

    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [videoName]: false
    }));
  };

  const handleFileNameClick = (previewUrl) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    window.open(previewUrl, '_blank');
  };

  const handleEditButtonClick = (videoName) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [videoName]: !prevEditMode[videoName]
    }));

    setCurrentInputValue(videoName);
  };

  const handleDeleteButtonClick = (index) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    const updatedNames = [...inputValues];
    updatedNames.splice(index, 1);

    updateFiles(formIndex, (oldFiles) => {
      const updatedFiles = [...oldFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });

    setInputValues(updatedNames);
  };

  const handleCloseButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setTableView(false);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setTableView(true);
  };

  const handleInspectorChange = (value) => {
    const newInspector = value?.value;
    setSelectedInspector(newInspector);
    const updatedSurveys = surveys.map((survey, index) => (index === formIndex
      ? {
        ...survey,
        inspectors: [
          {
            id: newInspector,
            name: value?.item?.name,
            email: value?.item?.email,
            credentials: value?.item?.credentials
          }
        ]
      }
      : survey));
    setSurveys(updatedSurveys);
  };

  const handleSurveyInstrumentChange = (value) => {
    const surveyInstrumentValue = {
      surveyInstrument: {
        id: value?.value,
        name: value?.item?.name,
        serialNumber: value?.item?.serialNumber,
        sensorType: {
          id: value?.item?.sensorTypeId,
          name: value?.item?.sensorTypeName
        }
      }
    };

    // eslint-disable-next-line max-len
    const updatedSurveys = surveys.map((survey, index) => (index === formIndex ? { ...survey, ...surveyInstrumentValue } : survey));

    setSurveys(updatedSurveys);
  };

  useEffect(() => {
    if (
      surveys[formIndex]
      && Array.isArray(surveys[formIndex].inspectors)
      && surveys[formIndex].inspectors.length > 0
    ) {
      const currentSurveyInspector = surveys[formIndex].inspectors[0].id;
      setSelectedInspector(currentSurveyInspector);
    } else {
      setSelectedInspector(null);
    }
  }, [formIndex, surveys]);

  useEffect(() => {
    // eslint-disable-next-line max-len
    setInputValues((prevInputValues) => prevInputValues.map((value, index) => (index === editingIndex ? currentInputValue : value)));
  }, [currentInputValue, editingIndex]);

  useEffect(() => {
    if (files.length < 10) {
      setTableView(false);
    }
  }, [files]);

  return (
    <Form isExpanded={isExpanded}>
      <SurveyHeader>
        {`Survey ${formIndex + 1}`}
        <ExpandableButton
          type="button"
          className="survey-toggle-button"
          onClick={() => setIsExpanded(!isExpanded)}
          tabIndex={0}
        >
          {isExpanded ? (
            <IoChevronUpOutline style={{ color: global.Gray4 }} />
          ) : (
            <IoChevronDownOutline style={{ color: global.Gray4 }} />
          )}
        </ExpandableButton>
      </SurveyHeader>

      {isExpanded && (
        <FieldsDiv>
          <Label>Inspector</Label>
          <Select
            id="surveyInspector"
            isClearable
            autosize
            styles={selectedInspector ? credentialsStyles : customStyles}
            className="detail-select"
            classNamePrefix="detail-select"
            value={
              surveys[formIndex]
              && Array.isArray(surveys[formIndex].inspectors)
              && surveys[formIndex].inspectors.length > 0
                ? inspectorOptions.find(
                  (option) => option.value === surveys[formIndex].inspectors[0].id
                )
                : null
            }
            options={inspectorOptions || []}
            placeholder="Select inspector"
            onChange={handleInspectorChange}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator,
              DropdownIndicator
            }}
          />
          {selectedInspector && (
            <StyledConcatenation data-cy="credentials">
              {concatenateCredentials(inspectors?.inspectors)
                ?.find((item) => item.id === selectedInspector)
                ?.concatenatedCredentials.split('\n')
                .map((line) => {
                  const uniqueKey = `credential-${line}`;
                  return <div key={uniqueKey}>{line}</div>;
                }) || <div>No credentials</div>}
            </StyledConcatenation>
          )}

          <Label>Inspection method</Label>
          <Select
            id="surveyMethod"
            isClearable
            className="detail-select"
            classNamePrefix="detail-select"
            autosize
            styles={customStyles}
            value={
              methodOptions.find((option) => option.value === surveys[formIndex]?.method?.id)
              || null
            }
            options={methodOptions || []}
            placeholder="Select method"
            onChange={handleMethodChange}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator,
              DropdownIndicator
            }}
          />
          <Label>Survey instrument</Label>
          <Select
            id="surveyInstrument"
            isClearable
            autosize
            styles={credentialsStyles}
            className="detail-select"
            classNamePrefix="detail-select"
            value={
              instrumentsOptions.find(
                (option) => option.value === surveys[formIndex]?.surveyInstrument?.id
              ) || null
            }
            options={instrumentsOptions || []}
            placeholder="Select instrument"
            onChange={handleSurveyInstrumentChange}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator,
              DropdownIndicator
            }}
          />
          {surveys[formIndex]?.surveyInstrument?.sensorType?.name && (
            <SurveyType>
              {`Sensor type: ${surveys[formIndex].surveyInstrument.sensorType.name}`}
            </SurveyType>
          )}
          <Label>Duration</Label>
          <TimeInputContainer>
            <InputWrapperLeft id="hours">
              <InputLeft
                id="surveyHours"
                value={surveys[formIndex]?.inspectionDurationHours}
                placeholder="Enter amount"
                onChange={(event) => handleInputChange(event.target.value, 'inspectionDurationHours')}
                type="number"
              />
            </InputWrapperLeft>
            <InputWrapperRight id="minutes">
              <InputRight
                id="surveyMinutes"
                value={surveys[formIndex]?.inspectionDurationMinutes}
                placeholder="Enter amount"
                onChange={(event) => handleInputChange(event.target.value, 'inspectionDurationMinutes')}
                type="number"
              />
            </InputWrapperRight>
          </TimeInputContainer>

          <LabelDiv>
            <Label>Survey videos</Label>
            <SubLabel>Files should be .mp4, .mov, or .avi</SubLabel>
          </LabelDiv>

          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isTableView ? (
              <TableView>
                <StyledTable>
                  <thead>
                    <tr>
                      <VideoTH>Video Name</VideoTH>
                      <th>File Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file, index) => (
                      <tr key={file.id}>
                        <TableCell>
                          {editMode[file.videoName] ? (
                            <div>
                              <CloseIcon size={16} onClick={handleCloseIconClick(file.videoName)} />
                              <StyledInput
                                value={sliceName(currentInputValue)}
                                onChange={handleInput}
                                onClick={handleInputClick}
                                onKeyDown={handleInputKeyDown(file.videoName, index)}
                              />
                              <CheckmarkIcon
                                size={16}
                                onClick={handleCheckmarkClick(file.videoName, index)}
                              />
                            </div>
                          ) : (
                            <>{sliceName(file.videoName)}</>
                          )}
                        </TableCell>
                        <td>
                          {!editMode[file.videoName] && (
                            <LinkIconsDiv>
                              <FileNameLink
                                href={file.preview}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={handleFileNameClick(file.preview)}
                              >
                                {sliceName(file.name)}
                              </FileNameLink>
                              <div>
                                <EditButton
                                  type="button"
                                  onClick={handleEditButtonClick(file.videoName)}
                                >
                                  <FiEdit2 size={16} color={global.Gray4} />
                                </EditButton>
                                <DeleteButton
                                  type="button"
                                  onClick={handleDeleteButtonClick(index)}
                                >
                                  <HiOutlineTrash size={16} color={global.ErrorRed} />
                                </DeleteButton>
                              </div>
                            </LinkIconsDiv>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
                {files.length > 9 && (
                  <CloseButton onClick={handleCloseButtonClick}>
                    <IoClose color={global.Gray2} size={20} />
                  </CloseButton>
                )}
              </TableView>
            ) : (
              <div>
                <PreviewView>
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    files.length === 0 && (
                      <Placeholder>
                        <Image src={placeholderImage} alt="placeholder" />
                        <UploadLabel>Upload media</UploadLabel>
                      </Placeholder>
                    )
                  )}
                  {thumbs.slice(0, 9)}
                  {files.length > 9 && (
                    <Button type="button" onClick={handleButtonClick}>
                      and
                      {' '}
                      {files.length}
                      {' '}
                      other surveys
                    </Button>
                  )}
                </PreviewView>
                {files.length > 0 && <UploadMore>Upload more media</UploadMore>}
              </div>
            )}
          </div>
        </FieldsDiv>
      )}
    </Form>
  );
};

export default SurveyForm;
