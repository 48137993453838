import { useState } from 'react';

const useAsyncClick = (asyncClick, onClick) => {
  const [waiting, setWaiting] = useState(false);
  const onClickAsyncHandler = (e) => {
    if (asyncClick) {
      setWaiting(true);
    }
    const callback = () => {
      setWaiting(false);
    };
    onClick(e, callback);
  };

  return {
    waiting,
    onClickHandler: onClickAsyncHandler
  };
};

export default useAsyncClick;
