export const sensorTypes = {
  FLIR: { id: 1, label: 'FLIR OGI' },
  LASER: { id: 2, label: 'Laser' },
  SNIFFER: { id: 3, label: 'Sniffer' },
  INSPECTION_REPORT: { id: 4, label: 'Inspection Report' },
  STANDARD_PIC: { id: 5, label: 'Standard Pic' },
  MISC: { id: 6, label: 'Misc.' },
  VENTUS_OGI: { id: 7, label: 'Ventus OGI' },
  LIDAR: { id: 8, label: 'LIDAR' },
  PHTOGRAMMETRY: { id: 9, label: 'Photogrammetry' }
};

export const VIDEO_FILE_TYPE = 1;
export const DOCUMENT_FILE_TYPE = 2;
export const IMAGE_FILE_TYPE = 3;
export const COMPLETE_STATUS_ID = 5;

export const AIR_METHANE_ADMIN_GROUP_NAME = 'Air Methane Admin';

export const PENDING_FILE_UPLOAD_STATUS = 'PENDING_FILE_UPLOAD_STATUS';
export const FAILED_FILE_UPLOAD_STATUS = 'FAILED_FILE_UPLOAD_STATUS';
export const SUCCESS_FILE_UPLOAD_STATUS = 'SUCCESS_FILE_UPLOAD_STATUS';

export const inspectionStatusMap = {
  draft: 'Draft',
  leak_processing: 'Processing leak',
  media_processing: 'Processing media',
  ready_to_submit: 'Ready for submission'
};

export const emptyBaseMonitoredComponent = {
  componentType: null,
  difficultToMonitor: false,
  difficultToMonitorDescription: '',
  unsafeToMonitor: false,
  unsafeToMonitorDescription: ''
};

export const inspectionSections = {
  SITE_DETAILS: 'site-details',
  INSPECTION_DETAILS: 'inspection-details',
  INFO: 'info',
  VERIFICATION: 'verification',
  WEATHER_CONDITIONS: 'weather-conditions',
  SURVEY_DETAILS: 'survey-details',
  LEAK_DETAILS: 'leak-details',
  LEAK_REPAIRS: 'leak-repairs',
  INSPECTION_SUMMARY: 'inspection-summary',
  FINAL_SUBMISSION: 'final-submission'
};

export const requiredFieldsBySection = {
  [inspectionSections.SITE_DETAILS]: [
    'inspectionDate',
    'customer',
    'customerLocation',
    'state',
    'county',
    'contactPhone',
    'contactName',
    'latitude',
    'longitude'
  ],
  [inspectionSections.INFO]: [
    'complianceType',
    'operationalStatus',
    'inspectionDate',
    'startTime',
    'endTime'
  ],
  [inspectionSections.VERIFICATION]: [
    'verificationImage',
    'surveyInstrument',
    'altitude',
    'facilitySign'
  ],
  [inspectionSections.WEATHER_CONDITIONS]: [
    'maxWindSpeed',
    'ambientTemperature',
    'windDirection',
    'skyConditions',
    'barometricPressure',
    'humidityPercent'
  ],
  [inspectionSections.SURVEY_DETAILS]: [
    'inspectors',
    'method',
    'surveyInstrument',
    'inspectionDurationHours',
    'inspectionDurationMinutes',
    'files'
  ],
  [inspectionSections.LEAK_DETAILS]: [
    'componentType',
    'componentSubtype',
    'timestamp',
    'location',
    'rateUom',
    'tagNumber',
    'leak1',
    'leak2'
  ],
  [inspectionSections.LEAK_REPAIRS]: ['date', 'file', 'leakId']
};

export const homeSections = {
  IN_PROGRESS: 'in-progress',
  COMPLETE: 'complete'
};

export const INFINITE = 'infinite';
