import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { global } from '../../../shared/colors';

const buttonStyles = css`
    width: 100%;
    height: 43px;
    margin: 20px 20px 0 20px;
    cursor: pointer;
    border-radius: 10px;
    background: transparent;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
`;

const StyledButton = styled.button`
    ${buttonStyles}
    border: 1px solid ${(props) => (props.variant === 'delete' ? global.ErrorRed : global.Black)};
    color: ${(props) => (props.variant === 'delete' ? global.ErrorRed : 'inherit')};

    &:hover {
        background-color: ${(props) => (props.variant === 'delete' ? global.ErrorRed : global.Gray2)};
        color: ${global.White};
    }

    &:disabled {
        color: ${global.Gray5};
        cursor: not-allowed;
        background: ${global.Gray4};
        border: none;
    }

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Spinner = styled.div`
    border: 4px solid ${global.Gray5};
    border-top: 4px solid ${global.Blue};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const ActionButton = ({
  onClick,
  isLoading,
  children,
  disabled,
  variant,
  alwaysEnableOnLoading
}) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled || (isLoading && !alwaysEnableOnLoading)}
    variant={variant}
  >
    {isLoading ? <Spinner /> : children}
  </StyledButton>
);

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['save', 'delete']),
  alwaysEnableOnLoading: PropTypes.bool
};

ActionButton.defaultProps = {
  isLoading: false,
  disabled: false,
  variant: 'save',
  alwaysEnableOnLoading: false
};

export default ActionButton;
