import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';

import Home from './pages/Home/Home';
import FilteredFiles from './pages/Files/FilteredFiles';
import ModifyInspection from './pages/ModifyInspection/ModifyInspections';
import Upload from './pages/MultiUpload/Upload';
import { GlobalContext } from './context/GlobalContext';
import AccessDenied from './pages/NoAccess/AccessDenied';
import Header from './components/Header/Header';
import SideNav from './components/Sidenav/SideNav';
import { global } from './shared/colors';
import Inspections from './pages/Inspections/Inspection';

const StyledDiv = styled.div`
    width: 100%;
    overflow: hidden;
    overflow-x: hidden;
    background: ${global.White};
    background: ${global.White};
    flex-direction: column;
`;

const StyledSection = styled.section`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const Main = styled.section`
    width: 100%;
`;

const AppRoutes = ({ navLinks, activeLink }) => {
  const { is_admin, has_access } = useContext(GlobalContext);

  return !has_access ? (
    <AccessDenied />
  ) : (
    <StyledDiv>
      <Header />
      <StyledSection>
        <SideNav activeLink={activeLink} navLinks={navLinks} />
        <Main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/orgs/:customerId" element={<Home />} />
            <Route
              path="/orgs/:customerId/inspections/:id"
              element={<Inspections />}
            />
            <Route
              path="/orgs/:customerId/inspections/:id/files/:flogistixId/:insp"
              element={<FilteredFiles />}
            />
            {is_admin && (
              <>
                <Route
                  path="/multi-upload"
                  element={<Upload />}
                />
                <Route
                  path="/inspections/:id?/*"
                  element={<ModifyInspection />}
                />
              </>
            )}
          </Routes>
        </Main>
      </StyledSection>
    </StyledDiv>
  );
};

export default AppRoutes;
