import React, { useContext } from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

import { global } from '../../shared/colors';
import { GlobalContext } from '../../context/GlobalContext';
import airMethaneLogo from '../../assets/FlogistixLogo.png';
import flogistixLogo from '../../assets/HeaderLogo.svg';

const StyledHeaderLogo = styled.a`
    margin-top: 23px;
    margin-left: 40px;
`;

const Logo = () => (
  <StyledHeaderLogo href="/" aria-label="Home">
    <img src={flogistixLogo} alt="Air Methane logo" id="Air Methane logo" />
  </StyledHeaderLogo>
);

const StyledHeader = styled.header`
    top: 0;
    right: 0;
    height: 72px;
    width: 100%;
    display: flex;
    z-index: 1000;
    background-color: ${global.Gray6};
`;

const StyledProfileContainer = styled.div`
    margin: 14px 30px 14px auto;

    img {
        width: 80px;
    }

    span {
        display: flex;
        align-items: center;
    }
`;

const StyledProfileButton = styled.button`
    width: 152px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid ${global.Gray5};
    background-color: ${global.Gray6};
    border-radius: 5px;

    &:hover {
        cursor: pointer;
    }
`;

const Header = () => {
  const { user } = useAuth0();

  const { setProfileModalOpen } = useContext(GlobalContext);

  return (
    <StyledHeader role="banner" id="AccountSettingsProfile">
      <Logo id="#DashboardLogo" />
      <StyledProfileContainer
        onClick={() => setProfileModalOpen(true)}
        aria-label="Profile and Settings"
      >
        <StyledProfileButton aria-label="Open Profile Settings">
          <img src={airMethaneLogo} alt="Flogistix logo" id="Flogistix logo" />
          <Avatar
            size={28}
            src={user?.picture}
            alt={`${user?.name}'s profile picture`}
          />
        </StyledProfileButton>
      </StyledProfileContainer>
    </StyledHeader>
  );
};

export default Header;
