import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { IoClose, IoCheckmark } from 'react-icons/io5';
import { FiEdit2 } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import placeholderImage from '../image-outline.svg';
import { global } from '../../../shared/colors';
import sliceName from './utils/sliceName';
import sliceTruncation from './utils/sliceTruncation';

const FlexCenter = `
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SubLabel = styled.p`
    font-size: 12px;
    font-weight: 600;
    color: ${global.Gray4};
    margin-bottom: 8px;
    margin-top: 4px;
`;

const StyledDropzone = styled.div`
    ${FlexCenter}
    flex: 1;
    flex-direction: column;
    border-width: 1px;
    border-radius: 10px;
    border-color: ${global.Gray5};
    border-style: dashed;
    background-color: ${global.White};
    color: ${global.Gray5};
    outline: none;
    transition: border 0.24s ease-in-out;
    width: 100%;
    cursor: pointer;
`;

const Image = styled.img`
    width: 88px;
    height: auto;
    margin: auto;
`;

const UploadLabel = styled.p`
    color: ${global.PrimaryBlue};
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
`;

const ImageContainer = styled.div`
    ${FlexCenter}
    position: relative;
`;

const Placeholder = styled.div`
    ${FlexCenter}
    flex-direction: column;
`;

const Remove = styled.button`
    color: ${global.ErrorRed};
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${global.ErrorRed};
    text-decoration-thickness: 1px;
    font-weight: 600;
    margin-top: 8px;

    &:hover {
        text-decoration-thickness: 2px;
    }
`;

const DropzoneContainer = styled.div`
    ${FlexCenter}
    flex-direction: column;
    width: 100%;
    height: ${({ height }) => height || '390px'};
`;

const EditDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const LeakTitle = styled.p`
    max-width: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${global.Gray2};
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin: 8px 4px;
`;

const StyledInput = styled.input`
    width: 50%;
    margin: 0 8px;
    border: none;
    background: none;
`;

const FileDropzone = ({
  id,
  subLabel,
  onFileAdded,
  onFileRemoved,
  value,
  height,
  editableName
}) => {
  const [files, setFiles] = useState(value ? [value] : []);
  const [editingIndex, setEditingIndex] = useState(null);
  const [currentInputValue, setCurrentInputValue] = useState('');

  const handleLeakNameUpdate = (event, index) => {
    event.preventDefault();
    const updatedFiles = [...files];
    updatedFiles[index].leakName = currentInputValue;
    setFiles(updatedFiles);
    setEditingIndex(null);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const updatedFiles = [...files];
      updatedFiles[editingIndex].leakName = currentInputValue;
      setFiles(updatedFiles);
      setEditingIndex(null);
    }
  };

  const handleCloseClick = () => {
    setEditingIndex(null);
  };

  const createEditClickHandler = (index, file) => () => {
    setEditingIndex(index);
    setCurrentInputValue(file?.leakName);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (files.length === 0) {
        const file = acceptedFiles[0];
        const uniqueLeakName = `${
          editableName || file?.leakName
        }_${uuidv4()}`;
        const uniqueFileName = `${file.name}_${uuidv4()}`;
        const newFile = {
          id,
          leakName: uniqueLeakName,
          name: uniqueFileName,
          type: file?.type,
          size: file?.size,
          preview: URL.createObjectURL(file)
        };
        setFiles([newFile]);
        onFileAdded(newFile);
      }
    },
    [id, files, onFileAdded, editableName]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, video/*',
    onDrop,
    noClick: files.length > 0,
    noKeyboard: files.length > 0
  });

  const clearImage = (leakNameToRemove) => {
    const updatedFiles = files.filter(
      (file) => file.leakName !== leakNameToRemove
    );
    setFiles(updatedFiles);
    onFileRemoved(leakNameToRemove);
  };

  const media = files.map((file) => {
    let content;
    if (file.type.startsWith('image/')) {
      content = (
        <img
          src={file.preview}
          style={{
            width: '100%',
            maxHeight: '150px',
            borderRadius: '10px'
          }}
          alt="preview"
        />
      );
    } else if (file.type.startsWith('video/')) {
      content = (
        <video
          controls
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '10px',
            maxHeight: '385px'
          }}
        >
          <source src={file.preview} type={file.type} />
          {/* Dummy track for ESLint */}
          <track
            src="#"
            kind="captions"
            srcLang="en"
            label="English"
            default
          />
          Your browser does not support the video tag.
        </video>
      );
    }

    return (
      <div key={file.id}>
        <ImageContainer>{content}</ImageContainer>
      </div>
    );
  });

  const renderEditableName = (file, index) => {
    if (editableName) {
      if (editingIndex === index) {
        return (
          <EditDiv>
            <IoClose
              style={{ color: 'red', cursor: 'pointer' }}
              size={20}
              onClick={handleCloseClick}
            />
            <StyledInput
              type="text"
              value={sliceName(currentInputValue)}
              onChange={(event) => setCurrentInputValue(event.target.value)}
              onKeyDown={handleKeyPress}
            />
            <IoCheckmark
              style={{ color: 'green', cursor: 'pointer' }}
              size={20}
              onClick={(event) => handleLeakNameUpdate(event, index)}
            />
          </EditDiv>
        );
      }
      return (
        <EditDiv>
          <LeakTitle>{sliceTruncation(file?.leakName)}</LeakTitle>
          <FiEdit2
            size={16}
            color={global.Gray4}
            onClick={createEditClickHandler(index, file)}
          />
        </EditDiv>
      );
    }

    return null;
  };

  useEffect(() => {
    if (value) {
      setFiles([value]);
    } else {
      setFiles([]);
    }
  }, [value]);

  return (
    <>
      <SubLabel>{subLabel}</SubLabel>
      <DropzoneContainer height={height}>
        {files.map((file, index) => renderEditableName(file, index))}
        <StyledDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          {media}
          {files.length === 0 && (
            <Placeholder>
              <Image src={placeholderImage} alt="placeholder" />
              <UploadLabel>Upload media</UploadLabel>
            </Placeholder>
          )}
        </StyledDropzone>
        {files.length > 0 && (
          <Remove onClick={() => clearImage(files[0].leakName)}>
            remove image
          </Remove>
        )}
      </DropzoneContainer>
    </>
  );
};

FileDropzone.propTypes = {
  id: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  onFileAdded: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    leakName: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.number,
    preview: PropTypes.string
  }),
  height: PropTypes.string,
  editableName: PropTypes.bool
};

FileDropzone.defaultProps = {
  subLabel: '',
  value: null,
  height: '390px',
  editableName: false
};

export default FileDropzone;
