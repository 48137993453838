import React, { useState, useContext, useEffect } from 'react';
import { Tooltip } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { IoChevronForward } from 'react-icons/io5';
import styled from 'styled-components';

import IconToggle from '../../../components/IconToggle';
import { Button } from '../../../components';
import { GlobalContext } from '../../../context/GlobalContext';
import { addKeys } from '../../../shared/utils';
import { global } from '../../../shared/colors';
import FileDeleteModal from '../../../components/Modals/FileDeleteModal';
import FilesSkeleton from '../FilesSkeleton/FilesSkeleton';
import FileUploadModal from '../../../components/Modals/FileUploadModal';
import useFiles from '../../../services/useFiles';
import Table from '../../../components/Table/Table';
import Status from '../../../components/Status/Status';
import {
  getFiles,
  patchFile,
  deleteFile
} from '../../../services/airmethaneApi';
import {
  StyledIoDocumentTextOutline,
  StyledIoVideocamOutline,
  StyledIoLocateOutline
} from '../styled-components';
import { Header } from '../../../shared/styled-components';
import {
  StyledInput,
  StyledTabs,
  IconWrapper,
  HeaderWrapper,
  InspectionID,
  StyledFile,
  TableButtonContainer,
  StyledPaperClip,
  StyledDownload,
  StyledTrashCan,
  StyledTableButtonDiv,
  StyledIoPencilOutline,
  StyledIoDownloadOutline,
  StyledIoTrashOutline,
  StyledBsPaperclip,
  BatchItems,
  StyledColumnDiv
} from './styled-components';

const Breadcrumbs = styled.div`
    display: flex;
    width: fit-content;
    justify-content: space-between;
    align-items: baseline;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 32px;
`;

const Breadcrumb = styled.button`
    background: none;
    border: none;
    color: ${global.Gray4};
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    font-size: 16px;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

    &:hover {
        text-decoration: underline;
        color: ${global.Black};
    }
`;

const InspectionBreadcrumb = styled.button`
    background: none;
    border: none;
    color: ${global.Gray4};
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    font-size: 16px;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0;

    &:hover {
        text-decoration: underline;
        color: ${global.Black};
    }
`;

const StyledSection = styled.div`
    height: 100vh;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
`;

const FilterTableTabs = styled(StyledTabs)`
    .ant-tabs-nav-list {
        margin-bottom: 20px;
    }
`;

const FilteredFilesTable = ({
  user_organization,
  user_site,
  onOrgBreadcrumbClick,
  onInspectionBreadcrumbClick,
  flogistix_id,
  inspId,
  OnOrgLocationBreadcrumbClick
}) => {
  const [current_record, setCurrentRecord] = useState({});
  const [selectedInspectionFile, setSelectedInspectionFile] = useState({});
  const [
    isInspectionFileDeleteModalVisible,
    setIsInspectionFileDeleteModalVisible
  ] = useState(false);
  const [updated_display, setDisplayPatch] = useState('');
  const [token, setToken] = useState('');
  const [isFileUploadModalVisible, setIsFileUploadModalVisible] = useState(false);
  const [selectedInspection, setSelectedInspection] = useState({});
  const [typeTab, setTypeTab] = useState('1');
  const [singleFileDeleteId, setSingleFileDeleteId] = useState(null);
  const {
    inspectionsReloadIsPending,
    is_admin,
    selectInspection,
    setSelectInspection,
    inspections,
    selectedRowKeys,
    setSelectedRowKeys,
    isStep2FormActive
  } = useContext(GlobalContext);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [filesLoading, data, fetchFiles] = useFiles({
    inspectionId: inspId
  });

  const reports = data
    && addKeys(
      data?.filter(
        (i) => (i.fileType === 'document' && i.leakId === null)
          || (i.fileType === 'image' && i.leakId === null)
      )
    );

  const videos = data && addKeys(data?.filter((i) => i.fileType === 'video'));
  const leaks = data && addKeys(data?.filter((i) => i.leakId !== null));

  const handleDisplayCancel = () => {
    setCurrentRecord('');
  };

  const updateInspectionFile = async (updatedDisplay, record, callback) => {
    try {
      const file = await patchFile(token, {
        id: record.id,
        displayName: updatedDisplay
      });
      if (file) {
        handleDisplayCancel();
      } else {
        console.error('Failed to update the row');
      }
      callback();
      return file;
    } catch (err) {
      callback();
      console.error(err);
      return null;
    }
  };

  const handleClickFunction = async (e, callback, record) => {
    updateInspectionFile(updated_display, record, callback);
    await fetchFiles();
  };

  const refreshFiles = async () => {
    if (!inspId) {
      return;
    }
    await fetchFiles();
    setCurrentRecord('');
    setSelectedRowKeys([]);
  };

  const viewFile = async (record) => {
    try {
      const file = await getFiles(token, { inline: true, id: record });
      window.open(file?.url, '_blank');
      return file;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const handleMultiDelete = async (callback) => {
    if (singleFileDeleteId) {
      try {
        const res = await deleteFile(token, singleFileDeleteId);
        if (res) {
          setSingleFileDeleteId(null);
          setIsInspectionFileDeleteModalVisible(false);
          callback();
          fetchFiles();
        }
      } catch (err) {
        console.error(err);
        setSingleFileDeleteId(null);
        callback();
        setIsInspectionFileDeleteModalVisible(false);
      }
    } else {
      const deletePromises = selectedRowKeys.map((key) => deleteFile(token, key));

      try {
        await Promise.all(deletePromises);
        await refreshFiles();
        setIsInspectionFileDeleteModalVisible(false);
        callback();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const showInspectionFileDeleteModal = () => {
    setIsInspectionFileDeleteModalVisible(true);
  };

  const handleInspectionFileDeleteModalOk = async (_, callback) => {
    handleMultiDelete(callback);
  };

  const handleInspectionFileDeleteModalCancel = () => {
    setSelectedInspectionFile({});
    setIsInspectionFileDeleteModalVisible(false);
  };

  const handleFileDisplay = (record) => {
    setDisplayPatch(record.displayName);
    setCurrentRecord({ rowId: record.id });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const handleMultiDownload = async (e, singleFile = null) => {
    e.stopPropagation();
    e.preventDefault();
    if (singleFile) {
      try {
        const result = await getFiles(token, { id: singleFile });
        window.open(result.url, '_blank');
      } catch (error) {
        console.error(error);
      }
    } else {
      selectedRowKeys.map(async (key) => {
        try {
          const result = await getFiles(token, { id: key });
          window.open(result.url, '_blank');
        } catch (error) {
          console.error(error);
        }
      });
    }
  };

  const handleFileUploadModalOk = () => {
    setIsFileUploadModalVisible(false);
  };

  const handleFileUploadModalCancel = () => {
    setIsFileUploadModalVisible(false);
  };

  const setNewSelectedInspection = (inspection) => {
    if (inspection?.id !== selectedInspection?.id) {
      setSelectedInspection(inspection);
    }
  };

  const showFileUploadModal = (r) => {
    setNewSelectedInspection(r);
    setIsFileUploadModalVisible(true);
  };

  const handleFileUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    showFileUploadModal(selectInspection);
  };

  useEffect(() => {
    if (!selectInspection) {
      const currentlySelected = inspections.find((inspection) => (
        inspection.id === parseInt(inspId, 10)
      ));
      setSelectInspection(currentlySelected);
    }
  }, [selectInspection]);

  const clientColumns = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '100%',
      ellipsis: true,
      sortable: true,
      render: (_, record) => (
        <StyledFile>
          <button
            target="_blank"
            aria-label="View File"
            type="submit"
            onClick={() => viewFile(record.id)}
          >
            {record.name}
          </button>
        </StyledFile>
      )
    }
  ];

  const clientColumnsWithTagId = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '70%',
      ellipsis: true,
      sortable: true,
      render: (_, record) => (
        <StyledFile>
          <button
            target="_blank"
            aria-label="View File"
            type="submit"
            onClick={() => viewFile(record.id)}
          >
            {record.name}
          </button>
        </StyledFile>
      )
    },
    {
      title: 'Tag Id',
      dataIndex: 'tagNumber',
      key: 'tagNumber',
      width: '30%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (current_record?.rowId === record?.tagNumber ? (
        <IconWrapper>
          <TableButtonContainer>
            <Button
              variant="outline"
              handleClick={handleDisplayCancel}
            >
              Cancel
            </Button>
          </TableButtonContainer>
          <TableButtonContainer>
            <Button
              asyncClick
              handleClick={(e, callback) => handleClickFunction(e, callback, record)}
            >
              Save
            </Button>
          </TableButtonContainer>
        </IconWrapper>
      ) : (
        <StyledColumnDiv>
          {value || 'No listed tag id'}
          <div className="icon-group">
            <IconToggle
              aria-label="Edit Display Name"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleFileDisplay(record);
              }}
            >
              <Tooltip
                title="Edit Display Name"
                color={global.White}
              >
                <StyledIoPencilOutline />
              </Tooltip>
            </IconToggle>
            <Tooltip
              title="Download Single File"
              color={global.White}
            >
              <StyledDownload
                data-cy="Download"
                onClick={(e) => handleMultiDownload(e, record.id)}
              >
                <StyledIoDownloadOutline className="gray-icon" />
              </StyledDownload>
            </Tooltip>
            {is_admin && (
              <StyledTrashCan
                className="gray-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSingleFileDeleteId(record.id);
                  showInspectionFileDeleteModal();
                }}
              >
                <Tooltip
                  title="Delete Single File"
                  color={global.White}
                >
                  <StyledIoTrashOutline data-cy="Delete Modal" />
                </Tooltip>
              </StyledTrashCan>
            )}
          </div>
        </StyledColumnDiv>
      ))
    }
  ];

  const clientColumnsWithSensorType = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '70%',
      ellipsis: true,
      sortable: true,
      render: (_, record) => (
        <StyledFile>
          <button
            target="_blank"
            aria-label="View File"
            type="submit"
            onClick={() => viewFile(record.id)}
          >
            {record.name}
          </button>
        </StyledFile>
      )
    },
    {
      title: 'Sensor Type',
      dataIndex: 'sensorType',
      key: 'sensorType',
      width: '30%',
      ellipsis: true,
      sortable: true
    }
  ];

  const adminColumns = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '100%',
      ellipsis: true,
      sortable: true,
      render: (displayValue, record) => (current_record?.rowId === record?.id ? (
        <StyledColumnDiv>
          <StyledInput
            className="form-control"
            value={updated_display}
            onChange={(e) => setDisplayPatch(e.target.value)}
          />
          <StyledDiv>
            <TableButtonContainer>
              <Button
                variant="outline"
                handleClick={handleDisplayCancel}
              >
                Cancel
              </Button>
            </TableButtonContainer>
            <TableButtonContainer>
              <Button
                asyncClick
                handleClick={(e, callback) => handleClickFunction(e, callback, record)}
              >
                Save
              </Button>
            </TableButtonContainer>
          </StyledDiv>
        </StyledColumnDiv>
      ) : (
        <StyledColumnDiv>
          <StyledFile>
            <button
              target="_blank"
              aria-label="View File"
              type="submit"
              onClick={() => viewFile(record.id)}
            >
              {displayValue}
            </button>
          </StyledFile>
          <div className="icon-group">
            <IconToggle
              id="EditDisplayName"
              aria-label="Edit Display Name"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleFileDisplay(record);
              }}
            >
              <Tooltip
                title="Edit Display Name"
                color={global.White}
              >
                <StyledIoPencilOutline />
              </Tooltip>
            </IconToggle>
            <Tooltip
              title="Download Single File"
              color={global.White}
            >
              <StyledDownload
                data-cy="Download"
                onClick={(e) => handleMultiDownload(e, record.id)}
              >
                <StyledIoDownloadOutline className="gray-icon" />
              </StyledDownload>
            </Tooltip>
            {is_admin && (
              <StyledTrashCan
                className="gray-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSingleFileDeleteId(record.id);
                  showInspectionFileDeleteModal();
                }}
              >
                <Tooltip
                  title="Delete Single File"
                  color={global.White}
                >
                  <StyledIoTrashOutline data-cy="Delete Modal" />
                </Tooltip>
              </StyledTrashCan>
            )}
          </div>
        </StyledColumnDiv>
      ))
    }
  ];

  const adminColumnsWithTagId = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '70%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (current_record?.rowId === record?.id ? (
        <div className="form-group">
          <StyledInput
            className="form-control"
            value={updated_display}
            onChange={(e) => setDisplayPatch(e.target.value)}
          />
        </div>
      ) : (
        <StyledFile>
          <button
            target="_blank"
            aria-label="View File"
            type="submit"
            onClick={() => viewFile(record.id)}
          >
            {value}
          </button>
        </StyledFile>
      ))
    },
    {
      title: 'Tag Id',
      dataIndex: 'tagNumber',
      key: 'tagNumber',
      width: '30%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (current_record?.rowId === record?.id ? (
        <StyledDiv>
          <TableButtonContainer>
            <Button
              variant="outline"
              handleClick={handleDisplayCancel}
            >
              Cancel
            </Button>
          </TableButtonContainer>
          <TableButtonContainer>
            <Button
              asyncClick
              handleClick={(e, callback) => handleClickFunction(e, callback, record)}
            >
              Save
            </Button>
          </TableButtonContainer>
        </StyledDiv>
      ) : (
        <IconWrapper>
          {record?.tagNumber || 'no tag id on record'}
          <div className="icon-group">
            <IconToggle
              id="EditDisplayNameTagId"
              aria-label="Edit Display Name"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleFileDisplay(record);
              }}
            >
              <Tooltip
                title="Edit Display Name"
                color={global.White}
              >
                <StyledIoPencilOutline />
              </Tooltip>
            </IconToggle>
            <Tooltip
              title="Download Single File"
              color={global.White}
            >
              <StyledDownload
                data-cy="Download"
                onClick={(e) => handleMultiDownload(e, record.id)}
              >
                <StyledIoDownloadOutline className="gray-icon" />
              </StyledDownload>
            </Tooltip>
            {is_admin && (
              <StyledTrashCan
                className="gray-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSingleFileDeleteId(record.id);
                  showInspectionFileDeleteModal();
                }}
              >
                <Tooltip
                  title="Delete Single File"
                  color={global.White}
                >
                  <StyledIoTrashOutline data-cy="Delete Modal" />
                </Tooltip>
              </StyledTrashCan>
            )}
          </div>
        </IconWrapper>
      ))
    }
  ];

  const adminColumnsWIthSensorType = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '70%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (current_record?.rowId === record?.id ? (
        <div className="form-group">
          <StyledInput
            className="form-control"
            value={updated_display}
            onChange={(e) => setDisplayPatch(e.target.value)}
          />
        </div>
      ) : (
        <StyledFile>
          <button
            target="_blank"
            aria-label="View File"
            type="submit"
            onClick={() => viewFile(record.id)}
          >
            {value}
          </button>
        </StyledFile>
      ))
    },
    {
      title: 'Sensor Type',
      dataIndex: 'sensorType',
      key: 'sensorType',
      width: '30%',
      ellipsis: true,
      sortable: true,
      render: (value, record) => (current_record?.rowId === record?.id ? (
        <StyledDiv>
          <TableButtonContainer>
            <Button
              variant="outline"
              handleClick={handleDisplayCancel}
            >
              Cancel
            </Button>
          </TableButtonContainer>
          <TableButtonContainer>
            <Button
              asyncClick
              handleClick={(e, callback) => handleClickFunction(e, callback, record)}
            >
              Save
            </Button>
          </TableButtonContainer>
        </StyledDiv>
      ) : (
        <IconWrapper>
          {record?.sensorType || 'no sensor type on record'}
          <div className="icon-group">
            <IconToggle
              aria-label="Edit Display Name"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleFileDisplay(record);
              }}
            >
              <Tooltip
                title="Edit Display Name"
                color={global.White}
              >
                <StyledIoPencilOutline />
              </Tooltip>
            </IconToggle>
            <Tooltip
              title="Download Single File"
              color={global.White}
            >
              <StyledDownload
                data-cy="Download"
                onClick={(e) => handleMultiDownload(e, record.id)}
              >
                <StyledIoDownloadOutline className="gray-icon" />
              </StyledDownload>
            </Tooltip>
            {is_admin && (
              <StyledTrashCan
                className="gray-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSingleFileDeleteId(record.id);
                  showInspectionFileDeleteModal();
                }}
              >
                <Tooltip
                  title="Delete Single File"
                  color={global.White}
                >
                  <StyledIoTrashOutline data-cy="Delete Modal" />
                </Tooltip>
              </StyledTrashCan>
            )}
          </div>
        </IconWrapper>
      ))
    }
  ];

  const items = [
    {
      key: '1',
      label: (
        <>
          <StyledIoDocumentTextOutline />
          <div>Reports</div>
        </>
      ),
      children: (() => {
        if (filesLoading) {
          return <FilesSkeleton justTable />;
        }
        return (
          <>
            {(reports?.length > 0 && (
              <StyledTableButtonDiv>
                <Tooltip
                  title="Download Selected Files"
                  color={global.White}
                >
                  <StyledDownload
                    data-cy="Download"
                    onClick={handleMultiDownload}
                  >
                    <StyledIoDownloadOutline className="gray-icon" />
                  </StyledDownload>
                </Tooltip>
                {is_admin && (
                  <StyledTrashCan
                    className="gray-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (
                        selectedRowKeys?.length > 0
                      ) {
                        showInspectionFileDeleteModal(
                          rowSelection.selectedRowKeys
                        );
                      }
                    }}
                  >
                    <Tooltip
                      title="Delete Selected Files"
                      color={global.White}
                    >
                      <StyledIoTrashOutline data-cy="Delete Modal" />
                    </Tooltip>
                  </StyledTrashCan>
                )}
              </StyledTableButtonDiv>
            ))}
            <Table
              columns={is_admin ? adminColumns : clientColumns}
              data={reports || ''}
              onRowSelectionChange={setSelectedRowKeys}
              checkbox
              fileSearch
              isUpload
              searchPlaceholder="Filter by display name"
            />
          </>
        );
      })()
    },
    {
      key: '2',
      label: (
        <>
          <StyledIoVideocamOutline />
          <div>Videos</div>
        </>
      ),
      children: (() => {
        if (filesLoading) {
          return <FilesSkeleton tableLocation="videos" justTable />;
        }
        return (
          <>
            {(videos?.length > 0 && (
              <BatchItems>
                <Tooltip
                  title="Download Selected Files"
                  color={global.White}
                >
                  <StyledDownload
                    onClick={handleMultiDownload}
                  >
                    <StyledIoDownloadOutline />
                  </StyledDownload>
                </Tooltip>
                {is_admin && (
                  <StyledTrashCan
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      showInspectionFileDeleteModal(
                        rowSelection.selectedRowKeys
                      );
                    }}
                  >
                    <Tooltip
                      title="Delete Selected Files"
                      color={global.White}
                    >
                      <StyledIoTrashOutline />
                    </Tooltip>
                  </StyledTrashCan>
                )}
              </BatchItems>
            ))}
            <Table
              columns={
                is_admin
                  ? adminColumnsWIthSensorType
                  : clientColumnsWithSensorType
              }
              data={videos || ''}
              onRowSelectionChange={setSelectedRowKeys}
              checkbox
              fileSearch
              isUpload
            />
          </>
        );
      })()
    },
    ...(isStep2FormActive
      ? [
        {
          key: '3',
          label: (
            <>
              <StyledIoLocateOutline />
              <div>Leaks</div>
            </>
          ),
          children: (() => {
            if (filesLoading) {
              return (
                <FilesSkeleton
                  tableLocation="leaks"
                  justTable
                />
              );
            }
            return (
              <>
                {(leaks?.length > 0 && (
                  <BatchItems>
                    <Tooltip
                      title="Download Selected Files"
                      color={global.White}
                    >
                      <StyledDownload
                        onClick={
                          handleMultiDownload
                        }
                      >
                        <StyledIoDownloadOutline />
                      </StyledDownload>
                    </Tooltip>
                    {is_admin && (
                      <StyledTrashCan
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          showInspectionFileDeleteModal(
                            rowSelection.selectedRowKeys
                          );
                        }}
                      >
                        <Tooltip
                          title="Delete Selected Files"
                          color={global.White}
                        >
                          <StyledIoTrashOutline />
                        </Tooltip>
                      </StyledTrashCan>
                    )}
                  </BatchItems>
                ))}
                <Table
                  columns={
                    is_admin
                      ? adminColumnsWithTagId
                      : clientColumnsWithTagId
                  }
                  data={leaks || ''}
                  onRowSelectionChange={setSelectedRowKeys}
                  checkbox
                  fileSearch
                  isUpload
                />
              </>
            );
          })()
        }
      ]
      : [])
  ];

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      })();
    }
    if (inspId) {
      fetchFiles();
    }
    if (inspectionsReloadIsPending) {
      refreshFiles();
    }
  }, [isAuthenticated, getAccessTokenSilently, inspectionsReloadIsPending]);

  const renderModals = () => (
    <>
      <FileUploadModal
        isVisible={isFileUploadModalVisible}
        inspection={selectedInspection}
        onCancel={handleFileUploadModalCancel}
        onOk={handleFileUploadModalOk}
      />
      <FileDeleteModal
        isVisible={isInspectionFileDeleteModalVisible}
        file={selectedInspectionFile}
        onCancel={handleInspectionFileDeleteModalCancel}
        onOk={handleInspectionFileDeleteModalOk}
      />
    </>
  );

  const handleTabChange = (tabKey) => {
    setTypeTab(tabKey);
  };

  return (
    <StyledSection>
      {renderModals()}
      <HeaderWrapper>
        <div>
          <Header>
            {flogistix_id}
            {is_admin && (
              <Tooltip title="Attach Files" color={global.White}>
                <StyledPaperClip onClick={handleFileUpload}>
                  <StyledBsPaperclip />
                </StyledPaperClip>
              </Tooltip>
            )}
          </Header>
          <Status />
        </div>

        <Breadcrumbs>
          <InspectionBreadcrumb
            id="InspectionsBreadcrumbFiles"
            onClick={onInspectionBreadcrumbClick}
            aria-label="Inspections"
          >
            Inspections
          </InspectionBreadcrumb>
          <IoChevronForward className="breadcrumb-chevron" />
          <Breadcrumb
            id="OrgBreadcrumbFiles"
            onClick={onOrgBreadcrumbClick}
            aria-label="Orgs"
          >
            {user_organization.orgName}
          </Breadcrumb>
          <IoChevronForward className="breadcrumb-chevron" />
          <Breadcrumb
            id="SiteBreadcrumbFiles"
            onClick={OnOrgLocationBreadcrumbClick}
            aria-label="Sites"
          >
            {user_site.siteName}
          </Breadcrumb>
          <>
            <IoChevronForward className="breadcrumb-chevron" />
            <InspectionID id="FlogistixId">
              {flogistix_id}
            </InspectionID>
          </>
        </Breadcrumbs>
      </HeaderWrapper>
      <FilterTableTabs
        activeKey={typeTab}
        onChange={handleTabChange}
        items={items}
      />
    </StyledSection>
  );
};

export default FilteredFilesTable;
