import React, { useContext, useEffect } from 'react';
import { IoAddOutline } from 'react-icons/io5';
import { v4 as uuid } from 'uuid';

import './MonitoredComponentsSection.scss';
import { ModifyInspectionsContext } from '../../../../context/ModifyInspectionsContext';
import { emptyBaseMonitoredComponent } from '../../../../shared/constants';
import MonitoredComponentCard from './MonitoredComponentCard';

const MonitoredComponentsSection = () => {
  const {
    monitoredComponents,
    setMonitoredComponents
  } = useContext(ModifyInspectionsContext);

  const addNewMonitoredComponent = () => {
    setMonitoredComponents([...monitoredComponents, {
      localId: uuid(),
      ...(monitoredComponents.at(0)?.inspectionId ? {
        inspectionId: monitoredComponents.at(0)?.inspectionId
      } : {}),
      ...emptyBaseMonitoredComponent
    }]);
  };

  useEffect(() => {
    if (monitoredComponents.length === 0) addNewMonitoredComponent();
  }, []);

  return (
    <section>
      <div className="subheading-container">
        <h2 className="subheading">Page 2 of 2</h2>
        <button
          type="button"
          className="new-component-btn"
          onClick={addNewMonitoredComponent}
        >
          <IoAddOutline size={16} />
          {' '}
          Add new component
        </button>
      </div>
      <div className="monitored-components-container">
        {
          monitoredComponents.map((monitoredComponent) => (
            <MonitoredComponentCard
              monitoredComponent={monitoredComponent}
            />
          ))
        }
        {
          monitoredComponents.length > 0 && (
            <button
              type="button"
              className="new-component-btn"
              onClick={addNewMonitoredComponent}
            >
              <IoAddOutline size={16} />
              {' '}
              Add new component
            </button>
          )
        }
      </div>
    </section>
  );
};

export default MonitoredComponentsSection;
