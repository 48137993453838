import React, { useContext } from 'react';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import FooterNav from '../../components/FooterNav';
import Back from '../../components/Back';
import Next from '../../components/Next';
import Spacer from '../../components/Spacer';
import MonitoredComponentsSection from './MonitoredComponentsSection';
import ToggleSwitch from '../../components/ToggleSwitch';
import { ModifyInspectionsContext } from '../../../../context/ModifyInspectionsContext';
import {
  Container,
  Label,
  SectionLabel,
  StyledInput
} from '../../components/InspectionSummary/StyledSummaryComponents.tsx';
import LeakSummaryCard from '../../components/InspectionSummary/LeakSummaryCard.tsx';

const InspectionSummary = () => {
  const {
    leaks,
    setActiveTab,
    fields,
    leakSummarySubNav,
    setLeakSummarySubNav,
    setIsDeviations,
    isDeviations,
    setFields
  } = useContext(ModifyInspectionsContext);

  const handleSubNavNext = () => setLeakSummarySubNav(2);
  const handleSubNavBack = () => setLeakSummarySubNav(1);
  const handleBack = () => setActiveTab('leak-details');
  const handleNext = () => setActiveTab('final-submission');

  const leaksWithRepairs = leaks.filter((leak) => leak.leakRepairs.length > 0);
  const leaksWithoutRepairs = leaks.filter(
    (leak) => leak.leakRepairs.length === 0
  );

  const handleInputChange = (e, fieldName) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: e.target.value
    }));
  };

  return leakSummarySubNav === 1 ? (
    <>
      <Container>
        <Header>Inspection summary</Header>
        <SubHeader>Part 1 of 2</SubHeader>
        {
          leaks.length === 0 && (
            <LeakSummaryCard
              leaks={[]}
            />
          )
        }
        {leaksWithRepairs.length > 0 && (
          <LeakSummaryCard
            leaks={leaksWithRepairs}
            leakStatus="REPAIRED"
          />
        )}
        {leaksWithoutRepairs.length > 0 && (
          <LeakSummaryCard
            leaks={leaksWithoutRepairs}
            leakStatus="NOT REPAIRED"
          />
        )}
      </Container>
      <FooterNav>
        <Back type="submit" onClick={handleBack}>
          Back
        </Back>
        <Next type="submit" onClick={handleSubNavNext}>
          Next
        </Next>
      </FooterNav>
    </>
  ) : (
    <>
      <Container>
        <Header>Inspection summary</Header>
        <MonitoredComponentsSection />
        <Label>Any deviations from the monitoring plan?</Label>
        <ToggleSwitch
          type={isDeviations}
          setType={setIsDeviations}
          text1="No"
          text2="Yes"
        />
        {isDeviations === 'Yes' && (
          <>
            <SectionLabel>
              What is the reason for the deviation?
            </SectionLabel>
            <StyledInput
              value={fields?.deviation}
              onChange={(e) => handleInputChange(e, 'deviation')}
              placeholder="Deviation reason..."
            />
          </>
        )}
        <Spacer />
      </Container>
      <FooterNav>
        <Back type="submit" onClick={handleSubNavBack}>
          Back
        </Back>
        <Next type="submit" onClick={handleNext}>
          Next
        </Next>
      </FooterNav>
    </>
  );
};

export default InspectionSummary;
