import styled from 'styled-components';

import { global } from '../../../shared/colors';

const FooterNav = styled.section`
    width: 90%;
    height: 123px;
    border-radius: 10px;
    border: 1px solid ${global.Gray5};
    align-items: center;
    padding: 0 60px 0 60px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    background: ${global.White};
    bottom: 18px;
    width: 71%;
    max-width: 960px;
    z-index: 999;

    @media (max-width: 1000px) {
        width: 60%;
    }
`;

export default FooterNav;
