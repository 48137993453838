import { inspectionSections, requiredFieldsBySection } from '../../shared/constants';

export const determineSectionCompletion = (section, sectionData) => {
  const requiredFields = requiredFieldsBySection[section];
  for (let i = 0; i < requiredFields.length; i += 1) {
    const field = requiredFields[i];
    if (!sectionData[field]) return false;
  }
  return true;
};

export const getSectionPercentage = (section, sectionData) => {
  const requiredFields = requiredFieldsBySection[section];
  const completedSections = requiredFields
    .map((requiredField) => (sectionData[requiredField] ? requiredField : null))
    .filter((field) => field !== null);
  return (completedSections.length / requiredFields.length) * 100;
};

export const determineInspectionDetailsCompletion = ({ fields }) => {
  const {
    facilitySign, verifications = [], siteConditions, surveys = []
  } = fields;
  const infoCompletion = getSectionPercentage(inspectionSections.INFO, fields);
  const verificationsCompletion = verifications.length
    ? verifications.reduce(
      (verificationPercentage, verification) => verificationPercentage
          + getSectionPercentage(inspectionSections.VERIFICATION, {
            ...verification,
            facilitySign: facilitySign?.at(0)
          }),
      0
    ) / verifications.length
    : 0;
  const weatherCompletion = getSectionPercentage(
    inspectionSections.WEATHER_CONDITIONS,
    siteConditions ?? {}
  );
  const surveyCompletion = surveys.length
    ? surveys.reduce((surveyPercentage, survey) => {
      const modifiedSurvey = {
        ...survey,
        files: survey.files.length > 0 ? survey.files : null
      };
      return (
        surveyPercentage
          + getSectionPercentage(inspectionSections.SURVEY_DETAILS, modifiedSurvey)
      );
    }, 0) / surveys.length
    : 100;

  return (
    (infoCompletion + verificationsCompletion + weatherCompletion + surveyCompletion)
    / 4
  );
};

export const determineLeakDetailsCompletion = ({ leaks, isLeaks, leakRepair }) => {
  const leaksCompletion = isLeaks === 'Yes'
    ? leaks.reduce(
      (leakPercentage, leak) => leakPercentage
            + getSectionPercentage(inspectionSections.LEAK_DETAILS, leak),
      0
    ) / leaks.length
    : 100;
  const repairsCompletion = leakRepair.length > 0
    ? leakRepair.reduce(
      (repairPercentage, repair) => repairPercentage
            + getSectionPercentage(inspectionSections.LEAK_REPAIRS, repair),
      0
    ) / leakRepair.length
    : 100;
  return (leaksCompletion + repairsCompletion) / 2;
};

export const determineAllCompletionValues = ({ fields, isLeaks, leakRepair }) => {
  const { leaks } = fields;
  const siteDetailsCompletion = getSectionPercentage(inspectionSections.SITE_DETAILS, {
    ...fields,
    customerLocation: fields.customerLocation?.customerLocationId,
    customer: fields.customer?.customerId
  });
  const inspectionDetailsCompletion = determineInspectionDetailsCompletion({
    fields
  });
  const leakDetailsCompletion = determineLeakDetailsCompletion({
    leaks,
    isLeaks,
    leakRepair
  });
  return {
    siteDetails: siteDetailsCompletion,
    inspectionDetails: inspectionDetailsCompletion,
    leakDetails: leakDetailsCompletion
  };
};
