import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import Select from 'react-select';

import DropdownIndicator from '../../../../components/Select/DropdownIndicator';
import customStyles from '../../components/customStyles/customStyles';
import FooterNav from '../../components/FooterNav';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import ClearButton from '../../components/ClearButton';
import Spacer from '../../components/Spacer';
import Next from '../../components/Next';
import Back from '../../components/Back';
import { global } from '../../../../shared/colors';
import useGetData from '../../../../hooks/useGetData';
import { GlobalContext } from '../../../../context/GlobalContext';
import {
  getSkyConditions,
  getWindDirections
} from '../../../../services/airmethaneApi';
import { handleDataLoading } from '../../../../shared/utils';
import { ModifyInspectionsContext } from '../../../../context/ModifyInspectionsContext';
import ClearIndicator from '../../../../components/Select/ClearIndicator';

const sharedMargins = `
    margin-top: 8px;
    margin-bottom: 1em;
`;

const sharedDimensions = `
    width: 100%;
    height: 51px;
    border-radius: 10px;
`;

const sharedFont = `
    font-size: 16px;
    font-weight: 600;
`;

const StyledInput = styled(Input)`
    ${sharedDimensions}
    ${sharedMargins}
    min-width: 200px;
    font-size: 16px;
    padding: 16px;
    cursor: pointer;
    color: ${global.Black};
    border: 1px solid ${global.Gray5};

    &::placeholder {
        text-align: right;
    }

    /* Hide number input spinners */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield;
    }
`;

const Container = styled.section`
    width: 60%;
    height: 90%;
    margin-bottom: 60px;
`;

const Label = styled.label`
    color: ${global.Gray3};
    ${sharedFont}
`;

const SiteDetails = () => {
  const [inputAlign, setInputAlign] = useState('right');
  const [skyOptions, setSkyOptions] = useState([]);
  const [windOptions, setWindOptions] = useState([]);
  const { token } = useContext(GlobalContext);
  const {
    data: skyConditions,
    error: skyConditionsError,
    isLoading: isSkyConditionsLoading
  } = useGetData(token, 'skyConditions', getSkyConditions);
  const {
    data: windDirections,
    error: windDirectionsError,
    isLoading: isWindDirectionsLoading
  } = useGetData(token, 'windDirections', getWindDirections);
  const { fields, setFields, setActiveSubTab } = useContext(
    ModifyInspectionsContext
  );

  const handleBack = () => {
    setActiveSubTab('verification');
  };

  const handleNext = () => {
    setActiveSubTab('surveys');
  };

  const handleInputFocus = () => {
    setInputAlign('left');
  };

  const handleInputBlur = (e) => {
    if (e.target.value === '') setInputAlign('right');
  };

  const handleWeatherChange = (e, fieldName, type) => {
    const value = type === 'number' ? parseFloat(e.target.value) : e.target.value;
    setFields((prevFields) => ({
      ...prevFields,
      siteConditions: {
        ...prevFields.siteConditions,
        [fieldName]: value.toString()
      }
    }));
  };

  const handleSelectChange = (selectedOption, fieldName) => {
    setFields((prevFields) => ({
      ...prevFields,
      siteConditions: {
        ...prevFields.siteConditions,
        [fieldName]: selectedOption ? selectedOption.label : ''
      }
    }));
  };

  const clearAll = () => {
    setFields((prevFields) => ({
      ...prevFields,
      siteConditions: {
        maxWindSpeed: '',
        windDirections: ''
      },
      ambientTemperature: '',
      barometricPressure: '',
      humidityPercent: '',
      skyConditions: null
    }));
  };

  const inputConfigs = [
    {
      key: 1,
      label: 'Temperature',
      placeholder: '°Fahrenheit (F)',
      type: 'number',
      fieldKey: 'ambientTemperature'
    },
    {
      key: 2,
      label: 'Barometric pressure',
      placeholder: 'pounds per square inch (psi)',
      type: 'number',
      fieldKey: 'barometricPressure'
    },
    {
      key: 3,
      label: 'Humidity',
      type: 'number',
      fieldKey: 'humidityPercent'
    }
  ];

  const renderInput = (config) => (
    <>
      <Label>{config.label}</Label>
      <StyledInput
        placeholder={config.placeholder}
        type={config.type || 'text'}
        inputAlign={inputAlign}
        value={fields?.siteConditions?.[config.fieldKey] || ''}
        onChange={(e) => handleWeatherChange(e, config.fieldKey, config.type)}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
    </>
  );

  useEffect(() => {
    handleDataLoading(
      skyConditions?.enumerations?.skyConditions,
      isSkyConditionsLoading,
      skyConditionsError,
      setSkyOptions,
      'skyConditions'
    );
    handleDataLoading(
      windDirections?.enumerations?.windDirection,
      isWindDirectionsLoading,
      windDirectionsError,
      setWindOptions,
      'windDirections'
    );
  }, [
    skyConditions,
    isSkyConditionsLoading,
    skyConditionsError,
    windDirections,
    isWindDirectionsLoading,
    windDirectionsError
  ]);

  return (
    <>
      <Container>
        <Header>Inspection details</Header>
        <SubHeader>Part 3 of 4: Weather Conditions</SubHeader>
        <Label>Max wind speed</Label>
        <StyledInput
          placeholder="miles per hour (mph)"
          type="number"
          inputAlign={inputAlign}
          value={fields?.siteConditions?.maxWindSpeed || ''}
          onChange={(e) => handleWeatherChange(e, 'maxWindSpeed', 'number')}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <Label>Wind direction</Label>
        <Select
          classNamePrefix="detail-select"
          id="windDirections"
          isClearable
          styles={customStyles}
          className="detail-select"
          value={
            windOptions.find(
              (option) => option.label
                                    === fields?.siteConditions?.windDirection
                                || ''
            ) || []
          }
          onChange={(selectedOption) => handleSelectChange(selectedOption, 'windDirection')}
          options={windOptions || []}
          placeholder="Select wind direction"
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator,
            DropdownIndicator
          }}
        />

        {inputConfigs.map((config) => renderInput(config))}
        <Label>Sky conditions</Label>
        <Select
          id="skyConditions"
          isClearable
          styles={customStyles}
          className="detail-select"
          classNamePrefix="detail-select"
          value={
            skyOptions.find(
              (option) => option.label
                                === fields?.siteConditions?.skyConditions
            ) || null
          }
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(selectedOption) => handleSelectChange(selectedOption, 'skyConditions')}
          options={skyOptions || []}
          placeholder="Select sky conditions"
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator,
            DropdownIndicator
          }}
        />
        <ClearButton onClear={clearAll} />
        <Spacer />
      </Container>
      <FooterNav>
        <Back type="submit" onClick={handleBack}>
          Back
        </Back>
        <Next type="submit" onClick={handleNext}>
          Next
        </Next>
      </FooterNav>
    </>
  );
};

export default SiteDetails;
