import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import { Input } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';

import { global } from '../../../../shared/colors';
import customStyles from '../../components/customStyles/customStyles';
import DropdownIndicator from '../../../../components/Select/DropdownIndicator';
import ImageDropzone from './ImageDropzone';
import { ModifyInspectionsContext } from '../../../../context/ModifyInspectionsContext';
import Datepicker from '../../components/Datepicker/Datepicker';
import ToggleCheck from '../../components/ToggleCheck';
import ClearIndicator from '../../../../components/Select/ClearIndicator';

const flexCenter = `
    display: flex;
    justify-content: center;
    align-items: center;
`;

const clickable = `
    cursor: pointer;
    &:hover {
        opacity: 65%;
    }
`;

const noBackgroundNoBorder = `
    background: none;
    border: none;
`;

const Form = styled.form`
    ${flexCenter}
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    background: ${global.White};
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 20px 20px 0 20px;
    margin-bottom: 20px;
    height: ${(props) => (props.isExpanded ? 'auto' : 'fit-content')};
`;

const RepairsHeader = styled.h2`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
    color: ${global.PrimaryBlue};
    font-size: 16px;
    font-weight: 600;
    align-items: center;
`;

const ExpandableButton = styled.button`
    ${noBackgroundNoBorder}
    ${clickable}
    font-size: 24px;
`;

const FieldsDiv = styled.div`
    width: 100%;
`;

const Label = styled.label`
    color: ${global.Gray2};
    font-size: 12px;
    font-weight: 600;
`;

const SubLabel = styled.div`
    color: ${global.Gray4};
    font-size: 12px;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 8px;
`;

const StyledInput = styled(Input)`
    height: 51px;
    width: 100%;
    padding: 16px 20px;
    min-width: 100px;
    margin-top: 8px;
    margin-bottom: 32px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;

    ${(props) => props.id === 'right'
        && `
        padding-left: 34px;
    `}

    ${(props) => props.id === 'left'
        && `
        padding-left: 20px;
    `}

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::placeholder {
        color: ${global.Gray4};
    }

    & {
        -moz-appearance: textfield;
    }
`;

const StyledSelect = styled(Select)`
    padding-bottom: 12px;
`;

const ImageDropzoneWrapper = styled.div`
    margin-bottom: 32px;
    text-align: center;
`;

const ToggleCheckWrapper = styled.div`
    margin-top: 40px;
    margin-bottom: 32px;

    label:last-child {
        margin-left: 12px;
    }
`;

const FileContainer = styled.div`
    ${flexCenter}
    flex: 1;
    flex-direction: column;
    border-width: 1px;
    border-radius: 10px;
    border-color: ${global.Gray5};
    border-style: dashed;
    background-color: ${global.White};
    color: ${global.Gray5};
    outline: none;
    transition: border 0.24s ease-in-out;
    width: 100%;
    cursor: pointer;
    height: 329px;
`;

const Remove = styled.button`
    color: ${global.ErrorRed};
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${global.ErrorRed};
    text-decoration-thickness: 1px;
    font-weight: 600;
    margin-top: 8px;

    &:hover {
        text-decoration-thickness: 2px;
    }
`;

// eslint-disable-next-line max-len
const findSelectedLeakOption = (leakId, options) => options.find((option) => (option?.id || option?.localId
  ? (option?.id || option?.localId) === leakId
  : option.value === leakId)) || null;

const RepairForm = ({ formIndex }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLeakRepaired, setIsLeakRepaired] = useState(null);

  const {
    leakIDOptions, leakRepair, setLeakRepair, updateLeakWithRepair
  } = useContext(ModifyInspectionsContext);

  const handleLeakRepairUpdate = (updatedRepair, formIndx) => {
    setLeakRepair((prevRepairs) => {
      const newRepairs = [...prevRepairs];
      newRepairs[formIndx] = updatedRepair;
      return newRepairs;
    });

    const leakId = updatedRepair?.leakId;
    updateLeakWithRepair(leakId, updatedRepair);
  };

  const handleToggleChange = () => {
    setIsLeakRepaired((prev) => {
      const updatedRepairs = [...leakRepair];
      if (!updatedRepairs[formIndex]) {
        updatedRepairs[formIndex] = {};
      }
      updatedRepairs[formIndex].repaired = !prev;

      handleLeakRepairUpdate(updatedRepairs);
      return !prev;
    });
  };

  const generateUniqueIdentifier = () => uuidv4();

  const handleFileChange = (file) => {
    if (!file) return;

    const uniqueFileId = generateUniqueIdentifier();
    const previewUrl = URL.createObjectURL(file);

    const updatedRepairs = [...leakRepair];
    if (!updatedRepairs[formIndex]) {
      updatedRepairs[formIndex] = {};
    }

    updatedRepairs[formIndex].file = {
      name: file.name,
      size: file.size,
      type: file.type,
      id: uniqueFileId,
      preview: previewUrl
    };

    setLeakRepair(updatedRepairs);
    updateLeakWithRepair(
      leakRepair[formIndex]?.leakId,
      updatedRepairs[formIndex]
    );
  };

  const removeSelectedFile = () => {
    if (selectedFile && selectedFile.preview) {
      URL.revokeObjectURL(selectedFile.preview);
    }
    setSelectedFile(null);
    const updatedRepairs = leakRepair.map((repair, index) => {
      if (index === formIndex) {
        return { ...repair, file: null };
      }
      return repair;
    });
    setLeakRepair(updatedRepairs);
    if (updateLeakWithRepair && updatedRepairs[formIndex]) {
      updateLeakWithRepair(
        updatedRepairs[formIndex].leakId,
        updatedRepairs[formIndex]
      );
    }
  };

  const handleInputChange = (value, fieldName) => {
    const updatedFormValues = [...leakRepair];
    updatedFormValues[formIndex][fieldName] = value;
    setLeakRepair(updatedFormValues);
    updateLeakWithRepair(
      leakRepair[formIndex]?.leakId,
      updatedFormValues[formIndex]
    );
  };

  const handleTextInputChange = (e, fieldName) => {
    const { value } = e.target;
    const updatedFormValues = [...leakRepair];
    if (updatedFormValues[formIndex]) {
      updatedFormValues[formIndex][fieldName] = value;
    } else {
      updatedFormValues[formIndex] = { [fieldName]: value };
    }
    setLeakRepair(updatedFormValues);
    updateLeakWithRepair(
      leakRepair[formIndex].leakId,
      updatedFormValues[formIndex]
    );
  };

  const handleDateChange = (e) => {
    const updatedRepairs = [...leakRepair];
    updatedRepairs[formIndex].date = e;
    setLeakRepair(updatedRepairs);
    updateLeakWithRepair(
      leakRepair[formIndex].leakId,
      updatedRepairs[formIndex]
    );
  };
  const leakRepairs = leakRepair[formIndex] || {};

  useEffect(() => {
    const cleanup = () => {
      if (selectedFile && selectedFile.preview) {
        URL.revokeObjectURL(selectedFile.preview);
      }
    };

    return cleanup;
  }, [selectedFile]);

  useEffect(() => {
    const currentLeakRepair = leakRepair[formIndex];
    if (
      currentLeakRepair
            && typeof currentLeakRepair.repaired === 'boolean'
    ) {
      setIsLeakRepaired(currentLeakRepair.repaired);
    }
  }, [formIndex, leakRepair]);

  return (
    <Form isExpanded={isExpanded}>
      <RepairsHeader>
        {`Repair ${formIndex + 1}`}
        <ExpandableButton
          type="button"
          className="survey-toggle-button"
          onClick={() => setIsExpanded(!isExpanded)}
          tabIndex={0}
        >
          {isExpanded ? (
            <IoChevronUpOutline style={{ color: global.Gray4 }} />
          ) : (
            <IoChevronDownOutline style={{ color: global.Gray4 }} />
          )}
        </ExpandableButton>
      </RepairsHeader>

      {isExpanded && (
        <FieldsDiv>
          <Label>Leak ID / Tag</Label>
          <StyledSelect
            autosize
            isClearable
            styles={customStyles}
            className="detail-select leakID"
            classNamePrefix="detail-select"
            value={findSelectedLeakOption(
              leakRepairs?.leakId,
              leakIDOptions
            )}
            options={leakIDOptions}
            placeholder="Select a leak ID"
            onChange={(option) => handleInputChange(
              option?.id || option?.localId,
              'leakId'
            )}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator,
              DropdownIndicator
            }}
          />
          <Label>Completion date</Label>
          <Datepicker
            type="date"
            selected={leakRepair[formIndex].date || ''}
            onChange={handleDateChange}
          />
          <Label>OGI image of repair</Label>
          <SubLabel>Files should be .jpg, .png, or .heic</SubLabel>
          <ImageDropzoneWrapper>
            <ImageDropzone
              onFileChange={handleFileChange}
              filePreview={leakRepairs?.file?.preview}
            />
            {leakRepairs.file && leakRepairs.file.preview && (
              <>
                <FileContainer>
                  <img
                    src={leakRepairs.file.preview}
                    alt="Preview"
                    style={{
                      width: 'auto',
                      maxWidth: '100%',
                      height: 'auto',
                      borderRadius: '10px',
                      overflow: 'auto'
                    }}
                  />
                </FileContainer>
                <Remove
                  onClick={removeSelectedFile}
                  type="button"
                >
                  Remove Image
                </Remove>
              </>
            )}
          </ImageDropzoneWrapper>
          <ToggleCheckWrapper>
            <ToggleCheck
              isOn={isLeakRepaired}
              handleToggle={handleToggleChange}
            />
            <Label>Leak was fully repaired</Label>
          </ToggleCheckWrapper>
          <Label>Repaired by (optional)</Label>
          <StyledInput
            placeholder="John Smith"
            type="text"
            value={leakRepairs?.repairedBy || ''}
            onChange={(e) => handleTextInputChange(e, 'repairedBy')}
          />
          <Label>Repaired method (optional)</Label>
          <StyledInput
            placeholder="How was it repaired?"
            type="text"
            value={leakRepairs?.method || ''}
            onChange={(e) => handleTextInputChange(e, 'method')}
          />
          <Label>Additional repair notes (optional)</Label>
          <StyledInput
            placeholder="Anything else you would like to add?"
            type="text"
            value={leakRepairs?.notes || ''}
            onChange={(e) => handleTextInputChange(e, 'notes')}
          />
        </FieldsDiv>
      )}
    </Form>
  );
};

export default RepairForm;
