import getIAM from './getIAM';

const getGroup = async (token, setHasAccess, setGroup, triggerGlobalNotification) => {
  try {
    const group = await getIAM(
      '/iam/groups?groupId=groups-air-methane-*',
      token
    );
    if (group.status === 'Not Found') {
      setHasAccess(false);
    }
    setGroup(group);
  } catch (err) {
    triggerGlobalNotification(
      'error',
      'Failed to fetch groups'
    );
  }
};

export default getGroup;
