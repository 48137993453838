import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { global } from '../../../shared/colors';

const ToggleLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
`;

const ToggleInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`;

const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${global.Gray5};
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
        position: absolute;
        content: '';
        height: 16px;
        width: 16px;
        left: 2px;
        top: 2px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
    }

    ${ToggleInput}:checked + & {
        background-color: ${global.PrimaryBlue};
    }

    ${ToggleInput}:focus + & {
        box-shadow: 0 0 1px ${global.PrimaryBlue};
    }

    ${ToggleInput}:checked + &:before {
        transform: translateX(16px);
    }

    border-radius: 20px;

    &:before {
        border-radius: 50%;
    }
`;

const ToggleCheck = ({ isOn, handleToggle }) => (
  <ToggleLabel>
    <ToggleInput type="checkbox" checked={isOn} onChange={handleToggle} />
    <Slider />
  </ToggleLabel>
);

ToggleCheck.propTypes = {
  isOn: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired
};

export default ToggleCheck;
