/* eslint-disable no-unused-vars */

import React, { useState, useContext, useEffect } from 'react';
import { HiOutlinePaperClip, HiOutlineTrash } from 'react-icons/hi';
import { RxDownload } from 'react-icons/rx';
import { useAuth0 } from '@auth0/auth0-react';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Tooltip, notification } from 'antd';
import { IoChevronForward } from 'react-icons/io5';
import styled from 'styled-components';

import { RiAddFill } from 'react-icons/ri';
import { Table } from '../../components';
import IconToggle from '../../components/IconToggle';
import { addKeys } from '../../shared/utils';
import { GlobalContext } from '../../context/GlobalContext';
import useFiles from '../../services/useFiles';
import { API_URL } from '../../shared/url';
import FileUploadModal from '../../components/Modals/FileUploadModal';
import InspectionDeleteModal from '../../components/Modals/InspectionDeleteModal';
import DatabooksModal from '../../components/Modals/DatabooksModal';
import { global } from '../../shared/colors';
import deleteInspection from '../../services/deleteInspection';
import sendDatabookRequest from '../../services/sendDatabookRequest';
import Status from '../../components/Status/Status';
import {
  Header,
  Breadcrumbs,
  Breadcrumb,
  StyledSpan
} from '../../shared/styled-components';
import {
  IconWrapper,
  HeaderWrapper,
  InspectionBreadcrumb
} from './styled-components';

import './InspectionTable.css';

const timeZone = 'UTC';

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 10px;
    background: ${global.PrimaryBlue};
    width: 205px;
    height: 41px;
    color: ${global.White};
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
        opacity: 60%;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
    margin: 0 60px 5px 0;
`;

const HeaderSection = styled.section`
    display: flex;
    width: 100%;
`;

const columns = [
  {
    title: 'Identifier',
    dataIndex: 'flogistixId',
    key: 'flogistixId',
    width: '40%',
    sortable: true
  },
  {
    title: 'Created By',
    dataIndex: 'createdByName',
    key: 'createdByName',
    width: '20%',
    sortable: true
  },
  {
    title: 'File Count',
    dataIndex: 'filesCount',
    key: 'filesCount',
    width: '20%',
    sortable: true
  },
  {
    title: 'Inspection Date',
    dataIndex: 'inspectionDate',
    key: 'inspectionDate',
    render: (inspectionDate) => {
      const dateAsUTC = utcToZonedTime(
        new Date(inspectionDate),
        timeZone
      );
      return format(dateAsUTC, 'MMM dd, yyyy', { timeZone });
    },
    width: '20%',
    sortable: true
  }
];

const InspectionTable = ({
  inspections,
  user_organization,
  user_site,
  onOrgBreadcrumbClick,
  onInspectionBreadcrumbClick,
  history
}) => {
  const [selectedInspection, setSelectedInspection] = useState({});
  const [expandedInspectionId, setExpandedInspectionId] = useState(null);
  const [isFileUploadModalVisible, setIsFileUploadModalVisible] = useState(false);
  const [isDeleteInspectionModalVisible, setIsDeleteInspectionModalVisible] = useState(false);
  const [isDatabooksModalVisible, setIsDatabooksModalVisible] = useState(false);
  const [inspectionRowUpdateIsPending, setInspectionRowUpdateIsPending] = useState(false);
  const [token, setToken] = useState('');
  const orgSiteUrlSegment = `orgs/${user_organization?.orgId}/sites/${user_site?.siteId}`;
  const {
    is_admin,
    inspectionsReloadIsPending,
    getAndSetInspections,
    setInspId,
    setSelectInspection,
    setSideNavVisible
  } = useContext(GlobalContext);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [filesLoading, fetchFiles] = useFiles(
    `/files/${orgSiteUrlSegment}?inspectionId=${expandedInspectionId}`
  );

  const handleRows = (record) => {
    try {
      if (
        !record
        || !record.id
        || !record.customerId
        || !record.customerLocationId
        || !record.flogistixId
      ) {
        throw new Error('Invalid record or missing properties');
      }

      setInspId(record.id);
      setSelectInspection(record);
      history(
        `/orgs/${record.customerId}/inspections/${record.customerLocationId}/files/${record.flogistixId}/${record.id}`
      );
    } catch (error) {
      console.error('Error handling rows:', error.message);
      notification.error({
        message: 'An error occurred while handling rows',
        description: error.message,
        key: 'handle_rows_error',
        duration: 8
      });
    }
  };

  const setNewSelectedInspection = (inspection) => {
    if (inspection?.id !== selectedInspection?.id) {
      setSelectedInspection(inspection);
    }
  };

  const refreshFiles = async () => {
    if (!expandedInspectionId) {
      return;
    }

    await fetchFiles();
    setInspectionRowUpdateIsPending(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      })();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const handleDelete = async (inspectionId) => {
    if (inspectionId === expandedInspectionId) {
      setExpandedInspectionId(null);
    }

    setInspectionRowUpdateIsPending(true);

    try {
      await deleteInspection(
        `${API_URL}/inspections/${orgSiteUrlSegment}?inspectionId=${inspectionId}`,
        token
      );
      await getAndSetInspections();

      setInspectionRowUpdateIsPending(false);
    } catch (err) {
      console.log(err);
    }
  };

  const showFileUploadModal = (r) => {
    setNewSelectedInspection(r);
    setIsFileUploadModalVisible(true);
  };

  const handleFileUploadModalOk = () => {
    setIsFileUploadModalVisible(false);
  };

  const handleFileUploadModalCancel = () => {
    setIsFileUploadModalVisible(false);
  };

  const showDeleteInspectionModal = (record) => {
    setNewSelectedInspection(record);
    setIsDeleteInspectionModalVisible(true);
  };

  const handleDeleteInspectionModalCancel = () => {
    setIsDeleteInspectionModalVisible(false);
  };

  const handleDeleteInspectionModalOk = async (record, callback) => {
    await handleDelete(record);
    callback();
    setIsDeleteInspectionModalVisible(false);
  };

  const handleDatabooksModalCancel = () => {
    setIsDatabooksModalVisible(false);
  };

  const handleDatabookClick = async (inspection) => {
    await sendDatabookRequest(
      inspection,
      token,
      user_organization,
      user_site
    );
  };

  const handleDatabooksModalOk = async (record, callback) => {
    await handleDatabookClick(record);
    callback();
    setIsDatabooksModalVisible(false);
  };

  const showDatabooksModal = (record) => {
    setNewSelectedInspection(record);
    setIsDatabooksModalVisible(true);
  };

  const clientColumns = [
    ...columns,
    {
      title: '',
      dataIndex: 'fileUrl',
      key: 'fileUrl',
      no_sort: true,
      width: '20%',
      render: (_, record) => (
        <IconWrapper>
          <IconToggle
            disabled={!(record?.filesCount > 0)}
            aria-label={
              record?.filesCount > 0
                ? 'Download Databooks'
                : 'No Files to Download'
            }
            onClick={() => {
              setNewSelectedInspection(record);
              showDatabooksModal(record);
            }}
          >
            <Tooltip
              title={
                record?.filesCount > 0
                  ? 'Download Databooks'
                  : 'No Files to Download'
              }
              color={global.White}
            >
              <RxDownload size={16} />
            </Tooltip>
          </IconToggle>
        </IconWrapper>
      )
    }
  ];

  const adminColumns = [
    ...columns,
    {
      title: '',
      dataIndex: 'fileUrl',
      key: 'fileUrl',
      no_sort: true,
      width: 150,
      render: (_, record) => (
        <IconWrapper>
          <IconToggle
            aria-label="Attach Files"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              showFileUploadModal(record);
            }}
          >
            <Tooltip title="Attach Files" color={global.White}>
              <HiOutlinePaperClip size={16} />
            </Tooltip>
          </IconToggle>

          <IconToggle
            disabled={!(record?.filesCount > 0)}
            aria-label={
              record?.filesCount > 0
                ? 'Download Databooks'
                : 'No Files to Download'
            }
            onClick={() => {
              setNewSelectedInspection(record);
              showDatabooksModal(record);
            }}
          >
            <Tooltip
              title={
                record?.filesCount > 0
                  ? 'Download Databooks'
                  : 'No Files to Download'
              }
              color={global.White}
            >
              <RxDownload size={16} />
            </Tooltip>
          </IconToggle>

          <IconToggle
            aria-label="Delete Inspection"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              showDeleteInspectionModal(record);
            }}
          >
            <Tooltip title="Delete Inspection" color={global.White}>
              <HiOutlineTrash
                size={16}
                data-cy="Delete Inspection"
              />
            </Tooltip>
          </IconToggle>
        </IconWrapper>
      )
    }
  ];

  useEffect(() => {
    refreshFiles();
  }, [expandedInspectionId]);

  useEffect(() => {
    if (inspectionsReloadIsPending) {
      refreshFiles();
    }
  }, [inspectionsReloadIsPending]);

  const renderModals = () => (
    <>
      <DatabooksModal
        isVisible={isDatabooksModalVisible}
        inspection={selectedInspection}
        onCancel={handleDatabooksModalCancel}
        onOk={handleDatabooksModalOk}
      />
      <FileUploadModal
        isVisible={isFileUploadModalVisible}
        inspection={selectedInspection}
        onCancel={handleFileUploadModalCancel}
        onOk={handleFileUploadModalOk}
      />
      <InspectionDeleteModal
        isVisible={isDeleteInspectionModalVisible}
        inspection={selectedInspection}
        onCancel={handleDeleteInspectionModalCancel}
        onOk={handleDeleteInspectionModalOk}
      />
    </>
  );

  return (
    <>
      {renderModals()}
      <HeaderWrapper>
        <HeaderSection>
          <div>
            <Header>{user_organization?.orgName}</Header>
            <Status />
          </div>
          {is_admin && (
            <ButtonWrapper>
              <StyledButton
                type="text"
                data-cy="CreateInspection"
                onClick={() => {
                  history('/inspections');
                  setSideNavVisible(false);
                }}
              >
                <RiAddFill
                  size={16}
                  style={{ marginRight: '4px' }}
                />
                Create new inspection
              </StyledButton>
            </ButtonWrapper>
          )}
        </HeaderSection>

        <Breadcrumbs>
          <InspectionBreadcrumb
            id="InspectionsBreadcrumb"
            onClick={onInspectionBreadcrumbClick}
            aria-label="Inspections"
          >
            Inspections
          </InspectionBreadcrumb>
          <IoChevronForward className="breadcrumb-chevron" />
          <Breadcrumb
            id="OrgBreadcrumbInspections"
            onClick={onOrgBreadcrumbClick}
            aria-label="Inspection"
          >
            {user_organization?.orgName}
          </Breadcrumb>
          {user_site && (
            <>
              <IoChevronForward className="breadcrumb-chevron" />
              <StyledSpan>{user_site?.siteName}</StyledSpan>
            </>
          )}
        </Breadcrumbs>
      </HeaderWrapper>
      <Table
        loading={
          inspectionsReloadIsPending
          || inspectionRowUpdateIsPending
          || filesLoading
        }
        data={addKeys(inspections)}
        onRow={handleRows}
        columns={is_admin ? adminColumns : clientColumns}
      />
    </>
  );
};

export default InspectionTable;
