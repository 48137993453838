import React, { useState } from 'react';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

// eslint-disable-next-line import/extensions, import/no-unresolved
import { LeakType } from '../../typings/LeakTypes';
import './LeakSummaryCard.scss';

const LeakSummaryCard = ({
  leaks,
  leakStatus = 'NO LEAKS DETECTED'
}: {
  leaks: LeakType[],
  leakStatus: string,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className={`expandable-card ${isExpanded ? 'expanded' : ''}`}>
      <h2>
        <div className="status-header">
          Leaks
          <p>{leakStatus}</p>
        </div>
        <button
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}
          className={`expandable-button ${isExpanded ? 'expanded' : ''}`}
        >
          {isExpanded ? (
            <IoChevronUpOutline
              className="chevron-icon"
            />
          ) : (
            <IoChevronDownOutline
              className="chevron-icon"
            />
          )}
        </button>
      </h2>

      {isExpanded && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>LeakID / Tag</th>
                <th>Component type</th>
                <th>Sub component</th>
              </tr>
            </thead>
            <tbody>
              {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                leaks?.map((leak: any) => (
                  <tr key={leak?.id}>
                    <td>{leak?.tagNumber}</td>
                    <td>
                      {leak?.componentType}
                    </td>
                    <td>
                      {leak?.componentSubtype}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default LeakSummaryCard;
