import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { global } from '../../../shared/colors';

const centeredFlex = `
    display: flex;
    align-items: center;
    justify-content: center;
`;

const sharedFontSettings = `
    font-size: 1em;
    font-weight: 600;
`;

const CommonPlaceholder = styled.p`
    ${sharedFontSettings}
    color: ${global.Gray3};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PlaceholderMargin = styled(CommonPlaceholder)`
    margin: ${(props) => props.margin || '0'};
`;

const Placeholders = styled.section`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const sharedMarginSettings = '8px 0 32px 0';

const Toggle = styled.div`
    ${centeredFlex}
    margin: ${(props) => (props.noBottomMargin ? '8px 0 0 0' : sharedMarginSettings)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    width: 100%;
    height: 51px;
    border-radius: 1em;
    position: relative;
    background: ${(props) => (props.disabled ? `${global.Gray5}` : `${global.Gray5}`)};
    transition: background 0.2s;
`;

const ToggleCircle = styled.div`
    ${centeredFlex}
    position: absolute;
    left: ${(props) => (props.toggled ? '50%' : '0')};
    width: 50%;
    height: 100%;
    background: white;
    border-radius: 1em;
    transition: left 0.2s;
    ${sharedFontSettings}
    box-shadow: 0.2em 0.2em 1em 0px #0000001a;
`;

const PlaceholderO = styled(PlaceholderMargin).attrs({ margin: '0 0 0 25%' })``;
const PlaceholderV = styled(PlaceholderMargin)`
    margin: ${(props) => (props.text.length < 5 ? '0 24% 0 0' : '0 20% 0 0')};
`;

const ToggleSwitch = ({
  type, setType, text1, text2, disabled, noBottomMargin = false
}) => {
  const isToggled = type === text1;

  const toggle = () => {
    if (disabled) return;
    setType(isToggled ? text2 : text1);
  };

  return (
    <Toggle
      onClick={toggle}
      toggled={isToggled}
      disabled={disabled}
      noBottomMargin={noBottomMargin}
    >
      <ToggleCircle toggled={isToggled}>{isToggled ? text1 : text2}</ToggleCircle>
      <Placeholders>
        <PlaceholderO>{text2}</PlaceholderO>
        <PlaceholderV text={text1}>{text1}</PlaceholderV>
      </Placeholders>
    </Toggle>
  );
};

ToggleSwitch.propTypes = {
  type: PropTypes.string,
  setType: PropTypes.func.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

ToggleSwitch.defaultProps = {
  disabled: false,
  type: ''
};

export default ToggleSwitch;
