import React, { useContext, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Input } from 'antd';
import Select from 'react-select';
import { format } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';

import DropdownIndicator from '../../../../components/Select/DropdownIndicator';
import FooterNav from '../../components/FooterNav';
import ClearButton from '../../components/ClearButton';
import Spacer from '../../components/Spacer';
import Next from '../../components/Next';
import Back from '../../components/Back';
import Datepicker from '../../components/Datepicker/Datepicker';
import ActionButton from '../../components/ActionButton';
import { global } from '../../../../shared/colors';
import customStyles from '../../components/customStyles/customStyles';
import { ModifyInspectionsContext } from '../../../../context/ModifyInspectionsContext';
import { GlobalContext } from '../../../../context/GlobalContext';
import { IAM_URL } from '../../../../shared/url';
import { COMPLETE_STATUS_ID } from '../../../../shared/constants';
import './SiteDetails.scss';
import ClearIndicator from '../../../../components/Select/ClearIndicator';
import { postInspection } from '../../../../services/airmethaneApi';

const InputStyle = css`
    height: 51px;
    width: 100%;
    min-width: 200px;
    margin-top: 8px;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 16px 20px;
    cursor: pointer;
    border-radius: 10px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield;
    }

    ::placeholder {
        color: ${global.Gray4};
    }
`;

const NavStyle = css`
    width: 90%;
    height: 123px;
    border-radius: 10px;
    border: 1px solid ${global.Gray5};
    align-items: center;
    padding: 0 60px 0 60px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    background: ${global.White};
    bottom: 18px;
    width: 71%;
    max-width: 960px;
    z-index: 999;

    @media (max-width: 1000px) {
        width: 60%;
    }
`;

const StyledInput = styled(Input)`
    ${InputStyle}
`;

const InitialFooterNav = styled.section`
    ${NavStyle}
    display: flex;
    justify-content: end;
    z-index: 9999;
`;

const Container = styled.section`
    width: 60%;
`;

const Label = styled.label`
    color: ${global.Gray3};
    font-size: 16px;
    font-weight: 600;
`;

const Header = styled.h1`
    color: ${global.Black};
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 45px;
`;

const Part = styled.p`
    color: ${global.Gray4};
    font-size: 20px;
    margin-top: 8px;
    margin-bottom: 40px;
`;

const CenterContainer = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ButtonWrapper = styled.div`
    width: 238px;
    margin: 51px 40px 0 0;
`;

const initialFieldsStateOne = {
  customer: null,
  customerLocation: null,
  state: '',
  county: ''
};

const initialFieldsStateTwo = {
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  latitude: '',
  longitude: '',
  contactApiNumber: ''
};

const SiteDetails = () => {
  const [orgOptions, setOrgOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingSites, setIsFetchingSites] = useState(false);
  const {
    toggleSideNav,
    sideNavVisible,
    setSideNavVisibility,
    token,
    triggerGlobalNotification,
    user_organizations,
    loading,
    isStep2FormActive,
    areButtonsDisabled,
    setAreButtonsDisabled
  } = useContext(GlobalContext);
  const {
    setActiveTab,
    setActiveSubTab,
    part,
    setPart,
    fields,
    setFields,
    inspectionDate,
    setInspectionDate,
    sites,
    setSites,
    formData,
    clearFields,
    inspectionType,
    setInspectionType
  } = useContext(ModifyInspectionsContext);
  const location = useLocation();
  const navigate = useNavigate();

  const inspectionTypeOptions = [
    { value: 'FACILITY', label: 'Facility' },
    { value: 'PIPELINE', label: 'Pipeline' }
  ];

  const formattedDate = formData?.inspectionDate
    ? format(new Date(formData.inspectionDate), 'MMM dd, yyyy')
    : '';

  const formatData = (originalData) => {
    const { ...rest } = originalData;

    return {
      ...rest,
      statusId: COMPLETE_STATUS_ID,
      status: {
        ...rest.status,
        id: COMPLETE_STATUS_ID,
        code: 'complete',
        display: 'Complete'
      }
    };
  };

  const createInspection = async () => {
    setIsLoading(true);
    try {
      await postInspection(token, formatData(formData));
      triggerGlobalNotification(
        'success',
        `Inspection "${formData?.customerLocation?.customerLocationName} - ${formattedDate}" has been saved.`
      );
      toggleSideNav();
      setSideNavVisibility(!sideNavVisible);

      if (location.pathname === '/inspections') {
        navigate('/');
      }

      setIsLoading(false);
      clearFields();
    } catch (err) {
      setIsLoading(false);
      triggerGlobalNotification(
        'error',
        `Inspection "${formData?.customerLocation?.customerLocationName} - ${formattedDate}" failed to saved.`
      );
    }
  };

  const handlePartChange = () => {
    setPart(2);
  };

  const handleBack = () => {
    setPart(1);
  };

  const handleNext = () => {
    setActiveTab('inspection-details');
    setActiveSubTab('info');
  };

  const handleInputChange = (e, fieldName) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: e.target.value
    }));
  };

  const clearPartOne = () => {
    setFields((prevFields) => ({
      ...prevFields,
      ...initialFieldsStateOne
    }));
    setInspectionDate(null);
  };

  const clearPartTwo = () => {
    setFields((prevFields) => ({
      ...prevFields,
      ...initialFieldsStateTwo
    }));
    setInspectionDate(null);
  };

  const getSitesForOrg = async (endpoint) => {
    try {
      const results = await fetch(`${endpoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      if (results.ok) {
        return await results.json();
      }
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const handleSite = (selectedSite) => {
    setFields((prevFields) => ({
      ...prevFields,
      customerLocation: {
        customerLocationId: selectedSite?.locationId
          ? selectedSite?.locationId
          : null,
        customerLocationName: selectedSite?.label
          ? selectedSite?.label
          : null
      }
    }));
  };

  const handleInspectionType = (selectedOption) => {
    const inspectionTypeValue = selectedOption ? selectedOption.value : null;
    setFields((prevFields) => ({
      ...prevFields,
      inspectionType: inspectionTypeValue
    }));
    setInspectionType(inspectionTypeValue);
  };

  const handleSitesForOrg = async (orgArg) => {
    if (orgArg?.value !== fields?.customer?.customerId) setSites(null);
    if (!orgArg) {
      setSites([]);
      return;
    }
    setIsFetchingSites(true);
    try {
      const data = await getSitesForOrg(
        `${IAM_URL}/iam/orgs/${orgArg?.value}/sites`
      );
      const siteData = data?.sites?.map((s) => ({
        value: s?.siteId,
        locationId: s?.netsuiteId,
        label: s?.siteName
      }));
      setSites(siteData ?? []);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetchingSites(false);
    }
  };

  const handleCompany = (orgArg) => {
    handleSitesForOrg(orgArg);
    setFields((prev) => ({
      ...prev,
      customer: {
        customerId: orgArg?.customerId ? orgArg?.customerId : null,
        customerName: orgArg?.label ? orgArg?.label : null
      }
    }));
  };

  const selectedSiteOption = sites?.find(
    (option) => option.locationId === fields?.customerLocation?.customerLocationId
  );

  const checkRequiredFields = () => {
    const hasRequiredFields = Boolean(
      formData?.inspectionDate
      && formData?.customer?.customerName
      && formData?.customerLocation?.customerLocationName
      && formData?.inspectionType
    );
    setAreButtonsDisabled(!hasRequiredFields);
  };

  useEffect(() => {
    checkRequiredFields();
  }, [formData, areButtonsDisabled]);

  useEffect(() => {
    if (!loading && fields.customer && user_organizations) {
      const selectedOrg = user_organizations.find(
        (o) => o.netsuiteId === fields.customer.customerId
      );
      if (selectedOrg) {
        const orgArg = {
          value: selectedOrg.orgId,
          label: selectedOrg.orgName
        };
        handleSitesForOrg(orgArg);
      }
    }

    if (!loading) {
      setOrgOptions(
        user_organizations?.map((o) => ({
          value: o?.orgId,
          customerId: o?.netsuiteId,
          label: o?.orgName
        }))
      );
    }
  }, [loading, fields.customer, user_organizations]);

  return part === 1 ? (
    <>
      <Container>
        <Header>Site details</Header>
        {isStep2FormActive ? <Part>Part 1 of 2</Part> : <Part />}
        <Label>Inspection type</Label>
        <Select
          id="inspectionType"
          isClearable
          placeholder="Select inspection type"
          value={inspectionTypeOptions.find((option) => option.value === inspectionType)}
          options={inspectionTypeOptions}
          className="detail-select"
          classNamePrefix="detail-select"
          styles={customStyles}
          onChange={handleInspectionType}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator,
            DropdownIndicator
          }}
        />
        <Label>Inspection date</Label>
        <Datepicker
          type="date"
          selected={inspectionDate || ''}
          onChange={setInspectionDate}
        />
        <Label>Customer</Label>
        <Select
          id="customer"
          isClearable
          placeholder="Select a customer"
          value={
            orgOptions?.find(
              (option) => option?.customerId
                === fields?.customer?.customerId
            ) || ''
          }
          options={orgOptions}
          className="detail-select"
          classNamePrefix="detail-select"
          styles={customStyles}
          onChange={handleCompany}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator,
            DropdownIndicator
          }}
        />
        <Label>Site name</Label>
        <Select
          id="site"
          isClearable
          placeholder="Dependent on customer"
          className="detail-select"
          classNamePrefix="detail-select"
          value={selectedSiteOption || ''}
          options={sites}
          styles={customStyles}
          isDisabled={!sites || isFetchingSites}
          onChange={handleSite}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator,
            DropdownIndicator
          }}
        />
        <Label>State</Label>
        <StyledInput
          value={fields?.state}
          onChange={(e) => handleInputChange(e, 'state')}
          placeholder="Dependent on site"
        />
        <Label>County</Label>
        <StyledInput
          value={fields.county}
          onChange={(e) => handleInputChange(e, 'county')}
          placeholder="Dependent on site"
        />
        <ClearButton onClear={clearPartOne} />
        {!isStep2FormActive && (
          <CenterContainer>
            <ButtonWrapper>
              <ActionButton
                onClick={createInspection}
                isLoading={isLoading}
                disabled={areButtonsDisabled}
                alwaysEnableOnLoading
                type="button"
              >
                Save
              </ActionButton>
            </ButtonWrapper>
          </CenterContainer>
        )}
        <Spacer />
      </Container>
      {isStep2FormActive && (
        <InitialFooterNav>
          <Next type="submit" onClick={handlePartChange}>
            Next
          </Next>
        </InitialFooterNav>
      )}
    </>
  ) : (
    <>
      <Container>
        <Header>Site details</Header>
        <Part>Part 2 of 2</Part>
        <Label>Contact name</Label>
        <StyledInput
          value={fields.contactName}
          onChange={(e) => handleInputChange(e, 'contactName')}
          placeholder="John Smith"
        />
        <Label>Phone number</Label>
        <StyledInput
          value={fields.contactPhone}
          onChange={(e) => handleInputChange(e, 'contactPhone')}
          placeholder="(555) 555 - 5555"
        />
        <Label>Email (optional)</Label>
        <StyledInput
          type="email"
          value={fields.contactEmail}
          onChange={(e) => handleInputChange(e, 'contactEmail')}
          placeholder="John.Smith@gmail.com"
        />
        <Label>Latitude</Label>
        <StyledInput
          type="number"
          value={fields.latitude}
          onChange={(e) => handleInputChange(e, 'latitude')}
          placeholder="12.345678"
        />
        <Label>Longitude</Label>
        <StyledInput
          type="number"
          value={fields.longitude}
          onChange={(e) => handleInputChange(e, 'longitude')}
          placeholder="-12.345678"
        />
        <Label>API # (optional)</Label>
        <StyledInput
          value={fields.contactApiNumber}
          onChange={(e) => handleInputChange(e, 'contactApiNumber')}
          placeholder="123 - 456789"
        />
        <ClearButton onClear={clearPartTwo} />
        <Spacer />
      </Container>
      <FooterNav>
        <Back type="submit" onClick={handleBack}>
          Back
        </Back>
        <Next type="submit" onClick={handleNext}>
          Next
        </Next>
      </FooterNav>
    </>
  );
};

export default SiteDetails;
