import React from 'react';
import styled from 'styled-components';
import { global } from '../../../../shared/colors';

const OptionDangerHeader = styled.h4`
    color: ${global.ErrorRed};
    margin: 0 0 4px 0;
    font-weight: ${(props) => (props.selected ? '600' : '400')};
    font-size: 16px;
`;

const OptionNumber = styled.h4`
    position: relative;
    margin: ${(props) => (props.danger ? '0 20px 0 0' : '8px 20px 0 0')};
    font-weight: ${(props) => (props.selected ? '600' : '400')};
    font-size: 16px;
    color: ${(props) => (props.danger ? `${global.ErrorRed}` : `${global.Black}`)}
`;

const OptionDangerLabel = styled.p`
    margin: 0;
    font-weight: ${(props) => (props.selected ? '600' : '400')};
    font-size: 12px;
    color: #848383;
`;

const Row = styled.div`
    display: flex;
    margin-top: 8px;
    align-items: ${(props) => (props.value ? 'flex-start' : 'center')};
    flex-direction: row;
    height: 30px;
    
    .detail-select-priority__input-container {
        ${(props) => props.value === null
            && `
            margin: 0;
            padding: 0;
            height: 0;
        `}
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const OptionHeader = styled.h4`
    margin: 8px 0;
    font-weight: ${(props) => (props.selected ? '600' : '400')};
    font-size: 16px;
    color: ${global.Gray2};
`;

const generateOptionLabel = (value, number, header, label, selected) => {
  const isSelected = selected === value;

  if (['1 - Emergency Work', '2 - Urgent Work'].includes(value)) {
    return (
      <Row value={value}>
        <OptionNumber danger selected={isSelected}>
          {number}
        </OptionNumber>
        <Column>
          <OptionDangerHeader selected={isSelected}>
            {header}
          </OptionDangerHeader>
          <OptionDangerLabel selected={isSelected}>
            {label}
          </OptionDangerLabel>
        </Column>
      </Row>
    );
  }
  return (
    <Row value={value}>
      <OptionNumber selected={isSelected}>{number}</OptionNumber>
      <OptionHeader selected={isSelected}>{header}</OptionHeader>
    </Row>
  );
};

const priorityOptions = (priority) => [
  {
    value: '1 - Emergency Work',
    label: generateOptionLabel(
      '1 - Emergency Work',
      '1',
      'Emergency Work',
      'Immediate EHS impact.',
      priority
    ),
    textLabel: '1 - Emergency Work'
  },
  {
    value: '2 - Urgent Work',
    label: generateOptionLabel(
      '2 - Urgent Work',
      '2',
      'Urgent Work',
      'High financial impact. Must be completed within one week.',
      priority
    ),
    textLabel: '2 - Urgent Work'
  },
  {
    value: '3 - Two Weeks',
    label: generateOptionLabel(
      '3 - Two Weeks',
      '3',
      'Within two weeks',
      '',
      priority
    ),
    textLabel: '3 - Two Weeks'
  },
  {
    value: '4 - Two Months',
    label: generateOptionLabel(
      '4 - Two Months',
      '4',
      'Within 2 months',
      '',
      priority
    ),
    textLabel: '4 - Two Months'
  },
  {
    value: '5 - Low Priority',
    label: generateOptionLabel(
      '5 - Low Priority',
      '5',
      'Within 6 months (or next time equipment is down)',
      '',
      priority
    ),
    textLabel: '5 - Low Priority'
  }
];

export default priorityOptions;
