import styled from 'styled-components';

import { global } from '../../../shared/colors';

const isStep2FormActive = process.env.REACT_APP_STEP_2_FORM_ACTIVE === 'true';

const ScrollRight = styled.section`
    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    overflow: scroll;
    ${!isStep2FormActive ? 'margin: 0 0 40px 0;' : 'margin: 0 0 80px 0;'}
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: ${global.Gray5} transparent;

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${global.Gray5};
        border-radius: 2px;
    }
`;

export default ScrollRight;
