const sliceName = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  const underscoreIndex = fileName.lastIndexOf('_');

  if (underscoreIndex > lastDotIndex && underscoreIndex !== -1) {
    return fileName.slice(0, underscoreIndex);
  }

  if (lastDotIndex !== -1) {
    return fileName.slice(0, lastDotIndex);
  }

  return fileName;
};

export default sliceName;
