import { global } from '../../../../shared/colors';

const customStyles = {
  dropdownIndicator: () => ({
    cursor: 'pointer'
  }),
  control: (provided) => ({
    ...provided,
    '&:hover': {
      borderColor: global.PrimaryBlue,
      cursor: 'pointer'
    },
    width: '100%',
    borderRadius: '10px',
    height: '51px',
    borderColor: global.Gray5
  }),
  container: (provided) => ({
    ...provided,
    justifyContent: 'flex-start',
    margin: '8px 0 20px 0'
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: global.Gray4,
    fontFamily: 'Inter',
    width: '100%'
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    width: '90%',
    margin: 'auto',
    color: global.Black,
    fontFamily: 'Inter',
    backgroundColor: isSelected ? global.Gray7 : global.White,
    borderRadius: '10px',
    cursor: 'pointer',
    marginTop: '2px',
    '&:hover': {
      backgroundColor: global.Gray7
    }
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999
  })
};

export default customStyles;
