import React from 'react';
import { Skeleton } from 'antd';

import './FormSkeletonLoader.scss';

const FormSkeletonLoader = () => (
  <div className="form-skeleton">
    <Skeleton
      className="nav-skeleton"
      paragraph={{ rows: 0 }}
      active
    />
    <Skeleton
      className="content-skeleton"
      paragraph={{ rows: 0 }}
      active
    />
  </div>
);

export default FormSkeletonLoader;
