/* eslint-disable react/no-unstable-nested-components */
import React, {
  useContext, useCallback, useState, useEffect
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IoCheckmarkCircle, IoCheckmarkCircleOutline } from 'react-icons/io5';
import { format } from 'date-fns';

import { Progress } from 'antd';
import SiteDetails from './pages/SiteDetails/SiteDetails';
import LeakDetails from './pages/Leaks/Leaks';
import Info from './pages/InspectionDetails/Info';
import Verification from './pages/InspectionDetails/Verification';
import WeatherConditions from './pages/InspectionDetails/Weather';
import Surveys from './pages/InspectionDetails/Surveys/Surveys';
import InspectionSummary from './pages/InspectionSummary/InspectionSummary';
import FinalSubmission from './pages/FinalSubmission/FinalSubmission';
import { GlobalContext } from '../../context/GlobalContext';
import { global } from '../../shared/colors';
import { ModifyInspectionsContext } from '../../context/ModifyInspectionsContext';
import ActionButton from './components/ActionButton';
import { putInspection, postInspection, deleteInspection } from '../../services/airmethaneApi';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal.tsx';
import { inspectionSections } from '../../shared/constants';
import {
  determineAllCompletionValues,
  determineInspectionDetailsCompletion,
  determineLeakDetailsCompletion,
  getSectionPercentage
} from './utils';
import ScrollRight from './components/ScrollRight';
import Border from './components/Border';
import FormSkeletonLoader from './components/FormSkeletonLoader';

const getTabTextColor = (props) => {
  if (props.isActive) return global.White;
  if (props.completed) return global.Black;
  return global.Gray3;
};

const Nav = styled.nav`
  min-width: 220px;
  max-width: 278px;
  width: 20%;
  height: 88vh;
  float: left;
  margin: 0 60px 20px 20px;
  border-radius: 20px;
  background: ${global.Gray6};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const Main = styled.main`
  margin-left: 10px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin: 10px 0;
`;

const TabSection = styled.section`
  margin-top: 20px;
  overflow: auto;
  overflow-x: hidden;
`;

const StyledSection = styled.section`
  overflow: auto;
  overflow-x: hidden;
`;

const Header = styled.header`
  margin-top: 60px;
  margin-left: 20px;
  font-size: 24px;
  width: 216px;
  font-weight: 600;
  line-height: 29.05px;
`;

const TabBase = styled.div`
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
`;

const Tab = styled(TabBase)`
  background-color: ${(props) => (props.isActive ? global.PrimaryBlue : 'transparent')};
  color: ${(props) => getTabTextColor(props)};
  font-size: 16px;
  font-weight: 600;
  margin: 1px;
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
  padding-left: 20px;

  svg {
    color: ${(props) => getTabTextColor(props)};
    width: 20px;
    height: 20px;
  }

  &:hover {
    opacity: 65%;
  }
`;

const SubTab = styled(TabBase)`
  margin-left: 25px;
  color: ${(props) => (props.isActive ? global.PrimaryBlue : global.Gray3)};
  font-size: 16px;
  font-weight: 600;
  padding: 4px;

  &:hover {
    opacity: 65%;
  }
`;

const ModifyInspection = () => {
  const { id: inspectionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteRetry, setIsDeleteRetry] = useState(false);
  const [isDeleteRetryOpen, setIsDeleteRetryOpen] = useState(false);
  const [isSaveRetryOpen, setIsSaveRetryOpen] = useState(false);
  const [totalCompletionPercentage, setTotalCompletionPercentage] = useState(0);
  const [sectionCompletionPercentage, setSectionCompletionPercentage] = useState({
    siteDetails: 0,
    inspectionDetails: 0,
    leakDetails: 0
  });
  const {
    toggleSideNav,
    sideNavVisible,
    setSideNavVisibility,
    token,
    triggerGlobalNotification,
    getAndSetInspections,
    isStep2FormActive,
    areButtonsDisabled,
    setAreButtonsDisabled
  } = useContext(GlobalContext);
  const {
    activeTab,
    setActiveTab,
    activeSubTab,
    setActiveSubTab,
    formData,
    clearFields,
    completedSections,
    setCompletedSections,
    fields,
    leakRepair = [],
    isLeaks,
    editInspectionData: { isPending: isInspectionPending, inspectionData },
    getInspectionById,
    resetEditInspectionData
  } = useContext(ModifyInspectionsContext);
  const location = useLocation();
  const navigate = useNavigate();

  const formattedDate = formData?.inspectionDate
    ? format(new Date(formData.inspectionDate), 'MMM dd, yyyy')
    : '';

  const createOrUpdateInspection = async () => {
    setIsLoading(true);
    try {
      if (formData.id) {
        await putInspection(token, formData);
      } else {
        await postInspection(token, formData);
      }

      triggerGlobalNotification(
        'success',
        `Inspection "${formData?.customerLocation?.customerLocationName} - ${formattedDate}" has been saved.`
      );
      toggleSideNav();
      setSideNavVisibility(!sideNavVisible);

      if (location.pathname.split('/').at(1) === 'inspections') {
        resetEditInspectionData();
        navigate('/');
      }

      setIsLoading(false);
      clearFields();
    } catch (err) {
      setIsLoading(false);
      setIsSaveRetryOpen(true);
    }
  };

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeRetryModal = () => {
    setIsDeleteRetryOpen(false);
  };

  const closeSaveModal = () => {
    setIsSaveRetryOpen(false);
  };

  const showSuccessNotification = (message) => {
    resetEditInspectionData();
    closeModal();
    clearFields();
    toggleSideNav();
    triggerGlobalNotification('success', message);
    navigate('/');
  };

  const performDeletion = async () => {
    try {
      await deleteInspection(token, formData?.id);
      await getAndSetInspections();
      resetEditInspectionData();
      closeModal();
      showSuccessNotification(
        `Inspection "${formData?.customerLocation?.customerLocationName} - ${formattedDate}" has been deleted.`
      );
    } catch (err) {
      console.error(err);
      closeModal();
      setIsDeleteRetryOpen(true);
    }
  };

  const handleDeletion = async (setLoadingState) => {
    setLoadingState(true);

    if (!formData?.id) {
      clearFields();
      setSideNavVisibility(!sideNavVisible);
      triggerGlobalNotification('success', 'Inspection has been deleted.');
      setLoadingState(false);
      navigate('/');
      return;
    }

    await performDeletion();

    setLoadingState(false);
  };

  const confirmDelete = () => {
    handleDeletion(setIsDeleting);
  };

  const confirmRetryDelete = () => {
    handleDeletion(setIsDeleteRetry);
  };

  const confirmRetrySave = () => {
    createOrUpdateInspection();
  };

  const handleTabChange = useCallback(
    (key) => () => {
      setActiveTab(key);
      if (key === 'inspection-details') {
        setActiveSubTab('info');
      } else {
        setActiveSubTab(null);
      }
    },
    []
  );

  const handleSubTabChange = useCallback(
    (key) => (event) => {
      event.stopPropagation();
      setActiveSubTab(key);
    },
    []
  );

  const checkRequiredFields = () => {
    const hasRequiredFields = formData?.inspectionDate
      && formData?.customer?.customerName
      && formData?.customerLocation?.customerLocationName;
    setAreButtonsDisabled(!hasRequiredFields);
  };

  const ConfirmationModals = () => (
    <>
      <ConfirmationModal
        isOpen={isSaveRetryOpen}
        onClose={closeSaveModal}
        onConfirm={confirmRetrySave}
        isProcessing={isLoading}
        modalHeader="Oh no! Your inspection failed to save."
        modalText="Would you like to retry to save your inspection?"
        confirmButtonColor={global.PrimaryBlue}
        cancelButtonColor={global.Gray4}
        cancelTextColor={global.White}
        confirmButtonText="Yes, retry"
        cancelButtonText="No, don't save"
        textColor={global.Gray3}
        textWidth="390px"
      />
      <ConfirmationModal
        isOpen={isDeleteRetryOpen}
        onClose={closeRetryModal}
        onConfirm={confirmRetryDelete}
        isProcessing={isDeleteRetry}
        modalHeader="Oh no! Your inspection failed to delete."
        modalText="Would you like to retry to delete your inspection?"
        cancelButtonColor={global.Gray4}
        cancelTextColor={global.White}
        confirmButtonText="Delete"
        textColor={global.Gray3}
        textWidth="390px"
      />
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmDelete}
        isProcessing={isDeleting}
        modalHeader="Are you sure?"
        confirmButtonText="Yes, delete"
        modalText={(
          <>
            Deleting this inspection will wipe all information you have entered.
            <strong>This action cannot be undone.</strong>
          </>
        )}
      />
    </>
  );

  useEffect(() => {
    checkRequiredFields();
  }, [formData]);

  const renderContent = () => {
    switch (activeTab) {
      case 'site-details':
        return <SiteDetails />;
      case 'inspection-details':
        switch (activeSubTab) {
          case 'info':
            return <Info />;
          case 'verification':
            return <Verification />;
          case 'weather-conditions':
            return <WeatherConditions />;
          case 'surveys':
            return <Surveys />;
          default:
            return <Info />;
        }
      case 'leak-details':
        return <LeakDetails />;
      case 'inspection-summary':
        return <InspectionSummary />;
      case 'final-submission':
        return <FinalSubmission />;
      default:
        return null;
    }
  };

  const Check = <IoCheckmarkCircle />;
  const CheckOutline = <IoCheckmarkCircleOutline />;

  const renderCheckmark = (section) => (completedSections.includes(section) ? Check : CheckOutline);

  useEffect(() => {
    switch (activeTab) {
      case inspectionSections.SITE_DETAILS: {
        const siteFields = {
          ...fields,
          customerLocation: fields.customerLocation?.customerLocationId,
          customer: fields.customer?.customerId
        };
        const siteDetailsCompletion = getSectionPercentage(
          inspectionSections.SITE_DETAILS,
          siteFields
        );
        if (siteDetailsCompletion === 100) {
          setCompletedSections(
            Array.from(new Set([...completedSections, inspectionSections.SITE_DETAILS]))
          );
        } else if (completedSections.includes(inspectionSections.SITE_DETAILS)) {
          setCompletedSections(
            completedSections.filter((section) => section !== inspectionSections.SITE_DETAILS)
          );
        }
        setSectionCompletionPercentage({
          ...sectionCompletionPercentage,
          siteDetails: siteDetailsCompletion
        });
        break;
      }
      case inspectionSections.INSPECTION_DETAILS: {
        const inspectionDetailsCompletion = determineInspectionDetailsCompletion({ fields });

        if (inspectionDetailsCompletion === 100) {
          setCompletedSections(
            Array.from(new Set([...completedSections, inspectionSections.INSPECTION_DETAILS]))
          );
        } else if (completedSections.includes(inspectionSections.INSPECTION_DETAILS)) {
          setCompletedSections(
            completedSections.filter(
              (section) => section !== inspectionSections.INSPECTION_DETAILS
            )
          );
        }
        setSectionCompletionPercentage({
          ...sectionCompletionPercentage,
          inspectionDetails: inspectionDetailsCompletion
        });
        break;
      }
      case inspectionSections.LEAK_DETAILS: {
        const { leaks = [] } = fields;
        const leakDetailsCompletion = determineLeakDetailsCompletion({
          leaks,
          isLeaks,
          leakRepair
        });
        if (leakDetailsCompletion === 100) {
          setCompletedSections(
            Array.from(new Set([...completedSections, inspectionSections.LEAK_DETAILS]))
          );
        } else if (completedSections.includes(inspectionSections.LEAK_DETAILS)) {
          setCompletedSections(
            completedSections.filter((section) => section !== inspectionSections.LEAK_DETAILS)
          );
        }
        setSectionCompletionPercentage({
          ...sectionCompletionPercentage,
          leakDetails: leakDetailsCompletion
        });
        break;
      }
      default:
        break;
    }
  }, [fields, leakRepair, activeTab, isLeaks]);

  useEffect(() => {
    if (
      completedSections.includes(inspectionSections.INSPECTION_SUMMARY)
      && completedSections.length <= 3
    ) {
      setCompletedSections(
        completedSections.filter((section) => section !== inspectionSections.INSPECTION_SUMMARY)
      );
    } else if (
      completedSections.length === 3
      && !completedSections.includes(inspectionSections.INSPECTION_SUMMARY)
    ) {
      setCompletedSections(
        Array.from(new Set([...completedSections, inspectionSections.INSPECTION_SUMMARY]))
      );
    } else if (
      completedSections.length === 4
      && !completedSections.includes(inspectionSections.FINAL_SUBMISSION)
    ) {
      setCompletedSections(
        Array.from(new Set([...completedSections, inspectionSections.FINAL_SUBMISSION]))
      );
    } else if (
      completedSections.length <= 4
      && completedSections.includes(inspectionSections.FINAL_SUBMISSION)
    ) {
      setCompletedSections(
        completedSections.filter((section) => section !== inspectionSections.FINAL_SUBMISSION)
      );
    }
  }, [completedSections]);

  useEffect(() => {
    const { siteDetails, inspectionDetails, leakDetails } = sectionCompletionPercentage;
    const totalCompletion = (siteDetails + inspectionDetails + leakDetails) / 3;
    setTotalCompletionPercentage(totalCompletion);
  }, [sectionCompletionPercentage]);

  useEffect(() => {
    if (inspectionId && !isInspectionPending && !inspectionData) {
      getInspectionById(inspectionId);
    }
    if (inspectionData) {
      const completionFields = determineAllCompletionValues({ fields, leakRepair, isLeaks });
      setSectionCompletionPercentage(completionFields);
    }
  }, [inspectionId, isInspectionPending, inspectionData]);

  if (isInspectionPending) {
    return <FormSkeletonLoader />;
  }

  return (
    <div>
      <Nav>
        <div>
          <Row>
            <ActionButton
              onClick={createOrUpdateInspection}
              isLoading={isLoading}
              disabled={(!isLoading && areButtonsDisabled) || !isStep2FormActive}
              alwaysEnableOnLoading
              type="button"
            >
              Save and exit
            </ActionButton>
          </Row>
          <StyledSection>
            <Header>Submit your inspection</Header>
            <TabSection>
              <Tab
                isActive={activeTab === 'site-details' || !isStep2FormActive}
                onClick={handleTabChange('site-details')}
                completed={completedSections.includes(inspectionSections.SITE_DETAILS)}
              >
                Site details
                {renderCheckmark(inspectionSections.SITE_DETAILS)}
              </Tab>
              {isStep2FormActive && (
                <>
                  <Tab
                    isActive={activeTab === 'inspection-details'}
                    onClick={handleTabChange('inspection-details')}
                    completed={completedSections.includes(inspectionSections.INSPECTION_DETAILS)}
                  >
                    Inspection details
                    {renderCheckmark(inspectionSections.INSPECTION_DETAILS)}
                  </Tab>
                  {activeTab === 'inspection-details' && (
                    <SubSection>
                      <SubTab
                        isActive={activeSubTab === 'info'}
                        onClick={handleSubTabChange('info')}
                      >
                        Info
                      </SubTab>
                      <SubTab
                        isActive={activeSubTab === 'verification'}
                        onClick={handleSubTabChange('verification')}
                      >
                        Verification
                      </SubTab>
                      <SubTab
                        isActive={activeSubTab === 'weather-conditions'}
                        onClick={handleSubTabChange('weather-conditions')}
                      >
                        Weather conditions
                      </SubTab>
                      <SubTab
                        isActive={activeSubTab === 'surveys'}
                        onClick={handleSubTabChange('surveys')}
                      >
                        Surveys
                      </SubTab>
                    </SubSection>
                  )}
                  <Tab
                    isActive={activeTab === 'leak-details'}
                    onClick={handleTabChange('leak-details')}
                    completed={completedSections.includes(inspectionSections.LEAK_DETAILS)}
                  >
                    Leak details
                    {renderCheckmark(inspectionSections.LEAK_DETAILS)}
                  </Tab>
                  <Tab
                    isActive={activeTab === 'inspection-summary'}
                    onClick={handleTabChange('inspection-summary')}
                    completed={completedSections.includes(inspectionSections.INSPECTION_SUMMARY)}
                  >
                    Inspection summary
                    {renderCheckmark(inspectionSections.INSPECTION_SUMMARY)}
                  </Tab>
                  <Tab
                    isActive={activeTab === 'final-submission'}
                    onClick={handleTabChange('final-submission')}
                    completed={completedSections.includes(inspectionSections.FINAL_SUBMISSION)}
                  >
                    Final submission
                    {renderCheckmark(inspectionSections.FINAL_SUBMISSION)}
                  </Tab>
                </>
              )}
            </TabSection>
          </StyledSection>
        </div>
        <Row>
          <ActionButton
            variant="delete"
            onClick={handleDelete}
            disabled={areButtonsDisabled || isLoading || !isStep2FormActive}
          >
            Delete
          </ActionButton>
        </Row>
        <ConfirmationModals />
      </Nav>
      <Main>
        <Border>
          {isStep2FormActive && (
            <div className="progress-bar-container">
              <Progress
                className="completion-percentage"
                percent={parseInt(totalCompletionPercentage, 10)}
                showInfo={false}
              />
              <p>
                {parseInt(totalCompletionPercentage, 10)}
                % complete
              </p>
            </div>
          )}
          <ScrollRight>{renderContent()}</ScrollRight>
        </Border>
      </Main>
    </div>
  );
};

export default ModifyInspection;
