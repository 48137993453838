import styled from 'styled-components';

import { global } from '../../../shared/colors';

const Back = styled.button`
    border-radius: 10px;
    border: 1px solid ${global.Gray3};
    width: 120px;
    height: 43px;
    background: none;
    cursor: pointer;

    &:hover {
        opacity: 65%;
    }
`;

export default Back;
