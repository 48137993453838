import React, { useContext } from 'react';
import { Avatar, Modal } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import { GlobalContext } from '../../context/GlobalContext';

import { global } from '../../shared/colors';

const StyledLogoutButton = styled.button`
    width: 119px;
    height: 43px;
    border-radius: 5px;
    border: 1px solid ${global.ErrorRed};
    background-color: ${global.White};
    color: ${global.ErrorRed};

    &:hover {
        cursor: pointer;
    }
`;

const LogoutButton = ({ logout }) => (
  <StyledLogoutButton
    className="logoutButton"
    onClick={() => logout({ returnTo: window.location.origin })}
    aria-label="Logout"
  >
    Logout
  </StyledLogoutButton>
);

const StyledProfileModal = styled(Modal)`
    float: right;
    margin-right: 40px;
    top: 72px;

    .ant-modal-body {
        display: flex;
        align-items: center;
        flex-direction: column;

        h1 {
            font-size: 20px;
            font-weight: 600;
            color: ${global.Gray1};
            margin: 0;
        }

        p {
            font-size: 16px;
            margin: 0 0 20px 0;
            color: ${global.Gray3};
        }
    }

    .ant-modal-close-x {
        color: ${global.Black};
    }
`;

const ProfileModal = () => {
  const { logout, user } = useAuth0();

  const { profileModalOpen, setProfileModalOpen } = useContext(GlobalContext);

  return (
    <StyledProfileModal
      footer={[]}
      open={profileModalOpen}
      wrapClassName="profile-modal-dialog"
      onCancel={() => setProfileModalOpen(false)}
      maskStyle={{ backgroundColor: 'transparent' }}
      role="dialog"
      aria-labelledby="profile-modal-title"
    >
      <Avatar
        size={88}
        src={user?.picture}
        alt={user?.name || 'User avatar'}
      />
      <h1 id="profile-modal-title">{user?.name || 'No Name'}</h1>
      <p>{user?.email || 'No Email'}</p>
      <LogoutButton logout={logout} />
    </StyledProfileModal>
  );
};

export default ProfileModal;
