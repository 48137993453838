import styled from 'styled-components';

import { global } from '../../../shared/colors';

const Header = styled.h1`
    color: ${global.Black};
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 45px;
`;

export default Header;
