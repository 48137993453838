import React, {
  useState, createContext, useEffect, useMemo
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

import {
  AIR_METHANE_ADMIN_GROUP_NAME,
  INFINITE,
  homeSections
} from '../shared/constants';
import getOrgs from '../services/getOrgs';
import getSites from '../services/getSites';
import getGroup from '../services/getGroup';
import { getInspections } from '../services/airmethaneApi';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const isStep2FormActive = process.env.REACT_APP_STEP_2_FORM_ACTIVE === 'true';
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [token, setToken] = useState('');
  const [activeHomeSection, setActiveHomeSection] = useState(
    isStep2FormActive ? homeSections.IN_PROGRESS : homeSections.COMPLETE
  );
  const [userIsInternal, setUserIsInternal] = useState(false);
  const [inspections, setInspections] = useState(null);
  const [user_organizations, setOrganizations] = useState(null);
  const [insp_id, setInspId] = useState(null);
  const [user_sites, setSites] = useState(null);
  const [selectInspection, setSelectInspection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inspectionsReloadIsPending, setInspectionsReloadIsPending] = useState(false);
  const [is_admin, setIsAdmin] = useState(false);
  const [group, setGroup] = useState(null);
  const [has_access, setHasAccess] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [inspectionsReadyCount, setInspectionsReadyCount] = useState(0);
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);
  const location = useLocation();

  const [globalNotificationType, setGlobalNotificationType] = useState({
    show: false,
    message: '',
    type: '',
    duration: null
  });

  const triggerGlobalNotification = (type, message, customTitle = '', duration = 5000) => {
    setGlobalNotificationType({
      show: true,
      type,
      message,
      customTitle,
      duration
    });
  };

  const closeGlobalNotification = () => {
    setGlobalNotificationType({ show: false, type: '', message: '' });
  };

  const initialValue = () => {
    const savedValue = localStorage.getItem('sideNavVisible');
    return savedValue !== null ? JSON.parse(savedValue) : true;
  };

  const [sideNavVisible, setSideNavVisible] = useState(initialValue());

  const setSideNavVisibility = (visibility) => {
    setSideNavVisible(visibility);
  };

  let netsuiteToAxilSiteIdMap = {};
  let netsuiteToAxilCustomerIdMap = {};

  if (!loading) {
    netsuiteToAxilSiteIdMap = Object.fromEntries(
      user_sites?.map(({ netsuiteId, siteId }) => [netsuiteId, siteId])
    );
    netsuiteToAxilCustomerIdMap = Object.fromEntries(
      user_organizations?.map(({ netsuiteId, orgId }) => [netsuiteId, orgId])
    );
  }

  const getAndSetInspections = async () => {
    if (inspections) {
      setInspectionsReloadIsPending(true);
    }

    const attemptOperationWithRetry = async (retryCount = 3) => {
      try {
        const data = await getInspections(token);
        if (data?.inspections) {
          const sortedInspections = [...data.inspections].sort(
            (a, b) => new Date(b.inspectionDate) - new Date(a.inspectionDate)
          );
          setInspections(sortedInspections);
        }

        if (inspections) {
          setInspectionsReloadIsPending(false);
        }
        return true;
      } catch (error) {
        if (retryCount <= 1) throw error;
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return attemptOperationWithRetry(retryCount - 1);
      }
    };

    try {
      await attemptOperationWithRetry();
    } catch (err) {
      setLoading(false);
      triggerGlobalNotification(
        'error',
        `There’s a problem loading your inspections. Try refreshing
        the page. If the problem persists, please contact support for help.`,
        'Failure to load inspections',
        INFINITE
      );
    }
  };

  useEffect(() => {
    localStorage.setItem('sideNavVisible', JSON.stringify(sideNavVisible));
  }, [sideNavVisible]);

  const toggleSideNav = () => {
    setSideNavVisible(!sideNavVisible);
  };

  useEffect(() => {
    setSideNavVisible(location.pathname.split('/').at(1) !== 'inspections');
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      const emailDomain = user?.email?.split('@').pop();
      const allowedDomains = ['flogistix.com'];
      const isInternalUser = allowedDomains.includes(emailDomain);

      setUserIsInternal(isInternalUser);

      (async () => {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      })();
    }
  }, [isAuthenticated, user?.email, getAccessTokenSilently]);

  useEffect(() => {
    if (token) {
      getOrgs(token, setOrganizations, triggerGlobalNotification);
      getSites(token, setSites, triggerGlobalNotification);
      getAndSetInspections();
      getGroup(token, setHasAccess, setGroup, triggerGlobalNotification);
    }
  }, [token]);

  useEffect(() => {
    if (!loading) {
      const newHasAccess = group?.groups?.length > 0 && user_organizations?.length > 0;
      setHasAccess(newHasAccess);
      setIsAdmin(
        newHasAccess
        && group?.groups.some(
          (person) => person.groupName.toLowerCase()
            === AIR_METHANE_ADMIN_GROUP_NAME.toLowerCase()
        )
      );
    }
  }, [loading]);

  useEffect(() => {
    setLoading(!inspections || !user_organizations || !user_sites || !group);
  }, [inspections, user_organizations, user_sites, group]);

  useEffect(() => {
    if (inspections) {
      const readyForSubmissionCount = inspections.filter(
        (inspection) => inspection.statusName === 'Ready for submission'
      ).length;
      setInspectionsReadyCount(readyForSubmissionCount);
    }
  }, [inspections]);

  const providerValue = useMemo(
    () => ({
      inspections,
      user_organizations,
      user_sites,
      token,
      loading,
      is_admin,
      has_access,
      selectedRowKeys,
      setSelectedRowKeys,
      netsuiteToAxilSiteIdMap,
      netsuiteToAxilCustomerIdMap,
      getAndSetInspections,
      inspectionsReloadIsPending,
      insp_id,
      setInspId,
      selectInspection,
      setSelectInspection,
      sideNavVisible,
      toggleSideNav,
      setSideNavVisibility,
      profileModalOpen,
      setProfileModalOpen,
      inspectionsReadyCount,
      setInspectionsReadyCount,
      globalNotificationType,
      setGlobalNotificationType,
      triggerGlobalNotification,
      closeGlobalNotification,
      isStep2FormActive,
      areButtonsDisabled,
      setAreButtonsDisabled,
      activeHomeSection,
      setActiveHomeSection,
      userIsInternal
    }),
    [
      inspections,
      user_organizations,
      user_sites,
      token,
      loading,
      is_admin,
      has_access,
      selectedRowKeys,
      netsuiteToAxilSiteIdMap,
      netsuiteToAxilCustomerIdMap,
      inspectionsReloadIsPending,
      insp_id,
      selectInspection,
      sideNavVisible,
      profileModalOpen,
      inspectionsReadyCount,
      globalNotificationType,
      isStep2FormActive,
      areButtonsDisabled,
      activeHomeSection,
      userIsInternal
    ]
  );

  return (
    <GlobalContext.Provider value={providerValue}>
      {children}
    </GlobalContext.Provider>
  );
};
